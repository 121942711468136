<template>
    <modal-dialog :hide-close="true">
        <h3 class="modal-title text-center" id="modal-title">
            Cadastrar foto
        </h3>
        <span v-if="isLoading" class="m-y-xl d-block"><i class="fa fa-circle-o-notch fa-spin"></i> Carregando...</span>
        <div class="area">
            <div id="recognition-modal-container">
                <p class="recognition-info" v-if="!isLoading">
                    Posicione-se em um ambiente com boa luminosidade sem interferência de luz direta que possa ofuscar
                    ou reduzir a eficácia da sua identificação.
                    É proibido o uso de itens que prejudiquem a identificação como boné com a aba voltada para a frente
                    e óculos escuros.
                </p>
            </div>
            <video ref="webCamera" autoplay muted playsinline style="width: auto; height: 350px;"
                   v-show="(!img) && !isLoading" title="Vídeo da sua câmera"></video>
            <input type="hidden" id="base_img" name="base_img" v-model="img"/>
            <img id="imagemTirada" v-if="img && !isLoading" :src="img" height="350" alt="Sua foto capturada para perfil">
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary m-r-lg" @click="sendSnapShot" :disabled="isLoading" v-if="img">Salvar
            </button>
            <button class="btn btn-default" @click="takePhoto" :disabled="isLoading" v-if="!img">Tirar foto</button>
            <button class="btn btn-default" @click="renewPhoto" :disabled="isLoading" v-if="img">Tirar nova foto
            </button>
        </div>
    </modal-dialog>
</template>

<script>
    import axios from 'axios';
    import {getBaseSgpUrl} from "../../../scripts/apiService/ApiService";
    import ModalDialog from "../../base/Modal";
    import {getCookie} from "../../../scripts/utils";

    export default {
        name: 'takeSnapShot',
        components: {ModalDialog},
        props: ['goToFaceRecognition', 'nativeProctoring'],
        data() {
            return {
                isLoading: true,
                img: null,
                cameraStream: null,
            }
        },
        methods: {
            takePhoto() {
                let video = this.$refs.webCamera;

                let canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                this.img = canvas.toDataURL('image/jpeg');
            },
            renewPhoto() {
                this.img = null;
            },
            sendSnapShot() {
                let currentCandidate = getCookie('currentCandidate');
                let url = '{baseUrl}api/v1/tm/rest/candidate/{pk}/'
                    .replace('{baseUrl}', getBaseSgpUrl())
                    .replace('{pk}', currentCandidate);
                let data = {photo: this.img};
                let component = this;

                this.isLoading = true;

                axios.patch(url, data).then((response) => {
                    this.cameraStream.getTracks().forEach(function(track) {
                        track.stop();
                    });
                    component.goToFaceRecognition(response.data.photo);
                }).catch(function () {
                    alert("Houve um erro ao salvar a sua foto.");
                    component.isLoading = false;
                });
            },
            loadCamera() {
                let video = this.$refs.webCamera;
                let component = this;
                if (video) {
                    if (navigator.mediaDevices.getUserMedia) {
                        navigator.mediaDevices.getUserMedia({audio: false, video: {facingMode: 'user'}})
                            .then((stream) => {
                                video.srcObject = stream;
                                component.isLoading = false;
                                this.cameraStream = stream;
                            })
                            .catch(function () {
                                alert("Habilite sua câmera e recarregue a página.");
                                location.reload();
                            });
                    }
                }
            }
        },
        beforeUnmount() {
            if (this.cameraStream){
                this.cameraStream.getTracks().forEach(function(track) {
                    track.stop();
                });
            }
        },
        mounted() {
            this.loadCamera();
        }
    }
</script>

<style scoped>
    .modal-mask-transparent {
        background-color: rgba(0, 0, 0, 0.7) !important;
    }

    @media (max-width: 768px) and (orientation: portrait) {
        .modal-container {
            width: 80% !important;
        }

        .modal-wrapper {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }
    }

    @media (max-width: 768px) and (orientation: landscape) {
        .modal-container {
            width: 80% !important;
        }

        .modal-wrapper {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }

        .area video {
            height: 225px !important;
        }

        .modal-header {
            padding-bottom: 5px;
            padding-top: 5px;
        }

        .modal-body {
            padding-top: 0px !important;
        }

        .area img {
            max-width: 100% !important;
            height: 225px !important;
        }
    }
</style>
<template>
    <modal-dialog mask-class="bg-blank" :hide-close="true">
        <h3 class="modal-title text-center" id="modal-title">
            Filmar Área em volta
        </h3>
        <span v-if="isLoading" class="m-y-xl d-block"><i class="fa fa-circle-o-notch fa-spin"></i> Carregando...</span>
        <div class="area">
            <div id="recognition-modal-container">
                <p class="recognition-info" v-if="!isLoading">
                    Gire sua câmera para a esquerda e depois para a direita, seguindo o fluxo da seta na tela, para filmar toda a área em volta.
                    Posicione-se em um ambiente com boa luminosidade e sem interferência de luz direta que possa ofuscar
                    ou reduzir a eficácia da sua identificação.
                </p>
            </div>
            <div class="col-xs-12 p-0 text-center video-container">
                <div class="arrow">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <video ref="webCamera" autoplay muted playsinline style="width: auto; height: 350px; display: none" v-show="!isLoading" title="Vídeo da sua câmera"></video>
            </div>
        </div>
    </modal-dialog>
</template>

<script>
    import axios from 'axios';
    import {getBaseSgpUrl} from "../../../scripts/apiService/ApiService";
    import {getBase64} from "../../../scripts/utils";
    import ModalDialog from "../../base/Modal";
    import * as RecordRTC from 'recordrtc';

    export default {
        name: "environmentRecognitionModal",
        components: {ModalDialog},
        props: ['onEnvRecognitionSuccess'],
        data() {
            return {
                isLoading: true,
                img: null,
                numberOfRecognitionTries: 0,
                itemGeneratedId: null,
                cameraStream: null
            }
        },
        methods: {
            loadCamera() {
                let video = this.$refs.webCamera;
                let component = this;
                if (window.stream) {
                    window.stream.getTracks()[0].stop();
                }
                if (video) {
                    if (navigator.mediaDevices.getUserMedia) {
                        navigator.mediaDevices.getUserMedia({audio: false, video: {facingMode: 'user'}})
                            .then((stream) => {
                                video.srcObject = stream;
                                component.isLoading = false;
                                this.cameraStream = stream;
                                this.initRecording(stream);
                            })
                            .catch(function () {
                                alert("Habilite sua câmera e recarregue a página.");
                                location.reload();
                            });
                    }
                }

                this.createArrowAnimation();

            },
            sendStream() {

                let url = '{baseUrl}api/v1/po/rest/provaonline/environmentstream/'
                    .replace('{baseUrl}', getBaseSgpUrl());
                let data = {
                    item_generated_pk: this.itemGeneratedId,
                };
                let component = this;

                this.isLoading = true;

                axios.post(url, data).then(() => {
                    this.cameraStream.getTracks().forEach(function(track) {
                        track.stop();
                    });
                    component.onEnvRecognitionSuccess();
                }).catch(function () {
                    alert("Houve um erro para registrar o vídeo.");
                    component.isLoading = false;
                });
            },
            initRecording(camera){
                let url = process.env.VUE_APP_MONITORING_SERVICES_URL + "upload_clip/";
                let itemGeneratedId = this.itemGeneratedId;
                let clientSlug = this.$store.state.onlineTest.configs.variables.client_slug;

                let recorder = RecordRTC(camera, {
                    recorderType: RecordRTC.MediaStreamRecorder,
                    mimeType: 'video/webm',
                });

                let component = this;

                recorder.setRecordingDuration(14000).onRecordingStopped(function() {
                    let blob = recorder.getBlob();
                    recorder = null;
                    camera.stop();

                    let file = new File([blob], 'teste', {
                            type: 'video/webm'
                        });

                    let data = {name: "EnvironmentStream",
                                client_slug: clientSlug,
                                item_generated_id: itemGeneratedId,
                                session_uuid: "environment"};
                    getBase64(file, function(result){
                        data.clip = result;
                        axios.post(url, data, {
                            headers: {"Content-Type": "application/json"}
                        });
                    });

                    if (this.cameraStream){
                        this.cameraStream.getTracks().forEach(function(track) {
                            track.stop();
                        });
                    }
                    component.sendStream();
                });

                recorder.startRecording();
                recorder.camera = camera;
            },
            createArrowAnimation(){
                let root = document.documentElement;

                root.style.setProperty('--transformArrow', "rotate(45deg)");
                root.style.setProperty('--leftArrow', 10 + "%");

                setTimeout(this.updateArrowAnimation, 7000);

            },
            updateArrowAnimation(){
                let root = document.documentElement;

                root.style.setProperty('--transformArrow', "rotate(-135deg)");
                root.style.setProperty('--leftArrow', 90 + "%");
            }
        },
        beforeUnmount() {
            if (this.cameraStream){
                this.cameraStream.getTracks().forEach(function(track) {
                    track.stop();
                });
            }
        },
        mounted() {
            this.itemGeneratedId = this.$route.params.key;
            this.loadCamera();
        }
    }
</script>

<style scoped>

    :root {
      --transformArrow: none;
      --leftArrow: none;
    }

    .video-container {
      position: relative;
      margin: 0 auto;
    }
    .content video {
      width: 100%;
      display: block;
    }
    .video-container:before {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .arrow {
        position: absolute;
        top: 50%;
        left: var(--leftArrow);
        transform: translate(-50%, -50%);
        transform: rotate(90deg);
        cursor: pointer;
    }

    .arrow span {
        display: block;
        width: 1.5vw;
        height: 1.5vw;
        border-bottom: 5px solid white;
        border-right: 5px solid white;
        transform: rotate(45deg);
        margin: -10px;
        animation: animate 2s infinite;
    }

    .arrow span:nth-child(2) {
        animation-delay: -0.2s;
    }

    .arrow span:nth-child(3) {
        animation-delay: -0.4s;
    }

    @keyframes animate {
        0% {
            opacity: 0;
            transform: var(--transformArrow);
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: var(--transformArrow);
        }
    }
</style>
<template>
    <span>
        <a class="link" v-if="hasToShow(modalContent)" @click="showModal = true" style="white-space: nowrap">Ver mais</a>
        <modal-dialog v-show="showModal" @close="showModal = false">
            <h3 class="m-b-md">{{ modalTitle }}</h3>
            <div v-html="modalContent"></div>
        </modal-dialog>
    </span>
</template>

<script>
    import ModalDialog from "../base/Modal";
    export default {
        name: "SeeMoreModal",
        components: {ModalDialog},
        data: () => {return {
            showModal: false
        }},
        props: ['modalTitle', 'modalContent'],
        methods: {
            hasToShow(content){
                return content && (content.split(' ').length > 50 || content.includes('<img'));
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <h4 class="m-b-lg">Resposta em formato de áudio</h4>

    <div v-if="uploading">
        <button class="btn btn-default btn-mic">
            <i class="fa fa-circle-o-notch fa-spin"></i>
        </button>
        <br/>
        <span>Enviando áudio...</span>
    </div>

    <div v-else-if="!recorder">
        <button class="btn btn-default btn-mic" @click="record()">
            <i class="fa fa-microphone"></i>
        </button>
        <br/>
        <span>Iniciar {{ initial || newAudioURL ? 'nova ' : '' }}gravação</span>
    </div>

    <div v-else>
        <button class="btn btn-primary btn-mic" @click="stop()">
            <i class="fa fa-stop"></i>
        </button>
        <br/>
        <span>Gravando...</span>
    </div>

    <br/>

    <audio v-if="newAudioURL" :src="newAudioURL" controls></audio>

</template>

<script>

    import {postTestMedia} from "../../scripts/apiService/tests";
    import {getBaseSgpUrl} from "../../scripts/apiService/ApiService";

    export default {
        props: ["initial", "callback", "testId"],
        data() {
            return {
                newAudio: null,
                newAudioURL: null,
                recorder: null,
                uploading: false,
            }
        },
        methods: {
            async record() {
                this.newAudio = null;

                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false
                });

                const options = {mimeType: "audio/webm"};
                const recordedChunks = [];
                this.recorder = new MediaRecorder(stream, options);

                this.recorder.addEventListener("dataavailable", e => {
                    if (e.data.size > 0) {
                        recordedChunks.push(e.data);
                    }
                });

                this.recorder.addEventListener("stop", () => {
                    this.newAudio = new Blob(recordedChunks);
                    this.newAudioURL = URL.createObjectURL(this.newAudio);
                    this.uploading = true;

                    postTestMedia(this.testId, this.newAudio, "mp3").then((response) => {
                        if (this.callback) {
                            let fileUrl = getBaseSgpUrl() + `privatefile?file_path=${response.data.file_key}`;
                            this.callback(this.newAudio, fileUrl);
                        }
                    }).catch((error) => {
                        console.log(error);
                    }).finally(() => {
                        this.uploading = false;
                    });
                });

                this.recorder.start();
            },
            async stop() {
                this.recorder.stop();
                this.recorder = null;
            }
        },
        mounted() {
            if (this.initial) {
                this.newAudioURL = this.initial;
            }
        }
    };
</script>

<style scoped>
    .btn-mic {
        width: 30px;
        height: 30px;
        padding: 0;
    }
</style>
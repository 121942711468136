export default
    `
    <script src="https://foreducationedtech.freshchat.com/js/widget.js"></script>
    <script type="application/javascript">

        function initFreshChat() {    
            let restoreId = null;
    
            window.fcWidget.init({
                token: "c830605d-6335-49c8-8008-9ac21b504471",
                host: "https://foreducationedtech.freshchat.com",
                externalId: "{{ pk }}",
                restoreId: restoreId ? restoreId : null
            });
    
            window.fcWidget.user.get(function (resp) {
                let status = resp && resp.status;
                // let data = resp && resp.data;
                if (status !== 200) {
                    window.fcWidget.user.setProperties({
                        firstName: "{{ first_name }}",
                        lastName: "{{ last_name }}",
                        email: "{{ email }}",
                    });
                    // user_created
                }
            });
    
        }
    
        initFreshChat();
    
    </script>`
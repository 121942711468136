import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import {getCookie} from "./utils";

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET
};

const firebaseApp = firebase.initializeApp(config);

const auth = getAuth(firebaseApp);

const firestoreDB = firebaseApp.firestore();
firestoreDB.settings({ experimentalForceLongPolling: true });
const firebaseStorage = firebaseApp.storage();

const fireBaseCheckMultipleInstances = async  (firestoreObjectRef) => {
    await signInWithCustomToken(auth, getCookie("firestore_token")).catch( (e) => {console.log(e)});
    const testDataRef = await firestoreObjectRef.get();
    const testData = testDataRef.data();
    return testData?.isOnline;
};

const loadDataFromFirebase = async (firestoreObjectRef) => {
    await signInWithCustomToken(auth, getCookie("firestore_token")).catch( (e) => {console.log(e)});
    const assessmentRef = await firestoreObjectRef.get();
    const onlineTestData = assessmentRef.data();
    const storageKey = onlineTestData.storageKey;
    const previousAnswers = onlineTestData.answers;
    const questions = onlineTestData.questions;

    const storageKeyRef = firebaseStorage.ref(storageKey);
    const documentUrl = await storageKeyRef.getDownloadURL();
    const assessmentData = await _loadStorageContent(documentUrl);
    return {
      assessmentData,
      previousAnswers,
      questions
    };
};

const _loadStorageContent = async (documentUrl) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = async () => {
            const blobContent = xhr.response;
            const fileText = await blobContent.text();
            const assessmentData = JSON.parse(fileText);
            resolve(assessmentData);
        };
        xhr.onerror = () => {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.open('GET', documentUrl);
        xhr.send();
    });
};

function firebaseSignOut(){
    signOut(auth).catch();
}

export {
  firebaseApp,
  firestoreDB,
  loadDataFromFirebase,
  firebaseSignOut,
  fireBaseCheckMultipleInstances
};

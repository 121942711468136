import WelcomeScreen from "../../components/views/WelcomeScreen";
import FinishedTestsList from "../../components/views/FinishedTestsList";
import UpcomingTestsList from "../../components/views/UpcomingTestsList";
import TestDataView from "../../components/views/TestDataView";
import TestDataPrintView from "../../components/views/TestDataPrintView";
import AppealsList from "../../components/views/AppealsList";
import AppealCreateView from "../../components/views/AppealCreateView";
import OnlineTestsList from "../../components/views/OnlineTestsList";
import QuestionnairesList from "../../components/views/QuestionnairesList";
import QuestionnaireForm from "../../components/views/QuestionnaireForm";
import TestDataViewQuestions from "../../components/views/TestDataViewQuestions";
import ExtraContentList from "../../components/views/ExtraContentList";
import ReportsList from "../../components/views/ReportsList";
import ReportView from "../../components/views/ReportView";
import CertificateList from "../../components/views/CertificateList";
import SchedulingList from "../../components/views/SchedulingList";

export const mainRoutes = [
  { path: '/', component: WelcomeScreen },
  { path: '/online-tests', component: OnlineTestsList, meta: {title: "Provas Online"}},
  { path: '/upcoming-tests', component: UpcomingTestsList, meta: {title: "Provas Futuras"}},
  { path: '/finished-tests', component: FinishedTestsList, meta: {title: "Provas Realizadas"}},
  { path: '/finished-tests/:key/', component: TestDataView, meta: {title: "Dados da prova"}},
  { path: '/finished-tests/:key/appeal', component: AppealCreateView, meta: {title: "Novo Requerimento"}},
  { path: '/finished-tests/:key/questions', component: TestDataViewQuestions, meta: {title: "Questões da prova"}},
  { path: '/finished-tests/:key/print', component: TestDataPrintView, meta: {title: "Impressão da prova"}},
  { path: '/test-scheduling/', component: SchedulingList, meta: {title: "Agendar provas"}},
  { path: '/appeals/', component: AppealsList, meta: {title: "Requerimentos"}},
  { path: '/questionnaires/', component: QuestionnairesList, meta: {title: "Questionários"}},
  { path: '/questionnaires/:key/', component: QuestionnaireForm, meta: {title: "Questionário"}},
  { path: '/extra-contents/', component: ExtraContentList, meta: {title: "Materiais de estudo"}},
  { path: '/reports-list/', component: ReportsList, meta: {title: "Relatórios"}},
  { path: '/report/:key', component: ReportView},
  { path: '/certificates/', component: CertificateList, meta: {title: "Certificados"}}
];

export var featureFlags = {};

export function initOptimizelyClient() {
    const optimizelySDK = require('@optimizely/optimizely-sdk');
    optimizelySDK.setLogLevel('error');
    const optimizelyClientInstance  = optimizelySDK.createInstance({
        sdkKey: process.env.VUE_APP_OPTIMIZELY_SDK_KEY
    });
    optimizelyClientInstance.onReady().then(() => {
        for (const [key, ] of Object.entries(featureFlags)) {
            featureFlags[key] = optimizelyClientInstance.isFeatureEnabled(key, '');
        }
        optimizelyClientReady();
    });
}

let callbacks = [];

function optimizelyClientReady(){
    callbacks.forEach((callback) => {callback()});
}

export function onClientReady(fn){
    callbacks.push(fn)
}

<template>
    <div aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-area-chart"></i> Relatórios</p>
            </div>
        </div>
        <div class="col-xs-12 p-x-sm" v-if="result !== null">
            <div class="col-xs-12 col-lg-3 p-md" v-for="report in reports" :key="report">
                <router-link :to="'/report/'+report.slug">
                    <div class="card p-20 d-flex-row flex-justify-center flex-align-center" style="min-height: 50px">
                        <div class="col-xs-12 p-none">
                            <h4 class="level m-b-sm">{{ report.name }}</h4>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {getReportsList} from "../../scripts/apiService/reports";
    export default {
        name: 'ReportsList',
        data: () => {
            return {
                reports: [],
            }
        },
        methods: {
            getList(){
                getReportsList().then(result => {
                    this.reports = result.data;
                })
            }
        },
        mounted(){
            this.getList();
            document.getElementById('title').focus();
        }
    }
</script>
import {getRequest, getSchedulingRequest, postSchedulingRequest} from "./ApiService";

export function getSchedules(){
    return getRequest('schedulingList')
}

export function getSchedulingData(tenantId, schedulingId){
    return getRequest('schedulingData', {id: schedulingId}, {tenant: tenantId, filter_available: true})
}

export function postSchedulingSubscription(tenantId, data){
    data.tenant_id = tenantId;
    return postSchedulingRequest(tenantId, 'newSubscription', {}, data)
}

export function subscriptionCancel(tenantId, subscriptionId){
    let data = {tenant_id: tenantId};
    return postSchedulingRequest(tenantId, 'cancelSubscription', {id: subscriptionId}, data)
}

export function getSettings(tenantId, parameterId){
    return getSchedulingRequest(tenantId, 'settings', {id: parameterId}, {tenant: tenantId})
}

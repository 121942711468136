import {getCookie} from "../utils";
import {getRequest} from "./ApiService";

export function getAppealsList(statusSearch){
    let candidateKey = getCookie("currentCandidate");
    let queryParams = {};
    if (statusSearch){
        queryParams.status = statusSearch
    }
    return getRequest('appealsList', {"candidateKey": candidateKey}, queryParams)
}

<template>
    <div class="row chat-container">
        <div style="display: inline-block;" v-if="messages.length > 0">
            <div class="chat-window" :class="isExpanded?'': 'minimized'">
                <div class="chat-header" style="background-color: #2e6da4">
                    <div class="col-xs-1">
                        <i class="fa fa-circle" :class="hasNewMessages ? 'has-new-messages' : ''"></i>
                    </div>
                    <div class="col-xs-10">
                        Chat :: Fale com o fiscal
                    </div>
                    <div class="col-xs-1 text-right chat-buttons">
                        <a class="fa" @click="toggleMinimize()" :class="isExpanded?'fa-window-minimize': 'fa-window-maximize'"></a>
                    </div>
                </div>
                <div v-if="isExpanded">
                    <div class="chat-messages" id="messages">
                        <div v-for="message in messages" :key="message.timestamp" :class="message.sender+'-message'" class="message">
                            <span>
                                <span>{{ message.message }}</span>
                                <div class="timestamp">
                                    {{ message.timestamp }}
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="form chat-form">
                        <div class="col-xs-8">
                            <textarea
                                name="message"
                                v-model="newMessage"
                                @keydown.enter="sendMessage()"
                            ></textarea>
                        </div>
                        <div class="col-xs-4">
                            <input type="button" value="Enviar" class="btn btn-primary btn-sm" @click="sendMessage()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data() {
            return {
                messages: [],
                newMessage: '',
                candidateMsgClass: 'candidate-message',
                monitorMsgClass: 'monitor-message',
                name: "",
                enrollment: "",
                configs: null,
                chatSession: null,
                sessionInterval: null,
                monitoringServicesUrl: process.env.VUE_APP_MONITORING_SERVICES_URL,
                hasNewMessages: false,
                isExpanded: true,
                newMessageCount: 0,
            }
        },
        mounted() {
            this.setLoadInterval();
        },
        methods: {
            toggleMinimize() {
                this.isExpanded = !this.isExpanded;
                if (this.hasNewMessages) {
                    this.hasNewMessages = false;
                }
            },
            gatherSession() {
                if (this.chatSession !== null) {
                    clearInterval(this.sessionInterval);
                    return;
                }
                let data = {params: {test_id: this.$store.state.onlineTest.configs.variables.item_generated_id}};

                axios.get(this.monitoringServicesUrl + 'chat/sessions/get', data).then(response => {
                    if (Object.keys(response.data).length > 0) {
                        this.chatSession = response.data.session_id;
                        clearInterval(this.sessionInterval)
                    }

                }).catch(error => {
                    console.log(error);
                });
            },
            fetchMessages() {
                if (this.chatSession === null && this.sessionInterval === null) {
                    this.sessionInterval = setInterval(() => {
                        this.gatherSession();
                    }, 10000)
                    return;
                }
                if (this.chatSession !== null) {
                    let data = {params: {session_id: this.chatSession}};

                    axios.get(this.monitoringServicesUrl + 'chat/messages/get', data).then(response => {
                        if (!this.isExpanded && this.messages.length > 0 && response.data.length > this.messages.length) {
                            this.hasNewMessages = true;
                        }
                        this.messages = response.data;


                    }).catch(error => {
                        console.log(error);
                    });
                }
            },
            sendMessage() {
                if (this.newMessage.trim().length > 0) {
                    let data = {
                        session_id: this.chatSession,
                        message: this.newMessage,
                        sender: 'candidate'
                    };
                    axios.post(this.monitoringServicesUrl + 'chat/messages/', data).then(() => {
                        this.newMessage = '';
                        this.fetchMessages();
                    }).catch(error => {
                        console.log(error);
                    });
                }
            },
            setLoadInterval() {
                setInterval(() => {
                    this.fetchMessages();
                }, 10000);
            }
        }
    }
</script>

<style scoped>
    .chat-container {
        position: fixed;
        bottom: 25px;
        right: 20px;
    }
    .chat-window {
        width: 400px;
        height: 500px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 999;
        margin-left: 10px;
    }
    .minimized {
        height: 65px;
    }
    .chat-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        border-bottom: 1px solid #eee;
        margin-left:0;
        height: 40px;
        color: #f5f5f5;
        font-weight: bold;
    }
    .chat-header a {
        color: #fff;
        font-size: 16px;
        text-decoration: none;
    }
    .fa.fa-circle.red {
        color: red;
    }
    .chat-messages {
        padding: 10px 20px;
        height: 400px;
        overflow-y: scroll;
    }

    .message .timestamp {
        font-size: 12px;
        color: #999;
        text-align: right;
    }
    .candidate-message{
        background-color: #2e6da4;
        border-radius: 10px;
        padding: 10px;
        margin-left: 10px;
        margin-bottom: 2px;
        color: #fff;
        text-align: right
    }
    .monitor-message {
        background-color: #f5f5f5;
        border-radius: 10px;
        padding: 10px;
        margin-right: 10px;
        text-align: left;
        margin-bottom: 2px;
    }
    .chat-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #eee;
        height: 50px;
        background-color: #fff;
        padding: 10px 10px;
    }
    .chat-form textarea {
        resize: none;
        width: 100%;
        outline: none;
    }
    @keyframes blinking {
        0% {
          color: #ff2c2c;
        }
        50% {
          color: #de0a26;
        }
        100% {
          color: #c30010;
        }
      }
    .has-new-messages {
        animation: blinking 1.5s infinite;
    }
</style>
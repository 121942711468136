import * as rxjs from 'rxjs';

import { catchError, tap } from "rxjs/operators";
import { screenSharingStatus$, screenStreamSubject$ } from "./observableStates";

function handleStream(stream) {

    screenStreamSubject$.set(stream);

    const videoTrack = stream.getVideoTracks()[0];

    if (videoTrack) {
        videoTrack.addEventListener("ended", () => {
            videoTrack.stop();
            screenStreamSubject$.set(null);
            screenSharingStatus$.set({ state: "ended", message: "O compartilhamento de tela foi interrompido. Habilite novamente para continuar." })
        });
    }

    screenSharingStatus$.set({ state: "streaming" });
}

function ErrorObjectWithStatus() {
    const error = { state: "error", message: "Habilite o compartilhamento de tela cheia do monitor e tente novamente" };
    screenStreamSubject$.set(null);
    screenSharingStatus$.set(error)
}

function displaySurfaceCondition(displaySurface, displaySurfaceExpected, stream){
    if (displaySurface === displaySurfaceExpected) handleStream(stream);
    else ErrorObjectWithStatus();
}

export function initScreenStream(){
    rxjs.from(navigator.mediaDevices.getDisplayMedia({ audio: false, video: true }))
    .pipe(
        tap(stream => {
            if(!stream){
                ErrorObjectWithStatus();
            }
            else{
                const settings = stream.getVideoTracks()[0].getSettings();

                if(settings.displaySurface) displaySurfaceCondition(settings.displaySurface, "monitor", stream);
                else displaySurfaceCondition(stream.getVideoTracks()[0].label, "Primary Monitor", stream);
            }
        }),
        catchError(() => {
            return rxjs.of(ErrorObjectWithStatus());
        })
    ).subscribe();
}

export function stopScreenStream(){
    let streamObject = screenStreamSubject$.getCurrentValue();

    if(streamObject) streamObject.getVideoTracks()[0].stop();
}
import {getCookie, getQueryParam, setCookie} from "../utils";
import {getRequest} from "./ApiService";

export function getCandidateInfo(){
    let candidateKey = getQueryParam("candidate_key");
    let candidateToken = getQueryParam("candidate_token");

    if (candidateToken) {
        setCookie("candidateToken", candidateToken);
    }

    if (candidateKey){
        setCookie("currentCandidate", candidateKey);
    } else {
        candidateKey = getCookie("currentCandidate");
    }
    return getRequest('candidateInfo', {"candidateKey": candidateKey})
}

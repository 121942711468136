<template>
    <div aria-live="assertive">
        <h2 class="m-b-xl m-t-lg" id="title" role="heading" aria-level="1" tabindex="0"><b>Relatório: {{reportData.report_name}}</b></h2>
        <div v-if="reportExecution == null" class="report-wrapper col-xs-12">
            <div class="col-xs-12" v-if="reportData">
                <div class="col-xs-12" v-for="parameter in reportData.parameters" v-bind:key="parameter.label">
                    <div v-if="parameter.type == 1 || parameter.type == 3" class="input-select input">
                        <label>{{parameter.label}}</label>
                        <select :id="'id_'+parameter.name" :name="parameter.name" v-bind:class="{'multiple': parameter.type == 3}" v-bind:multiple="parameter.type == 3">
                            <option :key="option.value" v-for="option in parameter.options" :value="option.value">{{option.name}}</option>
                        </select>
                    </div>
                    <div v-else-if="parameter.type == 2">
                        <input :type="parameter.is_hidden ? 'hidden': 'text'" :name="parameter.name" :value="parameter.name" :id="'id_'+parameter.name">
                    </div>
                    <div v-else-if="parameter.type == 4">
                        <input type="date" :name="parameter.name" :value="parameter.name" :id="'id_'+parameter.name">
                    </div>

                </div>
            </div>
            <button class="btn btn-primary m-xl" @click="executeReport">Executar relatório</button>
        </div>
        <div class="col-md-12 d-flex-column flex-justify-around flex-align-start" v-else>
            <label class="dropdown flex-align-self-end m-b-lg" v-if="reportExecution.export_types">
                <div class="dd-button">
                    Exportar
                </div>
                <input type="checkbox" class="dd-input">
                <ul class="dd-menu" style="z-index: 5">
                    <li v-for="exportType in reportExecution.export_types" :key="exportType.format">
                        <a target="_blank" :href="reportExecution.report_html_url + '&__format=' + exportType.format">{{exportType.label}}</a>
                    </li>
                </ul>
            </label>
            <div v-if="reportType == 'query'">
                <table class="col-sm-12">
                    <tr>
                        <th v-for="column in reportExecution.columns" :key="column">{{column}}</th>
                    </tr>
                    <tr v-for="row in reportExecution.object_list" :key="row">
                        <td v-for="value in row" :key="value">{{value}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-md-12 d-flex-row flex-justify-around flex-align-start" v-else>
                <embed height="600" width="70%" :src="reportExecution.report_html_url">
            </div>
        </div>
    </div>
</template>

<script>
    import {getReport, postExecuteReport} from "../../scripts/apiService/reports";

    export default {
        name: 'ReportView',
        data: () => {
            return {
                reportData: [],
                reportExecution: null,
                reportType: null
            }
        },
        methods: {
            getReportHTML(){
                getReport(this.$route.params.key).then(response => {
                    this.reportData = response.data;
                });
            },
            validateFields(formData) {
                let valid = true;
                if (this.reportData.parameters) {
                    this.reportData.parameters.forEach((parameter) => {
                        let reportParameterTag = document.querySelector(`#id_${parameter.name}`);
                        if (parameter.required && !parameter.is_hidden && !formData.get(reportParameterTag.name)) {
                            valid = false
                        }
                    });
                }
                return valid;
            },
            getFormData(){
                let reportForm = new FormData();

                this.reportData.parameters.forEach(parameter => {
                    let reportParameterTag = document.querySelector(`#id_${parameter.name}`);

                    if (reportParameterTag.hasAttribute('multiple')){
                        Array.from(reportParameterTag.options).filter(function (option) {
                            return option.selected;
                        }).map(function (option) {
                            reportForm.append(reportParameterTag.name, option.value);
                        });
                    }
                    else if (reportParameterTag.type == 'date'){
                        let formatedDate = reportParameterTag.value.split('-')
                        let year = formatedDate[0]
                        formatedDate[0] = formatedDate[2]
                        formatedDate[2] = year
                        reportForm.append(reportParameterTag.name, formatedDate.join('/'));
                    }
                    else if (reportParameterTag.name == reportParameterTag.value){
                        reportForm.append(reportParameterTag.name, "");
                    }
                    else{
                        reportForm.append(reportParameterTag.name, reportParameterTag.value);
                    }
                });
                return reportForm;
            },
            executeReport(){
                let reportForm = this.getFormData();

                if (!this.validateFields(reportForm)) {
                    alert("Favor preencher campos obrigatórios");
                    return;
                }
                
                postExecuteReport(this.$route.params.key, reportForm).then(response => {
                    response.data.columns ? this.reportType = 'query' : this.reportType = 'birt';
                    this.reportExecution = response.data;
                });
                
            },
        },
        mounted(){
            this.getReportHTML();
            document.getElementById('title').focus();
        }
    }
</script>
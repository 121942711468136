<template>
  <div id="welcome-screen" aria-live="polite">
    <h2>Olá, {{ $store.state.main.candidateInfo.firstName }}!</h2>
    <p class="welcome-description">Navegue através do menu lateral para acessar suas provas, requerimentos e certificados</p>
  </div>
</template>

<script>
export default {
  name: 'WelcomeScreen'
}
</script>

<template>
    <div v-if="result && !result?.forbidden" aria-live="assertive" tabindex="0">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="initialMessage" aria-level="1" tabindex="0"><i class="fa fa-question"></i> Novo requerimento</p>
            </div>
        </div>

        <div class="col-xs-12 m-t-xl">
            Selecione uma questão para criar o requerimento
        </div>

        <div class="col-xs-12 m-t-xl" v-for="(question, index) in result.questions" :key="question">
            <div class="card p-20 text-left question-card">

                <label class="checkbox-custom check-success m-0 m-r-sm" v-if="!question.appeal">
                    <input id="accept-terms" type="checkbox" v-model="showSelection[question.pk]"
                    @click="clickSelection(question.pk)">
                    <label for="accept-terms" class="m-r-0"></label>
                </label>

                <strong>{{ index + 1 }}) </strong>
                <div v-if="!result.hide_question_body" class="m-t-sm">
                    <span class="m-t-sm">{{ stripQuestionTags(question.body) }}</span>
                    <see-more-modal modal-title="Enunciado" :modal-content="question.body"></see-more-modal>

                    <div v-if="question.appeal" class="m-t-md d-flex-row">
                        <div class="btn btn-primary" @click="currentShownAppeal = question.appeal">
                            Já existe requerimento para esta questão. Clique para ver detalhes.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xs-12 m-t-xl">
            <div class="card p-20 text-left">
                <h4>Motivo:</h4>
                <textarea v-model="appealReason" style="width: 100%; resize: vertical; height: 100px"></textarea>
            </div>
        </div>

        <div class="col-xs-12 m-t-md" v-if="appealError">
            <div class="bg-danger p-10">
                {{ appealError }}
            </div>
        </div>

        <div class="col-xs-12 m-t-xl d-flex-row flex-justify-between p-b-xl">
            <router-link class="btn btn-default" :to="`/finished-tests/${$route.params.key}/`">Cancelar</router-link>
            <button class="btn btn-primary" @click="createQuestionAppeal()"
               v-bind="loading || !selectedQuestion ? {disabled: 'disabled'} : {}">
                <i class="fa fa-circle-o-notch fa-spin" v-if="loading"></i> Salvar
            </button>
        </div>

        <modal-dialog v-show="currentShownAppeal" @close="currentShownAppeal = null">
            <h3 class="m-b-md">Informações do requerimento</h3>
            <div class="text-left" v-if="currentShownAppeal">
                <p>Solicitado em {{ formatDbDatetime(currentShownAppeal.creation_date) }}</p>

                <span>Solicitação:</span><br/>
                <span>{{ currentShownAppeal.reason }}</span><br/><br/>

                <div v-if="currentShownAppeal.status === 2 || currentShownAppeal.status === null" class="chip bg-default m-r-sm d-inline-block">
                    <i class="fa fa-clock-o"></i> Em análise
                </div>
                <div v-else-if="currentShownAppeal.status === 1" class="chip bg-success m-r-sm d-inline-block">
                    <i class="fa fa-check"></i> Aceito em {{ formatDbDatetime(currentShownAppeal.date_answer) }}
                </div>
                <div v-else class="chip bg-danger m-r-sm d-inline-block">
                    <i class="fa fa-times"></i> Rejeitado em {{ formatDbDatetime(currentShownAppeal.date_answer) }}
                </div>

                <div v-if="currentShownAppeal.answer">
                    <p>Analisado em {{ formatDbDatetime(currentShownAppeal.creation_date) }}</p>
                    <span>Resposta:</span><br/>
                    <span>{{ currentShownAppeal.answer }}</span>
                </div>
            </div>
        </modal-dialog>
    </div>
    <div v-else-if="result?.forbidden" class="d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
        <h3 id="refusedInitialMessage" aria-level="1" tabindex="0">Você não pode criar requerimentos nessa prova</h3>
    </div>
    <div v-else class="d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
        <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
    </div>
</template>

<script>
    import {getAppealCreateQuestions} from "../../scripts/apiService/tests";
    import {formatDbDatetime, getCookie, stripQuestionTags} from "../../scripts/utils";
    import SeeMoreModal from "../includes/SeeMoreModal";
    import ModalDialog from "../base/Modal";
    import {getBaseSgpUrlNoSlug} from "../../scripts/apiService/ApiService";
    import axios from "axios";

    export default {
        name: 'TestDataView',
        components: {ModalDialog, SeeMoreModal},
        data: () => { return {
            result: null,
            currentShownAppeal: null,
            showSelection: {},
            selectedQuestion: null,
            appealReason: null,
            appealError: null,
            loading: null
        }},
        methods: {
            formatDbDatetime: formatDbDatetime,
            stripQuestionTags: stripQuestionTags,
            clickSelection(question){
                Object.keys(this.showSelection).forEach((other) => {
                    if (other !== question) {
                        delete this.showSelection[other];
                    }
                });
                this.selectedQuestion = this.showSelection[question] ? null : question;
            },
            createQuestionAppeal() {
                let command = this.selectedQuestion;
                if (!command) {
                    this.appealError = 'Selecione uma questão.';
                    return
                }
                if (!this.appealReason) {
                    this.appealError = 'Motivo é obrigatório.';
                    return
                }
                this.loading = true;
                this.appealError = null;
                let data = {
                    command: command,
                    itemGenerated: this.$route.params.key,
                    candidate: getCookie('currentCandidate'),
                    comment: this.appealReason
                };
                let url = getBaseSgpUrlNoSlug() + this.result.create_question_appeal_url;
                const headers = {"X-CSRFTOKEN": getCookie('csrftoken')};
                axios.post(url, data, {withCredentials: true, headers: headers})
                    .then(() => {
                        location.reload();
                    }).catch((error) => {
                        this.loading = false;
                        this.appealError = error.response.data;
                    });
            }
        },
        mounted() {
            getAppealCreateQuestions(this.$route.params.key).then((response) => {
                this.result = response.data;
                this.$nextTick(() => {
                    if(!this.result.forbidden)
                        document.getElementById('initialMessage').focus();
                    else
                        document.getElementById('refusedInitialMessage').focus();
                });
            });
        },
    }
</script>

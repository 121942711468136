import {getCookie} from "../utils";

export const mainState = {
    namespaced: true,
    state () {
        return {
            theme: getCookie('theme', 'light'),
            loading: true,
            custom: "",
            candidateInfo: {availableMenus: {}},
            menuActive: false,
            hideMenu: false,
            fullScreen: false,
            smespCustomization: false
        }
    },
    mutations: {
        setTheme (state, theme) {state.theme = theme;},
        setLoading (state, value) {state.loading = value;},
        setCustom (state, value) {state.custom = value;},
        setCandidateInfo (state, data) {state.candidateInfo = data;},
        toggleMenu (state) {state.menuActive = !state.menuActive;},
        setHideMenu (state, value) {state.hideMenu = value;},
        setSmespCustomization (state,value) {state.smespCustomization = value;},
    }
};
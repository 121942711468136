<template>
    <div id="theme-switch-container" :class="'sw-custom-' + $store.state.main.custom">
        <label class="toggle" for="myToggle">
            <input class="toggle__input" name="" type="checkbox" id="myToggle"
                   @input="toggleTheme()" :checked="checked">
            <div class="toggle__fill"></div>
        </label>
    </div>
</template>

<script>
    import {getCookie, setCookie} from "../../scripts/utils";

    export default {
        name: "ThemeSwitch",
        data: () => { return {
            theme: getCookie('theme', 'light'),
            checked: getCookie('theme', 'light') === 'dark'
        }},
        methods: {
            toggleTheme(){
                this.checked = !this.checked;
                let currentTheme = this.theme;
                let newTheme = currentTheme === 'light' ? 'dark' : 'light';
                setCookie('theme', newTheme);
                this.$store.commit('main/setTheme', newTheme);
                this.theme = newTheme;
            }
        }
    }
</script>

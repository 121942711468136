<template>
    <h4 class="m-b-lg">Resposta em formato de vídeo</h4>

    <div v-if="uploading">
        <button class="btn btn-default">
            <i class="fa fa-circle-o-notch fa-spin"></i> Enviando vídeo...
        </button>
    </div>

    <div v-else-if="!recorder">
        <button class="btn btn-default" @click="record()">
            <i class="fa fa-circle color-danger"></i> Iniciar {{ initial || newVideoURL ? 'nova ' : '' }}gravação
        </button>
    </div>

    <div v-else>
        <button class="btn btn-primary" @click="stop()">
            <i class="fa fa-stop"></i> Finalizar gravação
        </button>
        <br/>
        <span>Gravando...</span>
    </div>

    <br/>

    <video v-if="newVideoURL && !filming" :src="newVideoURL" controls style="max-height: 300px"></video>
    <video v-if="filming" id="video-answer" autoplay muted style="max-height: 300px"></video>

</template>

<script>

    import {postTestMedia} from "../../scripts/apiService/tests";
    import {getBaseSgpUrl} from "../../scripts/apiService/ApiService";

    export default {
        props: ["initial", "callback", "testId"],
        data() {
            return {
                newVideo: null,
                newVideoURL: null,
                recorder: null,
                uploading: false,
                filming: false,
            }
        },
        methods: {
            async record() {
                this.newVideo = null;
                this.filming = true;

                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: true
                });

                document.getElementById("video-answer").srcObject = stream;

                const options = {mimeType: "video/webm"};
                const recordedChunks = [];
                this.recorder = new MediaRecorder(stream, options);

                this.recorder.addEventListener("dataavailable", e => {
                    if (e.data.size > 0) {
                        recordedChunks.push(e.data);
                    }
                });

                this.recorder.addEventListener("stop", () => {
                    this.newVideo = new Blob(recordedChunks);
                    this.newVideoURL = URL.createObjectURL(this.newVideo);
                    this.uploading = true;
                    this.filming = false;

                    postTestMedia(this.testId, this.newVideo, "mp4").then((response) => {
                        if (this.callback) {
                            let fileUrl = getBaseSgpUrl() + `privatefile?file_path=${response.data.file_key}`;
                            this.callback(this.newVideo, fileUrl);
                        }
                    }).catch((error) => {
                        console.log(error);
                    }).finally(() => {
                        this.uploading = false;
                        if (stream){
                            stream.getTracks().forEach(function(track) {
                                track.stop();
                            });
                        }
                    });
                });

                this.recorder.start();
            },
            async stop() {
                this.recorder.stop();
                this.recorder = null;
            }
        },
        mounted() {
            if (this.initial) {
                this.newVideoURL = this.initial;
            }
        }
    };
</script>

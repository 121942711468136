export function setCookie(name, value) {
    document.cookie = name + "=" + value + "; max-age=86400;SameSite=None;Secure";
}

export function getCookie(name, defaultValue) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    let result;
    if (parts.length === 2) {
        result = parts.pop().split(';').shift();
    }
    if (result){
        return result
    }
    return defaultValue
}

export function deleteCookie(name) {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
}

export function isMobile(){
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
}

export function formatDbDate(dbDate){
    let date = new Date(dbDate.replace(/-/g, '/'));
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${day}/${month}/${date.getFullYear()}`;
}

export function formatDbDatetime(dbDatetime){
    let date = new Date(dbDatetime);
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let time = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    return `${day}/${month}/${date.getFullYear()} ${time}:00`;
}

export function secondsToDate(seconds){
    let date = new Date(1970, 0, 1);
    date.setSeconds(seconds);
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${second}`;
}

export function removeTags(htmlText, tagsToRemove){
    let result = htmlText;
    tagsToRemove.forEach((tag) => {
        let div = document.createElement('div');
        div.innerHTML = result;
        let elements = div.getElementsByTagName(tag);
        while (elements[0])
            elements[0].parentNode.removeChild(elements[0]);
        result = div.innerHTML;
    });
    result = result.replace(/&nbsp;/gi, "");
    return result;
}

export function stripTags(htmlText){
    return htmlText.replace(/(<([^>]+)>)/gi, "");
}

export function readMore(input, maxWords) {
    let strippedString = input.trim();
    let array = strippedString.split(" ");
    let wordCount = array.length;
    let result = array.splice(0, maxWords).join(" ");

    if(wordCount > maxWords) {
        result += "...";
    }

    return result;
}

export function stripQuestionTags(htmlText, full){
    let result = stripTags(removeTags(htmlText, ['img','table','tbody','tr','td']));
    if (!full){
        result = readMore(result, 50);
    }
    return result
}

export function orderToChar(order){
    return String.fromCharCode(64 + order);
}

export function getQueryParam(paramName) {
    let queryParams = location.href.split("?")[1]?.split("&");
    if (queryParams){
        let foundParam = queryParams.find((param) => param.split("=")[0] === paramName);
        if (foundParam){
            return foundParam.split("=")[1].replace("/", "").replace("#", "");
        }
    }
    return null;
}

export function getBase64(file, callback) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callback(reader.result);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export function getFileNameFromPath(path) {
    let splitName = path.split("/");
    return splitName[splitName.length - 1];
}
<template>
    <div aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-history"></i> Provas realizadas</p>
            </div>
        </div>
        <div class="col-xs-12 p-x-sm" v-if="result !== null">
            <div class="col-xs-12 m-b-20">
                <div class="card p-20 text-center">
                    <div class="cursor-pointer" @click="expandFilters = !expandFilters">
                        <div class="col-xs-12 text-center">
                            <b>Filtrar provas</b>
                            <i class="fa pull-right" :class="expandFilters ? 'fa-caret-up' : 'fa-caret-down'"></i>
                        </div>
                    </div>
                    <div class="col-xs-12 filter-container" :class="expandFilters ? '' : 'filter-collapsed'">
                        <div class="col-xs-6">
                            <b>Anos</b>
                        </div>
                        <div class="col-xs-6">
                            <b>Meses</b>
                        </div>
                        <div class="col-xs-12 p-0 m-t-lg flex-align-center flex-justify-center d-flex-row">
                            <div class="col-xs-6">
                                <div class="col-xs-12 text-center p-0">
                                    <div class="d-inline-block p-x-md" v-for="year in availableFilter.years" :key="year">
                                        <button class="btn m-t-sm m-b-sm" @click="toggleSearch(year, filter.years)"
                                           :class="filter.years.indexOf(year) >= 0 ? 'btn-primary' : 'btn-default'">
                                            {{ year }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div class="col-xs-12">
                                    <div class="col-xs-4 col-md-2" v-for="month in availableFilter.months" :key="month">
                                        <button class="btn m-t-sm m-b-sm" @click="toggleSearch(month, filter.months)"
                                           :class="filter.months.indexOf(month) >= 0 ? 'btn-primary' : 'btn-default'">
                                            {{ month }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-lg-4 p-md" v-for="(test) in getFilteredResult()" :key="test">
                <div class="card p-20">
                    <div class="d-flex-row">
                        <div class="col-xs-7 p-none d-flex-column text-left">
                            <h4 class="level m-b-sm">{{ test.schedule.type_assessment.name }}</h4>
                            <small class="level m-b-sm" v-for="academic in test.academic.split(' / ')" :key="academic">
                                {{ academic }}
                            </small>
                        </div>
                        <div class="exam col-xs-5 d-flex-column flex-justify-between p-none">
                            <div class="exam-info d-flex-column flex-align-end text-right p-none">
                                <small class="info p-b-md"><i class="fa fa-calendar"></i> {{ formatDbDate(test.schedule.date_schedule) }} às {{ test.schedule.time_schedule }}</small>
                                <small class="info p-b-md" v-if="test.attendance"><i class="fa fa-map-marker"></i> Presente</small>
                                <small class="info p-b-md" v-if="!test.attendance"><i class="fa fa-times"></i> Ausente</small>
                                <span v-if="test.score_show && test.configs.enable_show_score_candidate" class="badge bg-success rounded-full">{{ test.score }} / {{ test.value }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="actions d-flex-row flex-justify-start m-t-lg">
                        <button v-if="test.can_be_retried" class="btn btn-primary m-r-md" @click="retryModalId = test.item_generated">
                            Nova tentativa
                        </button>
                        <router-link class="btn btn-default" v-if="test.score_show && !test.configs.hide_see_documents"
                                     :to="`/finished-tests/${test.item_generated}`">
                            Ver documentos
                        </router-link>
                    </div>
                </div>
            </div>

            <modal-dialog v-show="retryModalId" @close="retryModalId = confirmationText = retryReason = null">
                <h3 class="m-b-md">ATENÇÃO</h3>
                <div class="col-xs-12 text-left">
                    <p>Realmente deseja cancelar sua avaliação para realizar uma nova tentativa?<br/>
                    Ao confirmar a ação sua avaliação será cancelada e uma nova avaliação ficará disponível.<br/>
                    Nenhuma informação referente a esta primeira aplicação será guardada - questões, respostas, alternativas marcadas.</p>

                    <p>Digite CANCELAR para confirmar a ação:</p>
                    <div class="input-text input">
                        <input v-model="confirmationText" type="text" placeholder="Digite CANCELAR...">
                    </div>

                    <p>Motivo:</p>
                    <div class="input-text input">
                        <textarea v-model="retryReason" type="text"></textarea>
                    </div>

                    <p>*Todos os campos são obrigatórios</p>

                    <div class="col-xs-12 p-0 m-t-md text-right">
                        <button class="btn btn-danger m-r-sm" @click="retryModalId = null">Não</button>
                        <button class="btn btn-primary" @click="retryOnlineTest()"
                           v-bind="loading || confirmationText !== 'CANCELAR' || !retryReason ? {disabled: 'disabled'} : {}">
                            <i class="fa fa-spin fa-circle-o-notch" v-if="loading"></i> Sim
                        </button>
                    </div>
                </div>
            </modal-dialog>

            <modal-dialog v-show="errorDescription" @close="errorDescription = null">
                <h3 class="m-b-md">Erro ao solicitar nova tentativa</h3>
                <div class="col-xs-12">
                    {{ errorDescription }}
                </div>
            </modal-dialog>
        </div>
        <div v-else class="col-xs-12 d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
            <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
        </div>
    </div>
</template>

<script>
    import {getFinishedTests, postRetryOnlineTest} from "../../scripts/apiService/tests";
    import {formatDbDate} from "../../scripts/utils";
    import ModalDialog from "../base/Modal";

    export default {
        name: 'FinishedTestsList',
        components: {ModalDialog},
        data: () => { return {
            result: null,
            retryModalId: null,
            confirmationText: null,
            retryReason: null,
            loading: null,
            errorDescription: null,
            filter: {years: [], months: []},
            availableFilter: {years: [], months: [...Array(12).keys()].map(index => index + 1)},
            expandFilters: true,
        }},
        methods: {
            formatDbDate: formatDbDate,
            getFilteredResult() {
                let filteredResult = this.result;
                if (this.filter.years.length) {
                    filteredResult = filteredResult.filter((test) => {
                        let year = parseInt(test.schedule.date_schedule.split("-")[0]);
                        return this.filter.years.indexOf(year) >= 0
                    });
                }
                if (this.filter.months.length) {
                    filteredResult = filteredResult.filter((test) => {
                        let month = parseInt(test.schedule.date_schedule.split("-")[1]);
                        return this.filter.months.indexOf(month) >= 0
                    });
                }
                return filteredResult
            },
            toggleSearch(value, searchList){
                let valuePosition = searchList.indexOf(value);
                if (valuePosition >= 0) {
                    searchList.splice(valuePosition, 1);
                } else {
                    searchList.push(value);
                }
            },
            retryOnlineTest(){
                this.loading = true;
                postRetryOnlineTest(this.retryModalId, {
                    use_new_layout: true,
                    reason: this.retryReason
                }).then((response) => {
                    let testId = response.data.item_id;
                    this.$router.push(`/online-tests/${testId}/instructions`);
                }).catch((error) => {
                    this.errorDescription = error.response.data.error;
                    this.loading = false;
                })
            }
        },
        mounted() {
            getFinishedTests(this.$route.params.key).then((response) => {
                this.result = response.data;
                let years = [];
                this.result.forEach((test) => {
                    let year = test.schedule.date_schedule.split("-")[0];
                    years.push(parseInt(year));
                });
                this.availableFilter.years = new Set(years);
            });
            document.getElementById('title').focus();
        },
    }
</script>
<template>
  <div id="loading-container"><i class="fa fa-circle-o-notch fa-5x fa-spin"></i></div>
</template>

<script>
export default {
  name: 'LoadingScreen'
}
</script>


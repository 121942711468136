import {getCookie} from "../utils";
import {getRequest, postRequest} from "./ApiService";

export function getOnlineQuestionnaires(){
    let candidateKey = getCookie("currentCandidate");
    return getRequest('onlineQuestionnairesList', {"candidateKey": candidateKey})
}
export function getOnlineQuestionnaireQuestions(id){
    let candidateKey = getCookie("currentCandidate");
    return getRequest('onlineQuestionnaireQuestions', {"candidateKey": candidateKey, "id": id})
}
export function postOnlineQuestionnaireQuestions(id, answers){
    let candidateKey = getCookie("currentCandidate");
    return postRequest('onlineQuestionnaireFinish', answers, {"candidateKey": candidateKey, "id": id})
}
<template>
    <div aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-search"></i> Materiais de estudo</p>
            </div>
        </div>
        <div class="col-xs-12 p-x-sm" v-if="result !== null">
            <div class="col-xs-12 col-md-6 col-lg-4 p-md" v-for="(content) in result" :key="content">
                <div class="card p-20 text-left">
                    <div class="d-flex-row flex-justify-between m-b-sm">
                        <h4>{{ content.name }}</h4>
                        <small class="info">
                            <i class="fa fa-calendar"></i> {{ formatDbDatetime(content.last_update) }}
                        </small>
                    </div>
                    <div class="m-b-lg"><small class="color-gray">{{ content.content }}</small></div>

                    <span class="m-t-sm">{{ readMore(content.description, 50) }}</span>
                    <see-more-modal modal-title="Descrição" :modal-content="content.description"></see-more-modal>

                    <div class="col-xs-12 p-0 text-right m-t-lg">
                        <a class="btn btn-default" :href="content.link" target="_blank">
                            <i class="fa fa-download"></i> Acessar material
                        </a>
                    </div>
                </div>
            </div>    
        </div>
        <div v-else class="col-xs-12 d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
            <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
        </div>
    </div>
</template>

<script>
    import {getExtraContents} from "../../scripts/apiService/content";
    import SeeMoreModal from "../includes/SeeMoreModal";
    import {formatDbDatetime, readMore} from "../../scripts/utils";

    export default {
        name: 'ExtraContentList',
        components: {SeeMoreModal},
        data: () => { return {
            result: null,
        }},
        methods: {
            readMore: readMore,
            formatDbDatetime: formatDbDatetime
        },
        mounted() {
            getExtraContents().then((response) => {
                this.result = response.data;
            });
            document.getElementById('title').focus();
        },
    }
</script>
import axios from 'axios'
import {getCookie} from "../utils";

let apiUrls = {
    candidateInfo: 'schedule/candidate-menu/{candidateKey}/',
    upcomingTestsList: 'api/v1/s/candidate-menu/{candidateKey}/upcoming-tests/',
    finishedTestsList: 'api/v1/s/candidate-menu/{candidateKey}/finished-tests/',
    finishedTestData: 'api/v1/s/candidate-menu/{candidateKey}/finished-test/{testId}/',
    finishedTestPages: 'api/v2/ot/finished-tests/{testId}/pages/',
    retryOnlineTest: 'api/v1/s/candidate-menu/{candidateKey}/retry-test/{testId}/',
    appealCreateQuestions: 'api/v1/s/candidate-menu/{candidateKey}/appeal-create-questions/{testId}/',
    appealsList: 'api/v1/s/candidate-menu/{candidateKey}/appeals/',
    onlineTestsList: 'api/v1/s/candidate-menu/{candidateKey}/online-tests/',
    onlineTestInstructions: 'api/v1/s/online-tests/{candidateKey}/{testId}/instructions-and-release/',
    onlineTestConfigs: 'api/v1/po/rest/provaonline/{testId}/basicconfig/',
    onlineQuestionnairesList: 'api/v1/s/candidate-menu/{candidateKey}/questionnaires/',
    onlineQuestionnaireQuestions: 'api/v1/s/candidate-menu/{candidateKey}/questionnaires/{id}/',
    onlineQuestionnaireFinish: 'api/v1/s/candidate-menu/{candidateKey}/questionnaires/{id}/finish/',
    extraContentList: 'api/v1/s/candidate-menu/{candidateKey}/extra-contents/',
    reportsList: 'api/v1/s/candidate-menu/reports/',
    reportInfo: 'api/v1/s/candidate-menu/{candidateKey}/report/{reportSlug}/',
    executeReport: 'api/v1/s/candidate-menu/{candidateKey}/reportresult/{reportSlug}/',
    registerInfraction: 'api/v1/s/register-infraction/'
};

let newApiUrls = {
    candidateInfo: 'api/v2/ot/get-menu/',
    upcomingTestsList: 'api/v2/ot/future-tests/',
    finishedTestsList: 'api/v2/ot/finished-tests/',
    finishedTestData: 'api/v2/ot/finished-tests/{testId}/',
    finishedTestPages: 'api/v2/ot/finished-tests/{testId}/pages/',
    retryOnlineTest: 'api/v2/ot/finished-tests/retry/{testId}/',
    appealCreateQuestions: 'api/v2/ot/appeals/create-info/{testId}/',
    appealsList: 'api/v2/ot/appeals/',
    onlineTestsList: 'api/v2/ot/online-tests/',
    registerInfraction: 'api/v2/ot/online-tests/infraction/',
    onlineTestInstructions: 'api/v2/ot/online-tests/{testId}/instructions-release/',
    onlineTestConfigs: 'api/v2/ot/online-tests/{testId}/base-configs/',
    saveMedia: 'api/v2/ot/online-tests/{testId}/save-media/',
    onlineQuestionnairesList: 'api/v2/ot/questionnaires/',
    onlineQuestionnaireQuestions: 'api/v2/ot/questionnaires/{id}/',
    onlineQuestionnaireFinish: 'api/v2/ot/questionnaires/{id}/finish/',
    extraContentList: 'api/v2/ot/extra-contents/',
    reportsList: 'api/v2/ot/reports/',
    reportInfo: 'api/v2/ot/reports/{reportSlug}/',
    certificateList: 'api/v2/ot/certificates/',
    executeReport: 'api/v2/ot/reports/{reportSlug}/run/',
    schedulingList: 'api/v2/ot/schedules/available/',
    schedulingData: 'api/v2/ot/schedules/{id}/',

    newSubscription: 'subscriptions/',
    cancelSubscription: 'subscriptions/{id}/cancel/',
    settings: 'settings/{id}/'

};

export function getBaseSgpUrl(){
    return process.env.VUE_APP_SGP_BASE_URL
        .replace('{origin}', getCookie("sgpBaseUrl", ""))
        .replace("%5C", "/")
        .replace("\\", "/");
}

export function getBaseSgpUrlNoSlug(){
    let baseUrlWithClientSlug = getBaseSgpUrl();

    let splitUrl = baseUrlWithClientSlug.split('/');
    let clientSlug = splitUrl[splitUrl.length - 2];
    return baseUrlWithClientSlug.replace("/" + clientSlug + "/", "");
}

export function postRequest(api, data, pathParams, extraHeaders, fullUrl) {
    let options = {
        headers: {"Content-Type": "application/json"},
        withCredentials: true
    };

    let url = apiUrls[api];

    if (getCookie('candidateToken')) {
        options.headers.Authorization = 'Token ' + getCookie('candidateToken');
        url = newApiUrls[api];
    }

    if (fullUrl) {
        url = fullUrl;
    }

    if (pathParams) {
        for (let [key, value] of Object.entries(pathParams)) {
            url = url.replace("{" + key + "}", value);
        }
    }

    if (extraHeaders) {
        for (let [key, value] of Object.entries(extraHeaders)) {
            options.headers[key] = value;
        }
    }
    
    return axios.post(getBaseSgpUrl() + url, data, options)
}

export function getRequest(api, pathParams, queryParams, extraHeaders, fullUrl) {
    let options = {
        headers: {"Content-Type": "application/json"},
        withCredentials: true
    };

    let url = apiUrls[api];

    if (getCookie('candidateToken')) {
        options.headers.Authorization = 'Token ' + getCookie('candidateToken');
        url = newApiUrls[api];
    }

    if (fullUrl) {
        url = fullUrl;
    }

    if (pathParams) {
        for (let [key, value] of Object.entries(pathParams)) {
            url = url.replace("{" + key + "}", value);
        }
    }

    if (queryParams) {
        let prefix = '?';
        for (let [key, value] of Object.entries(queryParams)) {
            url = url + prefix + key + '=' + value;
            prefix = '&';
        }
    }

    if (extraHeaders) {
        for (let [key, value] of Object.entries(extraHeaders)) {
            options.headers[key] = value;
        }
    }

    return axios.get(getBaseSgpUrl() + url, options)
}

export function getSchedulingRequest(tenantId, api, pathParams, queryParams) {
    let options = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": tenantId + " " + getCookie("refreshToken")
        },
    };

    let url = newApiUrls[api];

    if (pathParams) {
        for (let [key, value] of Object.entries(pathParams)) {
            url = url.replace("{" + key + "}", value);
        }
    }

    if (queryParams) {
        let prefix = '?';
        for (let [key, value] of Object.entries(queryParams)) {
            url = url + prefix + key + '=' + value;
            prefix = '&';
        }
    }

    return axios.get(process.env.VUE_APP_SCHEDULING_BASE_URL + url, options)
}

export function postSchedulingRequest(tenantId, api, pathParams, data) {
    let options = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": tenantId + " " + getCookie("refreshToken")
        },
    };

    let url = newApiUrls[api];

    if (pathParams) {
        for (let [key, value] of Object.entries(pathParams)) {
            url = url.replace("{" + key + "}", value);
        }
    }

    return axios.post(process.env.VUE_APP_SCHEDULING_BASE_URL + url, data, options)
}

<template>
    <div aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-clock-o"></i> Agendar provas</p>
            </div>
        </div>
        <div v-if="!loading">
            <div class="col-xs-12 p-x-sm" v-if="result && result.length > 0">
                <div class="col-xs-12 col-md-6 col-lg-4 p-md" v-for="scheduling in getGroupedSchedulings()" :key="scheduling.id">
                    <div class="card p-20">
                        <div class="d-flex-row flex-justify-between m-b-md">
                            <div>
                                <h4 class="level m-b-sm text-left" :key="academic"
                                    v-for="academic in scheduling.academics">{{ academic.name }}</h4>
                            </div>
                            <small class="info">
                                {{ scheduling.test_type.name }}
                            </small>
                        </div>
                        <div class="d-flex-column p-y-md text-left">
                            <b>Periodo de inscrições</b>
                            <span>{{ formatDbDatetime(scheduling.start_date) }}
                                a {{ formatDbDatetime(scheduling.end_date) }}</span>
                        </div>
                        <div v-if="scheduling.subscribed">
                            <div class="d-flex-column p-y-md text-left">
                                <b>Data/Hora da prova</b>
                                <span>{{ formatDbDatetime(scheduling.test_date.date) }}</span>
                            </div>
                            <div class="d-flex-column p-y-md text-left" v-for="placeData in getPlaceTree(scheduling.test_date.place)" :key="placeData.name">
                                <b>{{ placeData.config }}</b>
                                <span>{{ placeData.name }}</span>
                            </div>
                        </div>
                        <div class="col-xs-12 m-t-lg">
                            <button class="col-xs-12 btn btn-primary" @click="() => openSubscriptionModal(scheduling)"
                                    v-if="!scheduling.subscribed">
                                Inscrever
                            </button>
                            <button class="col-xs-12 btn btn-danger" v-else-if="schedulingSubscribedFuture(scheduling)"
                                    @click="() => {cancellingSubscription = scheduling.subscribed}">
                                Cancelar inscrição
                            </button>
                        </div>
                    </div>
                </div>    
            </div>
            <div v-else>
                <span><b>Não há agendamentos disponíveis</b></span>
            </div>
        </div>
        <div role="presentation" v-else class="col-xs-12 d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
            <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
        </div>

        <modal-dialog v-show="currentScheduling || modalData.loading" @close="currentScheduling = null; modalData.loading = false" mask-class="max-width">
            <div v-if="!currentScheduling || modalData.loading"
                 class="col-xs-12 d-flex-column align-center flex-justify-center text-center h-50vh overflow-hidden">
                <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
            </div>
            <div v-else class="col-xs-12 d-flex-column align-center flex-justify-center">
                <h3 class="text-left">Inscrever</h3>
                <p class="text-left">Selecione abaixo a data, horário e local da sua prova.</p>
                <div class="col-xs-12 p-0 m-t-md input-select input">
                    <label>Data</label>
                    <select v-model="modalSelection.date" class="w-100" @change="changeTestDate">
                        <option v-for="date in getTestDates()" :key="date" :value="date.value">
                            {{ date.label }}
                        </option>
                    </select>
                </div>
                <div class="col-xs-12 p-0 m-t-md text-left" v-if="modalSelection.date">
                    <p>Selecione o horário da prova</p>

                    <div v-for="time in getTestTimes()"
                         :key="time" class="col-xs-6 p-0">
                        <input :value="time.value" type="radio" v-model="modalSelection.dateTime"
                               @change="modalSelection.places = []">
                        <span>{{ time.label }}</span>
                    </div>
                </div>

                <div class="col-xs-12 p-0 m-t-md text-left" v-if="modalSelection.dateTime && this.currentScheduling.select_place">
                    <p class="m-b-xs">Selecione o local da prova</p>

                    <div v-for="index in getPlaceOrders()"
                         :key="index" class="col-xs-6 p-0 input input-select m-t-md" :class="index % 2 === 0 ? 'p-r-md' : ''">
                        <label v-if="index === 0 || modalSelection.places[index-1]">
                            {{ getPlaceOptions(index)[0].config_name }}
                        </label>
                        <select class="w-100" v-if="index === 0 || modalSelection.places[index-1]"
                                v-model="modalSelection.places[index]" @change="changePlace(index)">
                            <option :value="undefined" class="d-none">Selecione</option>
                            <option v-for="option in getPlaceOptions(index)" :key="option" :value="option">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 p-0 m-t-xl m-b-lg text-right">
                    <a class="btn btn-default m-r-md" @click="currentScheduling = null">Cancelar</a>
                    <button class="btn btn-primary" @click="saveSubscription"
                       v-bind="modalData.saving || !finishedForm() ? {disabled: 'disabled'} : {}">
                        <i class="fa fa-spin fa-circle-o-notch" v-if="modalData.saving"></i> Salvar</button>
                </div>
            </div>
        </modal-dialog>

        <modal-dialog v-show="successMsg" @close="successMsg = null">
            <div class="col-xs-12 d-flex-column align-center flex-justify-center">
                <h3 class="text-left">Inscrição finalizada</h3>
                <div class="col-xs-12 p-0 text-left m-t-xl" v-html="successMsg"></div>
                <div class="col-xs-12 p-0 text-right m-t-xl m-b-lg">
                    <a class="btn btn-primary" @click="successMsg = null">Entendi</a>
                </div>
            </div>
        </modal-dialog>

        <modal-dialog v-show="errorMsg" @close="errorMsg = null">
            <div class="col-xs-12 d-flex-column align-center flex-justify-center">
                <h3 class="text-left">Erro</h3>
                <div class="col-xs-12 p-0 text-left m-t-xl">
                    {{ errorMsg }}
                </div>
                <div class="col-xs-12 p-0 text-right m-t-xl m-b-lg">
                    <a class="btn btn-primary" @click="errorMsg = null">Entendi</a>
                </div>
            </div>
        </modal-dialog>

        <modal-dialog v-show="cancellingSubscription" @close="cancellingSubscription = null">
            <div class="modal-contour vertical-center m-b-xl">
                <div class="p-0 form-group text-center">
                    <h4 class="text-bold m-0 color-danger">
                        Você tem certeza que quer cancelar a inscrição?
                    </h4>
                </div>
            </div>
            <div class="col-sm-12 p-0 buttons-container text-right">
                <span class="btn-container">
                    <button class="btn btn-default text-success m-r-lg"
                       @click="cancelSubscription()" v-bind="cancelling ? {disabled: true} : {}">
                        <i class="fa fa-circle-o-notch fa-spin" v-if="cancelling"></i> Sim</button>

                    <button class="btn btn-danger" @click="cancellingSubscription = null">Não</button>
                </span>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import {formatDbDate, formatDbDatetime} from "../../scripts/utils";
    import {
        getSchedules,
        getSchedulingData, getSettings,
        postSchedulingSubscription,
        subscriptionCancel
    } from "../../scripts/apiService/scheduling";
    import ModalDialog from "../base/Modal";

    export default {
        name: 'SchedulingList',
        components: {ModalDialog},
        data: () => { return {
            result: null,
            groupAcademics: null,
            currentScheduling: null,
            loading: false,
            modalData: {},
            modalSelection: {},
            successMsg: null,
            errorMsg: null,
            cancellingSubscription: null,
            cancelling: false,
            accessDate: new Date()
        }},
        methods: {
            formatDbDate: formatDbDate,
            formatDbDatetime: formatDbDatetime,
            getPlaceTree(place) {
                let result = [{config: place.config_name, name: place.name}]
                let currParent = place;
                while (currParent.parent) {
                    currParent = currParent.parent;
                    result.unshift({config: currParent.config_name, name: currParent.name});
                }
                return result;
            },
            openSubscriptionModal(scheduling) {
                this.modalSelection = {
                    places: [],
                    academics: Array.from(new Set(scheduling.academics.map(academic => academic.id)))
                };
                this.modalData.loading = true;
                this.modalData.candidateId = scheduling.candidate_id;
                this.modalData.academicNames = scheduling.academics.map(academic => academic.name);
                getSchedulingData(this.$store.state.main.candidateInfo.client, scheduling.id).then((response) => {
                    this.currentScheduling = response.data;
                    this.modalData.loading = false;
                });
            },
            getTestDates() {
                let dates = [];
                let formattedDate;
                this.currentScheduling.test_dates.forEach((testDate) => {
                    formattedDate = formatDbDatetime(testDate.date);
                    if (!dates.find((date) => formattedDate.split(" ")[0] === date.label)){
                        dates.push({
                            label: formattedDate.split(" ")[0],
                            value: testDate.date.split("T")[0]
                        });
                    }
                });
                return dates;
            },
            changeTestDate() {
                this.modalSelection.dateTime = null;
                this.modalSelection.places = [];
            },
            getTestTimes() {
                let times = [];
                let formattedDate;
                this.currentScheduling.test_dates.forEach((testDate) => {
                    if (testDate.date.split("T")[0] === this.modalSelection.date) {
                        formattedDate = formatDbDatetime(testDate.date);
                        times.push({
                            label: formattedDate.split(" ")[1],
                            value: testDate
                        });
                    }
                });
                return times;
            },
            getPlaceOrders(){
                let testDate = this.currentScheduling.test_dates[0];
                let maxOrder = 1;
                let currentPlace = testDate.places[0].place;

                while (currentPlace.parent) {
                    maxOrder ++;
                    currentPlace = currentPlace.parent;
                }

                return Array.from({length: Math.min(maxOrder, 2)}, (x, i) => i);
            },
            getPlaceOptions(index) {
                let testDate = this.modalSelection.dateTime;
                let parent = index > 0 ? this.modalSelection.places[index-1] : null;

                let testPlaceList = testDate.places.map((testDatePlace) =>
                    this.getParentFromOrder(testDatePlace.place, index + 1)
                );

                if (parent) {
                    testPlaceList = testPlaceList.filter((place) => place.parent.id === parent.id)
                }

                let uniquePlaces = [];

                testPlaceList.forEach((testPlace) => {
                    if (!uniquePlaces.find((place) => place.legacy_key === testPlace.legacy_key)){
                        uniquePlaces.push(testPlace);
                    }
                });

                return uniquePlaces;
            },
            getParentFromOrder(place, order){
                let currentPlace = place;
                while (currentPlace.config_order > order) {
                    currentPlace = currentPlace.parent;
                }
                return currentPlace;
            },
            changePlace(index) {
                this.modalSelection.places = this.modalSelection.places.slice(0, index + 1);
            },
            saveSubscription() {
                let data = {
                    candidate_id: this.modalData.candidateId,
                    test_date_id: this.modalSelection.dateTime.id,
                    academics: this.modalSelection.academics,
                    access_date: this.accessDate,
                };

                if (this.currentScheduling.select_place) {
                    let place = this.modalSelection.places[this.modalSelection.places.length - 1];
                    data.place_id = place.id;
                }
                this.modalData.saving = true;
                postSchedulingSubscription(this.$store.state.main.candidateInfo.client, data).then((postResponse) => {
                    this.modalData.saving = false;
                    this.currentScheduling = null;

                    let parameterId = 1;

                    getSettings(this.$store.state.main.candidateInfo.client, parameterId).then((response) => {

                        if (response.data.value){
                            let msg = response.data.value;
                            // Dinamiza os dados no modal
                            msg = msg.replace("{NOME}", this.$store.state.main.candidateInfo.firstName);
                            msg = msg.replace("{DOCUMENTO}", this.$store.state.main.candidateInfo.documentId);
                            msg = msg.replace("{ACADEMICO}", this.modalData.academicNames.join(", "));
                            msg = msg.replace("{DATAHORAPROVA}", formatDbDatetime(this.modalSelection.dateTime.date));
                            msg = msg.replace("{LOCALPROVA}", postResponse.data.place.name);

                            this.successMsg = msg;
                        } else {
                            this.successMsg = `Prezado(a) ${this.$store.state.main.candidateInfo.firstName}<br/><br/>Agradecemos o seu interesse e confirmamos que sua inscrição foi recebida com sucesso.<br/><br/>Em breve entraremos em contato com mais informações.`
                        }

                        this.loadInitial();
                    });
                }).catch((error) => {
                    if (error.response.data === "capacity_exceeded") {
                        this.errorMsg = "A capacidade máxima do local foi atingido. Não foi possível finalizar a inscrição";
                    } else if (error.response.data === "no_place") {
                        this.errorMsg = "Não foi possível encontrar local associado a você ou à sua unidade";
                    } else if (error.response.data === "data_changed") {
                        this.errorMsg = "Os dados do agendamento foram alterados. Por favor, recarregue a página e tente novamente";
                    } else {
                        this.errorMsg = error.response.data;
                    }
                    this.modalData.saving = false;
                });
            },
            getGroupedSchedulings() {
                let listing = [];
                let countedSchedules = {};
                this.result.forEach((scheduling) => {
                    let distinctKey = scheduling.id + '-' + (this.groupAcademics ? '' : scheduling.academic.id);
                    let countedSchedule = countedSchedules[distinctKey];
                    if (countedSchedule) {
                        countedSchedule.subscribed = countedSchedule.subscribed || scheduling.subscribed;
                        countedSchedule.test_dates.push(scheduling.test_date);
                        if (!countedSchedule.academics.find(academic => academic.id === scheduling.academic.id))
                            countedSchedule.academics.push(scheduling.academic);
                        if (scheduling.subscribed)
                            countedSchedule.test_date = scheduling.test_date
                    } else {
                        let schedulingCopy = JSON.parse(JSON.stringify(scheduling));
                        delete schedulingCopy.academic;
                        if (!schedulingCopy.subscribed)
                            delete schedulingCopy.test_date;

                        countedSchedules[distinctKey] = schedulingCopy;
                        listing.push(schedulingCopy);

                        schedulingCopy.test_dates = [scheduling.test_date];
                        schedulingCopy.academics = [scheduling.academic];
                    }
                });
                return listing;
            },
            loadInitial() {
                this.loading = true;
                getSchedules().then((response) => {
                    this.result = response.data.schedule_periods;
                    this.groupAcademics = response.data.group_academics;
                    this.loading = false;
                });
            },
            cancelSubscription() {
                this.cancelling = true;
                subscriptionCancel(this.$store.state.main.candidateInfo.client, this.cancellingSubscription).then(() => {
                    this.result.forEach((scheduling) => {
                        if (scheduling.subscribed === this.cancellingSubscription) {
                            scheduling.subscribed = null;
                        }
                    });
                    this.cancelling = false;
                    this.cancellingSubscription = null;
                })
            },
            schedulingSubscribedFuture(obj) {
                const now = new Date();
                return new Date(obj.start_date) < now && now < new Date(obj.end_date);
            },
            finishedForm(){
                if (!this.currentScheduling.select_place) {
                    return this.modalSelection.dateTime;
                }
                return this.modalSelection.places[this.getPlaceOrders().length - 1];
            }
        },
        mounted() {
            this.loadInitial();
            document.getElementById('title').focus();
        },
    }
</script>

<template>
    <div class="wrapper" v-if="useFacialRecognition">
        <modal-dialog mask-class="bg-blank" :hide-close="true" v-if="facialRecognitionFail">
            <h3 class="modal-title text-center" id="modal-title">
                Reconhecimento de Face
            </h3>

            <div class="area">
                <p class="recognition-info">
                    <strong>
                        O reconhecimento da sua face falhou. Se atente à posição em frente à camera, a luminosidade do
                        local onde você está e a itens que podem prejudicar a acurácia.
                    </strong>
                </p>
            </div>
            <div class="modal-footer d-flex-row flex-justify-center">
                <button class="btn btn-primary" @click="candidateRetryFacialRecognition()">Tentar novamente</button>
            </div>
        </modal-dialog>
        <div class="m-t-xl">
            <video ref="webCamera" autoplay muted playsinline style="width: 100%; height: auto" v-show="!isLoading" title="Vídeo da sua câmera" id="cameraFrame"></video>

            <video ref="webCameraHidden" autoplay muted playsinline id="cameraFrameHidden" class="recognition-hidden-video"
                   style=""></video>
        </div>
        <div class="non-blocking-notification" v-if="facialRecognitionFailNonBlocking">
            <span id="close-button" class="close-button" @click="facialRecognitionFailNonBlocking=false">X</span>
            <p>
                O reconhecimento facial falhou. Por favor, verifique a posição em frente à câmera, a luminosidade do ambiente e outros fatores que podem afetar a precisão.
            </p>
        </div>
        <face-recognition-modal
                v-if="showModalFaceRecognition"
                :is-first-recognition="false"
                :onRecognitionSuccess="passFacialRecognition"
        ></face-recognition-modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import {v4 as uuidv4} from 'uuid';
    import {getBaseSgpUrlNoSlug} from "../../../scripts/apiService/ApiService";
    import {getCookie, getBase64} from "../../../scripts/utils";
    import ModalDialog from "../../base/Modal";
    import * as SimplePeer from 'simple-peer';
    import * as RecordRTC from 'recordrtc';
    import {registerInfraction} from "../../../scripts/apiService/proctoring";
    import {Kinesis, StreamProducer} from '../../../scripts/producer'
    import { screenStreamSubject$ } from "../../../scripts/observableStates";
    import FaceRecognitionModal from "./FaceRecognitionDirectiveModal";

    export default {
        name: "FaceRecognitionDirective",
        props: ['useFacialRecognition'],
        data() {
            let variables = this.$store.state.onlineTest.configs.variables;
            let urls = this.$store.state.onlineTest.configs.urls;
            return {
                isLoading: true,
                img: null,
                numberOfRecognitionTries: 0,
                itemGeneratedId: null,
                facialRecognitionFail: false,
                facialRecognitionFailNonBlocking: false,
                showModalFaceRecognition: false,
                faceRecognitionInterval: undefined,
                candidatePhoto: variables.candidate_photo,
                enableTakePhoto: variables.enable_take_photo,
                realTimePhotoValidation: variables.candidate_photo,
                uploadUrl: urls.facial_recognition_upload,
                urlSavePhoto: urls.facial_recognition_save_photo,
                bucketName: variables.bucket_name_s3,
                urlFacialRecognition: urls.facial_recognition,
                token: variables.token_facial_recognition_upload,
                nativeProctoring: variables.native_proctoring,
                peerCheckInterval: null,
                peerConnection: null,
                kinesisStream: true,
                screenStreamSubscription: null,
                failedFaceRecognitionNotification: variables.failed_face_recognition_notification
            }
        },
        components: {
            FaceRecognitionModal,
            ModalDialog
        },
        methods: {
            initConnectionServiceforStream(stream, streamType){
                if (stream) {
                    let url = process.env.VUE_APP_MONITORING_SERVICES_URL + "connection/";
                    let headers = {"content-type": "application/json"};
                    let data = {
                        "test_id": this.itemGeneratedId,
                        "origin": "candidate",
                        "type": streamType
                    };

                    let peerConnection = new SimplePeer({
                        initiator: true,
                        trickle: false,
                        stream: stream,
                        iceCompleteTimeout: 300000,
                        config: true
                    });

                    peerConnection.on('signal', (connString) => {
                        let initData = {
                            "test_id": this.itemGeneratedId,
                            "origin": "candidate",
                            "initialize": true,
                            "conn_string": JSON.stringify(connString),
                            "type": streamType
                        };

                        axios.post(url, initData, {headers: headers}).then(() => {
                            axios.post(url, data, {headers: headers}).then((response) => {
                                if (response.data.conn_string) {
                                    peerConnection.signal(response.data.conn_string);
                                    clearInterval(this.peerCheckInterval);
                                }
                            });
                            this.peerCheckInterval = setInterval(() => {
                                axios.post(url, data, {headers: headers}).then((response) => {
                                    if (response.data.conn_string) {
                                        peerConnection.signal(response.data.conn_string);
                                        clearInterval(this.peerCheckInterval);
                                    }
                                });
                            }, 5000);
                        });
                    });

                    peerConnection.on('close', () => {
                        this.initConnectionServiceforStream(stream, streamType);
                    });

                    peerConnection.on('error', (err) => {
                        console.log(err);
                    });
                }
            },
            async initKinesisConnection(source, sourceMedia){
                let url = process.env.VUE_APP_MONITORING_SERVICES_URL + "kinesis/credentials";
                let data = {params: {'test_id': this.itemGeneratedId}};
                let credentials = {};
                await axios.get(url, data).then(response => {
                    credentials = JSON.parse(response.data);
                }).catch(error => {
                    console.error(error)
                });
                let kinesis = new Kinesis({
                    channelName: `${this.itemGeneratedId}-${source}-candidate`,
                    streamName:`${this.itemGeneratedId}-${source}-candidate-stream`,
                    accessKeyId: credentials.AccessKeyId,
                    secretAccessKey: credentials.SecretAccessKey,
                    sessionToken: credentials.SessionToken,
                });
               let channelARN = await kinesis.createChannel();

               let streamProducer = new StreamProducer(channelARN, kinesis, sourceMedia);
               await streamProducer.start(source, null, false);
            },
            initRecording(camera, source){
                let url = process.env.VUE_APP_MONITORING_SERVICES_URL + "upload_clip/";
                let itemGeneratedId = this.itemGeneratedId;
                let counter = 0;
                let sessionUuid = uuidv4();
                let clientSlug = this.$store.state.onlineTest.configs.variables.client_slug;

                let recorder = RecordRTC(camera, {
                    recorderType: RecordRTC.MediaStreamRecorder,
                    mimeType: 'video/webm',
                    timeSlice: 5000,
                    ondataavailable: function(blob) {
                        let file = new File([blob], 'teste', {
                            type: 'video/webm'
                        });

                        let data = {name: source + " - " + counter,
                                    client_slug: clientSlug,
                                    item_generated_id: itemGeneratedId,
                                    session_uuid: sessionUuid};
                        getBase64(file, function(result){
                            data.clip = result;
                            axios.post(url, data, {
                                headers: {"Content-Type": "application/json"}
                            });
                        });
                        counter++;
                    }
                });
                recorder.startRecording();
                recorder.camera = camera;
            },
            startCameraShare() {
                let video = document.getElementById("cameraFrame");
                let snapShotVideo = document.getElementById("cameraFrameHidden");
                let component = this;
                navigator.mediaDevices.getUserMedia({audio: false, video: {facingMode: 'user'}})
                .then((stream) => {
                    video.srcObject = stream;
                    snapShotVideo.srcObject = stream;
                    window.stream = stream;
                    video.onloadedmetadata = component.callFacialRecognition;
                    if (component.nativeProctoring) {
                        component.initRecording(stream, "Camera");
                        component.initKinesisConnection("camera", stream);
                    }

                    stream.getTracks().forEach((track) => {
                        track.addEventListener('ended', () => {
                          track.stop();
                          this.startCameraShare();
                        });
                      });
                })
                .catch(function () {
                    alert("Habilite sua câmera e recarregue a página.");
                    location.reload();
                });
            },
            loadCamera() {
                let video = document.getElementById("cameraFrame");
                let snapShotVideo = document.getElementById("cameraFrameHidden");
                let component = this;
                if (window.stream) {
                    window.stream.getTracks()[0].stop();
                }
                if (video) {
                    if (navigator.mediaDevices.getUserMedia) {
                        const resolution =  {width: {ideal: 320}, height: {ideal: 240}, facingMode: "user"};
                        const constraints = {
                            video: resolution,
                            audio: false
                        };
                        navigator.mediaDevices.getUserMedia(constraints)
                            .then((stream) => {
                                video.srcObject = stream;
                                snapShotVideo.srcObject = stream;
                                window.stream = stream;
                                component.isLoading = false;
                                video.onloadedmetadata = component.callFacialRecognition;
                                if (this.nativeProctoring) {
                                    this.initRecording(stream, "Camera");
                                    this.initKinesisConnection("camera", stream);
                                }

                                stream.getTracks().forEach((track) => {
                                    track.addEventListener('ended', () => {
                                      track.stop();
                                      this.startCameraShare();
                                    });
                                  });

                            })
                            .catch(function () {
                                alert("Habilite sua câmera e recarregue a página.");
                                location.reload();
                            });
                    }
                }

                this.initScreenRecording();

            },
            initScreenRecording() {
                if (this.nativeProctoring && navigator.mediaDevices.getDisplayMedia) {
                    this.screenStreamSubscription = screenStreamSubject$.get().subscribe(stream => {
                        if (stream && stream.active) {
                            this.initRecording(stream, "Screen");
                            this.initKinesisConnection("screen", stream);
                        }
                    });
                }
            },
            retryFacialRecognition() {
                this.facialRecognitionFail = false;

                setTimeout(this.tryFacialRecognition, 1000)
            },
            tryFacialRecognition() {
                let currentCandidate = getCookie('currentCandidate');
                let baseUrl = getBaseSgpUrlNoSlug();
                let url = baseUrl + this.urlFacialRecognition;
                let data = {
                    candidate_key: currentCandidate,
                    data_url_image: this.img,
                    item_generated_id: this.itemGeneratedId,
                    is_first_recognition: this.isFirstRecognition,
                    is_skipped: false
                };
                const headers = {"X-CSRFTOKEN": getCookie('csrftoken'), "Authorization": 'Bearer ' + this.token};

                let component = this;

                axios.post(url, data, {headers: headers, withCredentials: true}).then(function (response) {
                    if (response.data.candidate_has_been_recognized) {
                        component.facialRecognitionFail = false;
                    } else {
                        component.facialRecognitionFail = true;
                        component.isLoading = false;
                        clearInterval(component.faceRecognitionInterval);
                    }
                }).catch(function () {
                    component.facialRecognitionFail = true;
                    component.isLoading = false;
                    clearInterval(component.faceRecognitionInterval);
                });
            },
            takePhoto() {
                this.$nextTick(() => {
                    let video = document.getElementById("cameraFrameHidden");
                    let canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    let ctx = canvas.getContext('2d');
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    this.img = canvas.toDataURL("image/jpeg");
                });
            },
            candidateRetryFacialRecognition() {
                this.facialRecognitionFail = false;
                this.showModalFaceRecognition = true;
            },
            callFacialRecognition() {
                this.faceRecognitionInterval = setInterval(() => {
                    this.startRecognition();
                }, 10000);
            },
            passFacialRecognition(){
                this.showModalFaceRecognition = false;
                this.callFacialRecognition();
            },
            startRecognition() {
                this.takePhoto();

                if (this.enableTakePhoto) {
                    this.uploadPhoto();
                } else {
                    this.tryFacialRecognition();
                }
            },
            uploadPhoto() {
                let data = {};
                let baseUrl = getBaseSgpUrlNoSlug();
                let urlSavePhoto = baseUrl + this.urlSavePhoto;

                if (this.realTimePhotoValidation) {
                    data = {
                        is_first_recognition: false,
                        is_skipped: false,
                        image_base64: this.img,
                        unlockable_facial_recognition: true,
                        item_generated_id: this.itemGeneratedId,
                        bucket_name: this.bucketName,
                        url_save_photo: urlSavePhoto,
                        candidate_photo: this.candidatePhoto,
                    };
                } else {
                    data = {
                        image_base64: this.img,
                        item_generated_id: this.itemGeneratedId,
                        bucket_name: this.bucketName,
                        url_save_photo: urlSavePhoto
                    };
                }

                const headers = {"Content-Type": "application/json", "Authorization": 'Bearer ' + this.token};

                axios.post(this.uploadUrl, data, {headers: headers, withCredentials: false})
                    .then(response => {
                        if (this.failedFaceRecognitionNotification && !response.data.is_candidate_recognized) {
                            this.facialRecognitionFailNonBlocking = true;
                            this.isLoading = false;
                        }
                    })
        },
            leaveExam(){
                if (this.nativeProctoring) {
                    let data = {
                        "infraction_type": "CHANGE_TAB",
                        "item_generated": this.itemGeneratedId
                    };
                    registerInfraction(data);
                }
            }
        },
        beforeUnmount() {
            if (window.stream){
                window.stream.getTracks().forEach(function(track) {
                    track.stop();
                });
            }
            if(this.enableTakePhoto) clearInterval(this.faceRecognitionInterval);
            if (this.screenStreamSubscription) this.screenStreamSubscription.unsubscribe();

        },
        mounted() {
            this.itemGeneratedId = this.$route.params.key;
            this.loadCamera();

            window.onblur = () => {
                this.leaveExam()
            };
        }
    }
</script>

<style scoped>
    .right-box {
        position: fixed;
        top: 420px;
        right: 20px;
    }

    #face-recognition-panel {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        height: auto;
        overflow: hidden;
    }

    .modal-wrapper {
        max-width: 96%;
    }
</style>

<style scoped>

.non-blocking-notification {
    bottom: 20px;
    left: 20px;
    background-color: rgba(255, 0, 0, 0.8);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    z-index: 9999;
    animation: slideInUp 0.5s ease forwards;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: bold;
    cursor: pointer;
    color: #272262ff;
}

@keyframes slideInUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>

let AWS = require('aws-sdk');
let KVSWebRtc = require('amazon-kinesis-video-streams-webrtc');

let log = {
    log: (eventMessageOrState) => {
        console.log(`🗨️ %c${eventMessageOrState}`, 'color: blue');
    },
    error: (eventMessageOrState) => {
        console.log(`🪳 %c${eventMessageOrState} `, 'color: red; font-weight:bold');
    },
    success: (eventMessageOrState) => {
        console.log(`✅ %c${eventMessageOrState} `, 'color: green');
    },
    warning: (eventMessageOrState) => {
        console.log(`🟠 %c${eventMessageOrState} `, 'color: orange');
    },
}

function KinesisProducerException(message) {
    this.message = message;
    this.name = "KinesisProducerException"
}


let Kinesis = function (options) {
    /**
     * @param {string} region
     * @param {string} accessKeyId
     * @param {string} secretAccessKey
     * @param {string} endpoint
     * @param {string} channelName
     * @param {boolean} forceTURN
     * @param {boolean} useTrickleICE
     * @param {boolean} natTransversalDisabled
     * @param {boolean} wideScreen
     */
    this.options = {
        region: process.env.VUE_APP_AWS_REGION,
        accessKeyId: null,
        secretAccessKey: null,
        sessionToken: null,
        channelName: null,
        streamName: null,
        forceTURN: false,
        useTrickleICE: true,
        natTransversalDisabled: false
    };

    if(options){
        Object.assign(this.options, options);
    }

};
async function streamFactory(videoClient, options) {
    let stream = null;

    try{
        await videoClient.createStream({
            StreamName: options.streamName,
            DataRetentionInHours: 24,
        })
        .promise()
        .then((result) => stream = result)
        .catch(error => {
            log.error(`Erro dectectado ${error}`)
            throw new KinesisProducerException(`Houve um problema ao tentar criar fluxo de vídeo ${options.streamName}`)
        })
    } catch(error) {
        log.error(error)
        await videoClient.describeStream({
            StreamName: options.streamName,
        })
        .promise()
        .then(result => stream = result.StreamInfo)
        .catch(error => {
            log.error(error)
            throw new KinesisProducerException(`Houve um erro ao tentar obter o fluxo de vídeo ${options.streamName}`)
        })
    }
    return stream
}

async function channelFactory(videoClient, options) {
    let channel;
    try{
        await videoClient.createSignalingChannel({
            ChannelName: options.channelName
        })
        .promise()
        .then(result => channel = result)
        .catch(error => {
            log.error(`Erro detectado: ${error}`)
            throw new KinesisProducerException(`Erro ao criar canal de sinalização ${options.channelName}`)
        })
    } catch (error) {
        log.error(error)
        await videoClient.describeSignalingChannel({
            ChannelName: options.channelName
        })
        .promise()
        .then(result => channel = result.ChannelInfo)
        .catch(error => {
            log.error(`Erro detectado ${error}`)
            throw new KinesisProducerException(`Erro ao obter canal de sinalização ${options.channelName}`)
        })
    }
    return channel
}

Kinesis.prototype.createChannel = async function () {
    let kinesisVideo = new AWS.KinesisVideo({
        region: this.options.region,
        accessKeyId: this.options.accessKeyId,
        secretAccessKey: this.options.secretAccessKey,
        sessionToken: this.options.sessionToken,
    });
    let stream = null;
    let signalingChannel = null;
    stream = await streamFactory(kinesisVideo, this.options)

    let endpoint = stream.DataEndpoint;

    let kinesisVideoSignalingChannels = new AWS.KinesisVideo({
        region: this.options.region,
        accessKeyId: this.options.accessKeyId,
        secretAccessKey: this.options.secretAccessKey,
        sessionToken: this.options.sessionToken,
        endpoint: endpoint,
    });
    signalingChannel = await channelFactory(kinesisVideoSignalingChannels, this.options)

    return await signalingChannel.ChannelARN;

};

let StreamProducer = function (channelARN, kinesisConfigInstance, localStream) {
    this.signalingClient = null;
    this.channelARN = channelARN;
    this.peerConnectionByClientId = {};
    this.dataChannelByClientId = {};
    this.localStream = localStream;
    this.peerConnectionStatsInterval = null;
    this.kinesis = kinesisConfigInstance;
};

StreamProducer.prototype.start = async function (source, onStatsReport, openDataChannel) {
    const videoClient = new AWS.KinesisVideo({
        region: this.kinesis.options.region,
        accessKeyId: this.kinesis.options.accessKeyId,
        secretAccessKey: this.kinesis.options.secretAccessKey,
        sessionToken: this.kinesis.options.sessionToken,
        correctClockSkew: true
    });

    let channelARN = this.channelARN;

    const getSignalingEndpointResponse = await videoClient.getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
            Protocols: ['WSS', 'HTTPS'],
            Role: KVSWebRtc.Role.MASTER,
        }
    }).promise();

    const endpointsByProtocol = getSignalingEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints
    }, {});

    this.signalingClient = new KVSWebRtc.SignalingClient({
        channelARN: channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        role: KVSWebRtc.Role.MASTER,
        region: this.kinesis.options.region,
        credentials: {
            accessKeyId: this.kinesis.options.accessKeyId,
            secretAccessKey: this.kinesis.options.secretAccessKey,
            sessionToken: this.kinesis.options.sessionToken,
        },
        systemClockOffset: videoClient.config.systemClockOffset
    });

    const kinesisVideoSinnalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
        region: this.kinesis.options.region,
        accessKeyId: this.kinesis.options.accessKeyId,
        secretAccessKey: this.kinesis.options.secretAccessKey,
        sessionToken: this.kinesis.options.sessionToken,
        endpoint: endpointsByProtocol.HTTPS,
        correctClockSkew: true
    });

    const getICEServerConfigResponse = await kinesisVideoSinnalingChannelsClient.getIceServerConfig({
        ChannelARN: channelARN
    }).promise();

    const iceServers = [];
    if (!this.kinesis.options.natTransversalDisabled && !this.kinesis.options.forceTURN) {
        iceServers.push({ urls: `stun:stun.kinesisvideo.${this.kinesis.options.region}.amazonaws.com:443`})
    }
    if (!this.kinesis.options.natTransversalDisabled) {
        getICEServerConfigResponse.IceServerList.forEach(iceServer =>
            iceServers.push({
                urls: iceServer.Uris,
                username: iceServer.Username,
                credential: iceServer.Password,
            }),
        );
    }

    const configuration = {
        iceServers,
        iceTransportPolicy: this.kinesis.options.forceTURN ? 'relay' : 'all'
    };

    this.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {

        const peerConnection = new RTCPeerConnection(configuration);
        this.peerConnectionByClientId[remoteClientId] = peerConnection;

        if (openDataChannel) {
            this.dataChannelByClientId[remoteClientId] = peerConnection.createDataChannel('kvsDataChannel');
            peerConnection.ondatachannel = event => {
                event.channel.onmessage = message => {
                    console.log('[PRODUCER] Received message: ' + message.data);
                }
            }
        }

        if (!this.peerConnectionStatsInterval) {
            this.peerConnectionStatsInterval = setInterval(() => {
                peerConnection.getStats().then(onStatsReport)
            }, 1000)
        }

        peerConnection.addEventListener('icecandidate', ({
            candidate
        }) => {
            if (candidate) {
                if (this.kinesis.options.useTrickleICE) {
                    this.signalingClient.sendIceCandidate(candidate, remoteClientId);
                }
            } else {
                if (!this.kinesis.options.useTrickleICE) {
                    this.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
                }
            }
        });

        if (this.localStream) {
            this.localStream.getTracks().forEach(
                track => {peerConnection.addTrack(track, this.localStream);}
            );

        }
        await peerConnection.setRemoteDescription(offer);

        await peerConnection.setLocalDescription(
            await peerConnection.createAnswer({
                offerToReceiveAudio: this.kinesis.options.sendAudio,
                offerToReceiveVideo: this.kinesis.options.sendVideo
            })
        );

        if (this.kinesis.options.useTrickleICE) {
            this.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
        }
    });

    this.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {
        const peerConnection = this.peerConnectionByClientId[remoteClientId];
        if (peerConnection) {
            await peerConnection.addIceCandidate(candidate);
        }
    });

    this.signalingClient.open();
};

export {
    Kinesis,
    StreamProducer
};
import * as rxjs from 'rxjs';

function createStateHandlers() {
  const subject = new rxjs.BehaviorSubject(null);

  return {
    set: (value) => subject.next(value),
    clear: () => subject.next(null),
    get: () => subject.asObservable(),
    getCurrentValue: () => subject.getValue()
  };
}

export const onlineTestQuestions$ = createStateHandlers();
export const screenStreamSubject$ = createStateHandlers();
export const screenSharingStatus$ = createStateHandlers();
<template>
    <div class="loading-container">
        <i class="loading-icon" :class="['fa', currentIcon]"></i>
        <p class="loading-message">{{ currentMessage }}</p>
        <div class="progress-bar">
            <div class="progress" :style="{ width: progress + '%' }"></div>
        </div>
        <p class="percentage">{{ progress }}%</p>
    </div>
    <modal-dialog :hide-close="true" v-show="showErrorsModal">
        <h3 id="errorMessage" class="p-y-xl"> {{ error.description }} </h3>
        <button id="error-btn" class="btn btn-primary m-b-xl" @click="error.buttonFunction()">
            {{ error.buttonText }}
        </button>
    </modal-dialog>
    <modal-dialog v-show="showAccessErrorModal" @close="showAccessErrorModal = false">
            <h3 class="m-b-md text-left">Ocorreu um erro ao acessar a prova.</h3>
            <p v-show="!showExpiredSessionMessages">
                <router-link class="btn btn-primary d-block" :to="`/`">
                    Voltar para a página inicial
                </router-link>
            </p>
            <p v-show="showExpiredSessionMessages">
                Não é possível prosseguir com a prova no momento,
                verifique as configurações de seu navegador e certifique-se de que
                o mesmo está com as configurações de cookies habilitadas.
            </p>
        </modal-dialog>
</template>

<script>
    import * as rxjs from "rxjs";
    import moment from "moment-timezone";

    import { catchError, concatMap, delay, mapTo, tap, toArray, skip } from "rxjs/operators";

    import ModalDialog from "../../base/Modal";

    import { firestoreDB, fireBaseCheckMultipleInstances, loadDataFromFirebase } from "../../../scripts/firebase";
    import { onlineTestQuestions$, screenSharingStatus$ } from "../../../scripts/observableStates";
    import { initScreenStream, stopScreenStream } from "../../../scripts/screenSharingStream";
    import { getCookie } from "../../../scripts/utils";
    import { getBaseSgpUrlNoSlug } from "../../../scripts/apiService/ApiService";
    import { getTestUrl, postTestUrl } from "../../../scripts/apiService/tests";

    let _ = require("lodash");


    export default {
        name: "LoadingTestView",
        components: { ModalDialog },
        data() {
            return {
                progress: 0,
                loadingSteps: [
                    { message: "Preparando tudo pra você...", icon: "fa-coffee" },
                    { message: "Carregando as melhores bibliotecas...", icon: "fa-book" },
                    { message: "Configurando o ambiente...", icon: "fa-desktop" },
                    { message: "Pré-carregando informações...", icon: "fa-database" },
                    { message: "Quase pronto!", icon: "fa-check" }
                ],
                currentStepIndex: 0,
                configs: null,
                assessment: null,
                failedTasks: [],
                error: {},
                showErrorsModal: false,
                showAccessErrorModal: false,
                showExpiredSessionMessages: false,
                pipelineSubscription: null
            };
        },
        computed: {
            currentMessage() {
                return this.loadingSteps[this.currentStepIndex].message;
            },
            currentIcon() {
                return this.loadingSteps[this.currentStepIndex].icon;
            }
        },
        methods: {
            async checkMultipleInstances() {
                if (this.configs?.variables?.check_multiple_online_test_instances) {
                    let clientCollection = `${this.configs.variables.client_slug}-${this.configs.variables.client_id}.dashboard_prova_online`;
                    const today = moment().tz("America/Sao_Paulo").format("YYYY-MM-DD");
                    const firestoreObj = firestoreDB
                        .collection(clientCollection)
                        .doc(today)
                        .collection("exams")
                        .doc(this.$route.params.key);
                    const isOnline = await fireBaseCheckMultipleInstances(firestoreObj, clientCollection, this.$route.params.key);
                    if (isOnline && this.assessment.configs.started) return { status: "error", source: "checkMultipleInstances" }
                }
                return { status: "success" };
            },
            loadFroala() {
                const FroalaEditorImport$ = rxjs.from(import("froala-editor/js/froala_editor.pkgd.min.js"));

                return FroalaEditorImport$.pipe(
                    tap( froalaObj => {
                        window.FroalaEditor = froalaObj.default;
                    }),
                    mapTo({ status: "success" }),
                    catchError(() => {
                        return rxjs.of({ status: "error", source: "loadFroala" });
                    })
                );
            },
            async manageLoadQuestions(){
                try{
                    const [items, answers, totalQuestions] = await this.loadQuestions();
                    const onlineTestQuestionItems = {
                        "items": items,
                        "answers": answers,
                        "totalQuestions": totalQuestions
                    };
                    onlineTestQuestions$.set(onlineTestQuestionItems);

                    return { status: "success" };
                } catch (e) {
                    return { status: "error", source: "manageLoadQuestions" };
                }
            },
            async loadQuestions() {
                let clientCollection = `${this.configs.variables.client_slug}-${this.configs.variables.client_id}`;
                let firestoreObj = firestoreDB
                    .collection("provaonline")
                    .doc(clientCollection)
                    .collection("assessment")
                    .doc(this.$route.params.key);

                const {assessmentData, previousAnswers} = await loadDataFromFirebase(firestoreObj);

                let answers = previousAnswers ? previousAnswers : {};
                let items = _.orderBy(assessmentData.assessment.items, ["min_number"], ["asc"]);
                let totalQuestions = 0;

                return this.loadAnswers(items, answers, totalQuestions);
            },
            loadAnswers(items, answers, totalQuestions) {
                for (let item of items) {
                    item.questions.forEach((question) => {
                        totalQuestions++;
                        if (answers[question.pk]) {
                            if (question.type === "o") {
                                question.markedAlternative = question.alternatives.find((alt) => alt.pk === answers[question.pk])
                            } else {
                                question.answer_text = answers[question.pk]
                            }
                        }
                    });
                }
                return [items, answers, totalQuestions]
            },
            async verifyScreenSharing(){
                if (!this.configs?.variables?.native_proctoring){
                    return { status: "success" }
                }
                let sharingStatus = screenSharingStatus$.getCurrentValue();
                if(sharingStatus?.state === "streaming") return { status: "success" };
                else return { status: "error", source: "verifyScreenSharing" };
            },
            retryFailedTasks(){
                this.showErrorsModal = false;

                const tasks$ = this.getTaskMap();
                const retryTasks = this.failedTasks.map(task => tasks$[task.source]);
                this.failedTasks = [];

                if(this.pipelineSubscription) this.pipelineSubscription.unsubscribe();
                this.pipelineSubscription = this.tasksPipeline(retryTasks).subscribe();
            },
            handlingErrorsModal(){

                if(this.failedTasks.length < 1) return;

                this.error = {};
                let component = this;

                if(this.failedTasks.find(object => object.source === "checkMultipleInstances")) this.error = {
                    description: "Não é possível acessar a prova, já está em andamento em outro dispositivo",
                    buttonFunction: function() {
                        component.redirectToStart();
                    },
                    buttonText: "Voltar para a pagina inicial",
                    retry: false
                };
                else if(this.failedTasks.find(object => object.source === "verifyScreenSharing")) this.error = {
                    description: "Habilite o compartilhamento de tela cheia do monitor e tente novamente",
                    buttonFunction: function() {
                        initScreenStream();
                        component.screenStatusSubscription = screenSharingStatus$.get().pipe(skip(1)).subscribe( obj => {
                                if(obj){
                                    component.retryFailedTasks();
                                    if(component.screenStatusSubscription) component.screenStatusSubscription.unsubscribe();
                                }
                            },
                        );
                    },
                    buttonText: "Tentar Novamente",
                    retry: true

                };
                else this.error = {
                    description: "Não é possível prosseguir com o carregamento da prova. Tente novamente em instantes ou entre em contato com sua instituição.",
                    buttonFunction: function() {
                        component.redirectToStart();
                    },
                    buttonText: "Voltar para a pagina inicial",
                    retry: false
                };


                if(!this.error?.retry){
                    if (this.configs?.variables?.native_proctoring) stopScreenStream();
                    if(component.screenStatusSubscription) component.screenStatusSubscription.unsubscribe();
                }


                this.showErrorsModal = true;
            },
            redirectToStart() {
                let redirectUrl = this.configs.urls.redirect_url;
                if (redirectUrl.startsWith("/")) {
                    let baseUrl = getBaseSgpUrlNoSlug();
                    redirectUrl = baseUrl + redirectUrl;
                }
                window.location.href = redirectUrl;
            },
            setCandidateCurrentSession() {
                let url = this.configs.urls.set_candidate_current_session;
                url = url.replace("/" + this.configs.variables.client_slug + "/", "");
                return postTestUrl(this.$route.params.key, url, null);
            },
            getStartExam() {
                let url = this.configs.urls.exam_start;
                url = url.replace("/" + this.configs.variables.client_slug + "/", "");

                return getTestUrl(this.$route.params.key, url, null);
            },
            startOnlineTest() {
                if (this.assessment.configs.finished) {
                    return;
                }

                window.dataLayer = window.dataLayer || [];

                function gtag(){
                    window.dataLayer.push(arguments);
                }

                gtag('event', 'startExam', {
                    "event_category": "buttonAction",
                    "event_label": "Start Online Exam"
                });

                let startPromise = () => {return this.setCandidateCurrentSession().then(this.getStartExam)};

                if (getCookie("candidateToken")) {
                    startPromise = () => {return this.getStartExam()};
                }

                startPromise().then(async () => {
                    this.$store.commit("onlineTest/setStarted");
                    this.$router.replace(`/online-tests/${this.$route.params.key}/test`);
                })
                .catch((error) => {
                    let error_data;
                    if (error.original) {
                        error_data = error.original.data;
                    }
                    if(error?.response?.data?.inactive_schedule){
                        error_data = error.response.data;
                    }
                    if (error_data && error_data.content) {
                        let message = error_data.content;
                        console.log(message);
                    }
                    this.showAccessErrorModal = true;
                    if((error === 'OnlineTestFirebaseNotFound') || (!error_data && error)){
                        this.showExpiredSessionMessages = true;
                    }
                })
            },
            upgradeProgress(){
                this.progress += 25;
                this.currentStepIndex++;
            },
            tasksPipeline(taskList){
                return rxjs.concat(taskList).pipe(
                    concatMap( task$ => task$.pipe(
                        delay(1000),
                        tap(result => {
                            if(result?.status === "error") {
                                const error = new Error(result.status);
                                error.source = result.source;
                                throw error;
                            }
                            else if(result?.status === "success"){
                                this.upgradeProgress()
                            }
                        }),
                        catchError(error => {
                            this.failedTasks.push({state: error.state, source: error.source});
                            return rxjs.of(error);
                        })
                    )),
                    toArray(),
                    tap(results => {
                        if(results.every( result => result.status === "success")) this.startOnlineTest();
                        else this.handlingErrorsModal()
                    })
                );
            },
            getTaskMap(){
                return  {
                    loadFroala: rxjs.from(this.loadFroala()),
                    checkMultipleInstances: rxjs.from(this.checkMultipleInstances()),
                    manageLoadQuestions: rxjs.from(this.manageLoadQuestions()),
                    verifyScreenSharing: rxjs.from(this.verifyScreenSharing())
                };
            }
        },
        mounted(){

            this.assessment = this.$store?.state?.onlineTest?.assessment;
            this.configs = this.$store?.state?.onlineTest?.configs;

            if (!this.assessment || !this.configs) {
                this.$router.replace(`/online-tests/${this.$route.params.key}/instructions`);
                return;
            }

            const tasks$ = Object.values(this.getTaskMap());

            this.pipelineSubscription = this.tasksPipeline(tasks$).subscribe();
        },
        beforeUnmount() {
            if (this.pipelineSubscription) this.pipelineSubscription.unsubscribe();
            if (this.screenStatusSubscription) this.screenStatusSubscription.unsubscribe();
        }
    }
</script>

<template>
    <modal-dialog v-if="question">
        <div class="col-xs-12">
            Selecione um arquivo para enviar sua resposta
            <br/>
            <div class="col-xs-12 m-t-lg" v-if="currentFile">
                <span>{{ currentFile.name }}</span>
            </div>
            <div class="col-xs-12 m-t-lg" v-if="initialName">
                <span>{{ initialName }}</span>
            </div>
            <div class="col-xs-12 m-t-lg m-b-xl">
                <label class="btn btn-primary" v-if="!uploading">
                    <input type="file" id="file-input" ref="fileInput" @change="changeFile"
                           accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.gif,.ppt,.pptx"
                           v-bind="uploading ? {disabled: 'disabled'} : {}"/>
                    {{ label }}
                </label>
                <button class="btn btn-primary m-l-lg" v-if="currentFile" @click="sendFile"
                   v-bind="uploading ? {disabled: 'disabled'} : {}">
                    <i class="fa" :class="uploading ? 'fa-circle-o-notch fa-spin' : 'fa-upload'"></i>
                    {{ uploading ? 'Enviando' : 'Enviar' }} arquivo
                </button>
            </div>
        </div>
    </modal-dialog>
</template>

<script>

    import ModalDialog from "../base/Modal";
    import {postTestMedia} from "../../scripts/apiService/tests";
    import {getBaseSgpUrl} from "../../scripts/apiService/ApiService";
    import {getFileNameFromPath} from "../../scripts/utils";

    export default {
        components: {ModalDialog},
        props: ["initial", "callback", "testId", "question"],
        data() {
            return {
                label: "Selecionar arquivo",
                currentFile: null,
                currentFileUrl: null,
                uploading: false,
                initialName: null,
            }
        },
        methods: {
            changeFile() {
                this.currentFile = this.$refs.fileInput.files[0];
                this.label = "Substituir arquivo";
            },
            sendFile() {
                this.uploading = true;

                postTestMedia(this.testId, this.currentFile, "", {
                    file_name: this.currentFile.name
                }).then((response) => {
                    if (this.callback) {
                        let fileUrl = getBaseSgpUrl() + `privatefile?file_path=${response.data.file_key}`;
                        this.callback(this.currentFile, fileUrl);
                    }
                    this.$emit('close');
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    this.uploading = false;
                });
            }
        },
        mounted() {
            if (this.initial) {
                this.initialName = getFileNameFromPath(this.initial);
            }
        }
    };
</script>

<style scoped>
    input[type="file"] {
        display: none;
    }

    .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
    }
</style>

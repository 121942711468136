<template>
    <modal-dialog mask-class="bg-blank" :hide-close="true">
        <h3 class="modal-title text-center" id="modal-title">
            Reconhecimento de Face
        </h3>
        <span v-if="isLoading" class="m-y-xl d-block"><i class="fa fa-circle-o-notch fa-spin"></i> Carregando...</span>
        <div class="area" v-show="!facialRecognitionFail">
            <div id="recognition-modal-container">
                <p class="recognition-info" v-if="!isLoading">
                    Posicione-se em um ambiente com boa luminosidade e sem interferência de luz direta que possa ofuscar
                    ou reduzir a eficácia da sua identificação.
                    É proibido o uso de itens que prejudiquem a identificação, como boné com a aba voltada para a frente
                    e óculos escuros.
                </p>
            </div>
            <div class="col-xs-12 p-0 text-center">
                <video class="face-recognition-video" ref="webCamera" autoplay muted playsinline style="width: auto; height: 350px;" v-show="!isLoading" title="Vídeo da sua câmera"></video>
            </div>
        </div>
        <div class="area" v-if="facialRecognitionFail">
            <p class="recognition-info">
                <strong>
                    O reconhecimento da sua face falhou. Se atente à posição em frente à camera, à luminosidade do local
                    onde você está e a itens que podem prejudicar a acurácia.
                </strong>
            </p>
        </div>
        <div class="modal-footer" v-if="facialRecognitionFail">
            <button class="btn btn-default" @click="retryFacialRecognition">Tentar novamente</button>
        </div>
    </modal-dialog>
</template>

<script>
    import axios from 'axios';
    import {getCookie} from "../../../scripts/utils";
    import ModalDialog from "../../base/Modal";
    import {getBaseSgpUrlNoSlug} from "../../../scripts/apiService/ApiService";

    export default {
        name: "faceRecognitionModal",
        components: {ModalDialog},
        props: ['onRecognitionSuccess', 'isFirstRecognition'],
        data() {
            let variables = this.$store.state.onlineTest.configs.variables;
            let urls = this.$store.state.onlineTest.configs.urls;
            return {
                isLoading: true,
                img: null,
                numberOfRecognitionTries: 0,
                itemGeneratedId: null,
                facialRecognitionFail: false,
                candidatePhoto: variables.candidate_photo,
                enableTakePhoto: variables.enable_take_photo,
                realTimePhotoValidation: variables.candidate_photo,
                uploadUrl: urls.facial_recognition_upload,
                urlSavePhoto: urls.facial_recognition_save_photo,
                bucketName: variables.bucket_name_s3,
                urlFacialRecognition: urls.facial_recognition,
                token: variables.token_facial_recognition_upload,
                nativeProctoring: variables.native_proctoring,
                cameraStream: null,
            }
        },
        methods: {
            loadCamera() {
                let video = this.$refs.webCamera;
                let component = this;
                if (video) {
                    if (navigator.mediaDevices.getUserMedia) {
                        navigator.mediaDevices.getUserMedia({audio: false, video: {facingMode: 'user'}})
                            .then((stream) => {
                                video.srcObject = stream;
                                component.isLoading = false;
                                this.cameraStream = stream;
                                setTimeout(component.startRecognition, 1000)
                            })
                            .catch(function () {
                                alert("Habilite sua câmera e recarregue a página.");
                                location.reload();
                            });
                    }
                }
            },
            startRecognition() {
                this.takePhoto();

                if (this.enableTakePhoto) {
                    this.uploadPhoto();
                } else {
                    this.tryFacialRecognition();
                }
            },
            uploadPhoto() {
                let data = {};
                let baseUrl = getBaseSgpUrlNoSlug();
                let urlSavePhoto = baseUrl + this.urlSavePhoto;

                if (this.realTimePhotoValidation) {
                    data = {
                        is_first_recognition: this.isFirstRecognition,
                        is_skipped: false,
                        image_base64: this.img,
                        unlockable_facial_recognition: true,
                        item_generated_id: this.itemGeneratedId,
                        bucket_name: this.bucketName,
                        url_save_photo: urlSavePhoto,
                        candidate_photo: this.candidatePhoto,
                    };
                } else {
                    data = {
                        image_base64: this.img,
                        item_generated_id: this.itemGeneratedId,
                        bucket_name: this.bucketName,
                        url_save_photo: urlSavePhoto
                    };
                }

                const headers = {"Content-Type": "application/json", "Authorization": 'Bearer ' + this.token};

                axios.post(this.uploadUrl, data, {headers: headers, withCredentials: false});

                this.cameraStream.getTracks().forEach(function(track) {
                    track.stop();
                });

                this.onRecognitionSuccess();
            },
            retryFacialRecognition() {
                this.facialRecognitionFail = false;

                setTimeout(this.loadCamera, 1000)
            },
            tryFacialRecognition() {
                let currentCandidate = getCookie('currentCandidate');
                let baseUrl = getBaseSgpUrlNoSlug();
                let url = baseUrl + this.urlFacialRecognition;
                let data = {
                    candidate_key: currentCandidate,
                    data_url_image: this.img,
                    item_generated_id: this.itemGeneratedId,
                    is_first_recognition: this.isFirstRecognition,
                    is_skipped: false
                };

                let csrfToken = getCookie('csrftoken');
                const headers = {"X-CSRFTOKEN": csrfToken, "Authorization": 'Bearer ' + this.token};

                let component = this;

                axios.post(url, data, {headers: headers, withCredentials: true}).then((response) => {
                    let recognitionSuccess =
                        response.data.candidate_has_been_recognized &&
                        response.data.number_of_faces === 1;
                    if (recognitionSuccess) {
                        this.cameraStream.getTracks().forEach(function(track) {
                            track.stop();
                        });
                        component.onRecognitionSuccess();
                    } else {
                        component.onRecognitionError();
                    }
                }).catch(function () {
                    component.facialRecognitionFail = true;
                    component.isLoading = false;
                });
            },
            takePhoto() {
                let video = this.$refs.webCamera;

                let canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                this.img = canvas.toDataURL("image/jpeg");
            },
            onRecognitionError() {
                this.numberOfRecognitionTries++;
                if (this.numberOfRecognitionTries >= 3) {
                    this.numberOfRecognitionTries = 0;
                    this.cameraStream.getTracks().forEach(function(track) {
                        track.stop();
                    });
                    this.facialRecognitionFail = true;
                    this.isLoading = false;
                } else {
                    setTimeout(this.tryFacialRecognition, 1000);
                }
            }
        },
        beforeUnmount() {
            if (this.cameraStream){
                this.cameraStream.getTracks().forEach(function(track) {
                    track.stop();
                });
            }
        },
        mounted() {
            this.loadCamera();
            this.itemGeneratedId = this.$route.params.key
        }
    }
</script>

<template>
    <VueAnnouncer/>
    <fullscreen v-model="$store.state.main.fullScreen"></fullscreen>
    <loading-screen v-if="$store.state.main.loading" role="none"
                    :class="'theme-' + $store.state.main.theme + ' custom-' + $store.state.main.custom"
    ></loading-screen>
    <div v-else id="page-wrapper"
         :class="'theme-' + $store.state.main.theme + ' custom-' + $store.state.main.custom + ($store.state.main.custom === 'smesp' ? ' ' + getClass : '')">
        <ScreenBase/>
    </div>
</template>

<script>
    import ScreenBase from './components/base/ScreenBase.vue'
    import LoadingScreen from "./components/base/Loading";
    import {getCandidateInfo} from "./scripts/apiService/accounts";
    import {getCookie, getQueryParam, setCookie} from "./scripts/utils";

    export default {
        name: 'App',
        components: {
            LoadingScreen,
            ScreenBase
        },
        watch: {
            $route: {
                immediate: true,
                handler(to) {
                    document.title = to.meta.title || 'SGA Cidade SP - Área do Aluno';
                }
            },
        },
        computed:{
            getClass() {
                    return this.getClassBySegment() ? this.getClassBySegment() : ''
                 }
            },
        methods: {
            checkCookies() {
                if (!getCookie("checkCookie")){
                    alert("Este site precisa de cookies para funcionar corretamente. Por favor, habilite os cookies e recarregue a página.")
                    location.reload();
                }
            },
            loadChatScript(scriptStr) {
                let scriptTags = scriptStr.split("/script>");
                let scriptEl, newElem;
                let elemList = [];
                scriptTags.forEach((scriptTag) => {
                    if (scriptTag) {
                        scriptEl = document.createElement("div");
                        scriptEl.innerHTML = (scriptTag + "/script>").trim();
                        newElem = document.createElement("script");
                        if (scriptEl.firstChild.src) {
                            newElem.async = true;
                            newElem.src = scriptEl.firstChild.src;
                        } else {
                            newElem.type = "application/javascript";
                            newElem.innerHTML = scriptEl.firstChild.innerHTML;
                        }
                        elemList.push(newElem);
                    }
                });
                document.head.appendChild(elemList[0]);
                let index = 0;
                while (elemList[index + 1]) {
                    let position = index + 1;
                    if (elemList[index].src) {
                        elemList[index].addEventListener("load", () => {
                            document.head.appendChild(elemList[position]);
                        });
                    } else {
                        document.head.appendChild(elemList[position]);
                    }
                    index++;
                }
            },
            getClassBySegment() {
                const classMap = {
                    'ensino médio': 'ensino-medio',
                    'ensino fundamental': 'ensino-fundamental',
                    'jovem e adulto': 'jovem-adulto',
                };

                let response = null;

                if(this.$store.state.main.candidateInfo.metaData){
                    response = classMap[this.$store.state.main.candidateInfo.metaData.toLowerCase()];
                }
                return response;
            },
        },

        mounted() {
            if (this.$store.state.main.smespCustomization) {
                if (process.env.NODE_ENV == 'development') {
                    new window.TT({
                        platform: 'avaliacao',
                        email: '{{ user.email }}',
                        env: 'hmlg'
                    });
                } else {
                    new window.TT({
                        platform: 'avaliacao',
                        email: '{{ user.email }}',
                        env: 'prod'
                    });
                }
            }
            if (!this.$store.state.main.candidateInfo.name) {
                let testId = getQueryParam("test_id");
                if(getQueryParam("origin")) {
                    setCookie("sgpBaseUrl", getQueryParam("origin"));
                }
                getCandidateInfo().then((response) => {
                    this.$router.replace({'query': null});
                    setCookie("currentCandidate", response.data["pk"]);

                    if(response.data["use_rybena"]) {
                        let rybenaShowStyle = document.createElement("style");
                        rybenaShowStyle.innerHTML = '#rybena-sidebar{display: flex!important}';
                        document.head.appendChild(rybenaShowStyle);
                    }

                    let availableMenus = response.data["menus"];
                    availableMenus.logout = "logout";
                    this.$store.commit("main/setCandidateInfo", {
                        firstName: response.data["candidate_name"].split(" ")[0],
                        name: response.data["candidate_name"],
                        email: response.data["candidate_email"],
                        availableMenus: response.data["menus"],
                        photo: response.data["candidate_photo"],
                        futureTests: response.data["future_test_amount"],
                        clientLogo: response.data["client_logo"],
                        legacyKey: response.data["legacy_key"],
                        mockExam: response.data["candidate_mock_exam"],
                        metaData: response.data["meta_data"],
                        logoutUrl: response.data["logout_url"],
                        client: response.data["client_id"],
                        documentId: response.data["document_id"],
                    });

                    this.$store.commit("main/setSmespCustomization", response.data["smesp_customization"]);
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setCustom", response.data["client_slug"]);

                    setCookie("firestore_token", response.data["firestore_token"]);

                    if (response.data["chat_script"]) {
                        this.loadChatScript(response.data["chat_script"]);
                    }

                    if (response.data["smesp_customization"]) {
                        let script = require('./assets/js/smesp_chat.js').default;
                        let candidateFirstName = response.data["candidate_name"].split(" ")[0];
                        script = script.replace("{{ pk }}", response.data["pk"]);
                        script = script.replace("{{ first_name }}", candidateFirstName);
                        script = script.replace("{{ last_name }}", response.data["candidate_name"].replace(candidateFirstName, "").trim());
                        script = script.replace("{{ email }}", response.data["candidate_email"]);
                        this.loadChatScript(script);
                    }

                    if (testId) {
                        this.$router.push(`/online-tests/${testId}/instructions`);
                    }
                });
            } else {
                this.$store.commit("main/setLoading", false);
            }
            setCookie("checkCookie", "123");
            setInterval(this.checkCookies, 1000);
        }
    }
</script>

<style type="text/css" src="./assets/css/base.scss" lang="scss"></style>
<style type="text/css" src="./assets/css/layout.scss" lang="scss"></style>
<style type="text/css" src="./assets/css/module.scss" lang="scss"></style>
<style type="text/css" src="./assets/css/state.scss" lang="scss"></style>
<style type="text/css" src="./assets/css/theme.scss" lang="scss"></style>

<style type="text/css" src="./assets/custom/custom-smesp.scss" lang="scss"></style>

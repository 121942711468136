<template>
    <div v-if="result" aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-history"></i> Provas realizadas</p>
            </div>
        </div>

        <div class="col-xs-12 m-t-md">
            <div class="card p-20 text-left d-flex-column test-info-card">
                <h3>Informações da avaliação</h3>
                <div class="col-xs-12 p-0 m-t-md">
                    <div class="col-xs-12 col-lg-4 p-0 m-t-md">
                        <div><i class="fa fa-user fa-lg"></i> {{ result.candidate.name }} - {{ result.candidate.enrollment_code }}</div>
                        <div class="m-t-md"><i class="fa fa-group fa-lg"></i> {{ result.academic }}</div>
                    </div>
                    <div class="col-xs-12 col-lg-8 p-0 m-t-md">
                        <div>
                            <i class="fa fa-calendar fa-lg"></i>
                            {{ result.schedule.type_assessment.name }} -
                            {{ formatDbDate(result.schedule.date_schedule) }} {{ result.schedule.time_schedule }}
                            ({{ result.status }})
                        </div>
                        <div class="m-t-md"><i class="fa fa-map-marker fa-lg"></i> {{ result.place }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xs-12 m-t-xl" v-if="result.attendance && result.show_tbl_score && result.configs.show_score">
            Pontuação: {{ result.score.toFixed(2) }} / {{ result.value.toFixed(2) }}
        </div>

        <div class="col-xs-12 m-t-xl">
            <div class="card p-20 text-left question-card">
                <div class="col-xs-12 m-t-md" v-for="(question, index) in result.questions" :key="question">
                    <div v-if="!result.is_absent">
                        <div v-if="!result.configs.hide_question_body">
                            <div v-if="question.item_support">
                                <div class="text-bold m-t-lg">Texto de apoio</div>
                                <span class="m-t-sm" v-html="question.item_support"></span>
                            </div>
                            <span class="m-t-sm">
                                <strong>Questão {{ index + 1 }}</strong>
                                <div v-html="question.body"></div></span>
                        </div>
                    </div>
                    <div class="separator-line" v-if="index < result.questions.length - 1"> </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
        <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
    </div>
</template>

<script>
    import {getFinishedTestData} from "../../scripts/apiService/tests";
    import {formatDbDate} from "../../scripts/utils";

    export default {
        name: 'TestDataViewQuestions',
        data: () => { return {
            result: null,
        }},
        methods: {
            formatDbDate: formatDbDate,
        },
        mounted() {
            getFinishedTestData(this.$route.params.key).then((response) => {
                this.result = response.data;
                this.$nextTick(() => {
                    if(this.result)
                        document.getElementById('title').focus();
                    else
                        this.$announcer.set('Não existem provas realizadas disponíveis', 'assertive');
                })
            });
        },
    }
</script>

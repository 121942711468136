<template>
  <top-menu></top-menu>
  <side-menu ref="sideMenuElement" :class="$store.state.main.menuActive ? 'menu-active' : ''"></side-menu>
  <div id="screen-shading"
       :class="$store.state.main.menuActive ? 'menu-active' : ''"
       @click="$store.commit('main/toggleMenu')"></div>
  <div id="wrapper">
    <router-view></router-view>
  </div>
  <theme-switch aria-hidden="true"></theme-switch>
</template>

<script>
import SideMenu from "./SideMenu";
import ThemeSwitch from "./ThemeSwitch";
import TopMenu from "./TopMenu";
import {isMobile} from "../../scripts/utils";
export default {
  name: 'ScreenBase',
  data() {
    return {
      shortcutMenu: false
    }
  },
  components: {TopMenu, ThemeSwitch, SideMenu},
  isMobile: isMobile,
  methods: {
    handleShortcut(event) {
      if (event.key === '0'){
        this.$announcer.set('Movendo para o menu. Aperte tab para passar pelas opções', 'assertive');
        this.$refs.sideMenuElement.$el.focus();
      }
    }
  },
  watch: {
      $route: {
          immediate: true,
          handler(to) {
            if(to.meta.title === "Prova Online"){
              this.shortcutMenu = false;
              window.removeEventListener('keydown', this.handleShortcut);
            }
            else if(!this.shortcutMenu){
              this.shortcutMenu = true;
              window.addEventListener('keydown', this.handleShortcut);
            }
          }
      },
  },
  mounted() {
    this.shortcutMenu = true;
    window.addEventListener('keydown', this.handleShortcut);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleShortcut);
  }
}
</script>

<style>
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
</style>

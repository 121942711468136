<template>
    <VueAnnouncer/>
    <div class="online-test-wrapper" @copy="checkPrevent" @paste="checkPrevent">
        <div class="col-xs-1"></div>
        <div class="col-xs-10 text-left m-t-xl p-b-xl">
            <div class="col-xs-12 p-0">
                <h3 id="titleTestView" role="heading" tabindex="0"><i class="fa fa-file-text"></i> Prova online</h3>
            </div>
            <div class="col-xs-12 text-center m-t-150" v-if="items.length === 0">
                <i class="fa fa-circle-o-notch fa-spin fa-5x"></i>
            </div>
            <div class="col-xs-12 p-0" v-else-if="!(modalFinishedTest)" :style="modalFullScreen ? 'visibility: hidden' : ''">
                <div class="col-xs-12 p-0 text-left m-t-xl mobile-show">
                    <div class="card p-10 m-t-xl collapsible-container">
                        <h3 aria-live="polite">
                            Dados da avaliação
                            <span class="fa fa-lg expand-arrow" @click="expandedCards[0] = !expandedCards[0]"
                                  :class="expandedCards[0] ? 'fa-caret-up' : 'fa-caret-down'"></span>
                        </h3>
                        <div class="col-xs-12 p-0 m-t-xl collapsible" :class="!expandedCards[0] ? 'collapsed' : ''">
                            <div class="col-xs-8 p-0" aria-live="polite"><i class="fa fa-user"></i> {{
                                assessment.configs.exam.candidate.name }}
                            </div>
                            <div class="col-xs-4 p-0" aria-live="polite"><i class="fa fa-id-card"></i> {{
                                assessment.configs.exam.candidate.enrollment }}
                            </div>
                        </div>
                        <div class="col-xs-12 p-0 text-center m-t-lg collapsible" :class="!expandedCards[0] ? 'collapsed' : ''">
                            <button class="btn btn-primary m-r-md m-l-md" @click="modalInstructions = true" aria-live="polite" tabindex="0"
                                    v-if="assessment.configs.exam.instructions.length > 0">
                                <i class="fa fa-info-circle m-r-4"></i> Instruções
                            </button>
                            <button class="btn btn-primary m-r-md m-l-md" @click="modalAttachment = true" aria-live="polite" tabindex="0"
                                    v-if="assessment.configs.exam.has_attachment">
                                <i class="fa fa-paperclip m-r-4"></i> Anexo
                            </button>
                        </div>
                        <div class="col-xs-12 p-0 m-t-xl text-center collapsible"
                             :class="!expandedCards[0] ? 'collapsed' : ''">
                            <h1>
                                <span v-if="showTimer" class="timer" aria-live="polite">{{ secondsToDate(timer) }}</span>
                                <span v-else class="timer timer-hide">&nbsp;</span>
                                <i class="fa fa-eye cursor-pointer" @click="showTimer = !showTimer"
                                   title="Alternar cronômetro"></i>
                            </h1>
                        </div>
                        <div class="col-xs-12 p-0 m-t-xl">
                            <button v-bind="isOffline ? {disabled: 'disabled'} : {}" aria-live="polite" tabindex="0"
                                    class="col-xs-12 btn btn-primary" @click="finishTestBtn()">
                                Entregar prova
                            </button>
                        </div>
                    </div>
                    <div class="card p-10 m-t-xl collapsible-container">
                        <h3 aria-live="polite">
                            Progresso
                            <span class="fa fa-lg expand-arrow" @click="expandedCards[1] = !expandedCards[1]"
                                  :class="expandedCards[1] ? 'fa-caret-up' : 'fa-caret-down'"></span>
                        </h3>
                        <div class="col-xs-12 p-0 m-t-xl collapsible" :class="!expandedCards[1] ? 'collapsed' : ''">
                            <div class="progress-bar">
                                <div class="bg-primary" aria-live="polite"
                                     :style="'width: ' + 100*Object.keys(answers).filter((questionId) => answers[questionId]).length/totalQuestions + '%'">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 p-0 text-left m-t-xs collapsible"
                             :class="!expandedCards[1] ? 'collapsed' : ''"
                             v-if="assessment.configs.exam.instructions.length > 0">
                            <small aria-live="polite">
                                {{ Object.keys(answers).filter((questionId) => answers[questionId]).length.toString().padStart(2, '0') }}/{{
                                totalQuestions.toString().padStart(2, '0') }}
                                questões respondidas
                            </small>
                        </div>
                        <div class="col-xs-12 p-0 m-t-xl d-flex-row flex-align-center flex-justify-start flex-line-break collapsible"
                             :class="!expandedCards[1] ? 'collapsed' : ''">
                            <div v-for="question in getQuestionsArray()" :key="question" class="text-center"
                                 style="flex-basis: 35px">
                                <div class="question-circle d-flex-row flex-align-center flex-justify-evenly cursor-pointer m-auto m-t-sm"
                                     :class="answers[question.pk] ? 'bg-primary' : 'border-primary'"
                                     @click="scrollToQuestion(question)" aria-live="polite" tabindex="0">
                                    {{ question.number }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 p-0 m-t-xl collapsible" :class="!expandedCards[1] ? 'collapsed' : ''" aria-hidden="true">
                            <p class="m-0">Legenda</p>
                        </div>
                        <div class="col-xs-12 p-0 m-t-sm d-flex-row flex-justify-between flex-align-center collapsible"
                             :class="!expandedCards[1] ? 'collapsed' : ''" aria-hidden="true">
                            <span class="d-flex-row flex-justify-between flex-align-center">
                                <span class="question-circle bg-primary d-inline-block m-r-md" style="width: 25px; height: 25px">
                                    &nbsp;
                                </span>
                                <small>Respondida</small>
                            </span>
                            <span class="d-flex-row flex-justify-between flex-align-center">
                                <span class="question-circle border-primary d-inline-block m-r-md" style="width: 25px; height: 25px"></span>
                                <small>Não respondida</small>
                            </span>
                        </div>
                    </div>
                    <div class="card p-10 m-t-xl collapsible-container" v-if="useFacialRecognition() && isMobile()">
                        <h3>
                            Monitoramento
                            <span class="fa fa-lg expand-arrow" @click="expandedCards[2] = !expandedCards[2]"
                                  :class="expandedCards[2] ? 'fa-caret-up' : 'fa-caret-down'"></span>
                        </h3>
                        <div class="collapsible" :class="!expandedCards[2] ? 'collapsed' : ''">
                            <face-recognition-directive
                                    :useFacialRecognition="useFacialRecognition()"></face-recognition-directive>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-8 p-0 text-left m-t-xl" v-if="!modalFullScreen">
                    <div v-for="item in items" :key="item">
                        <div class="card p-10 m-t-xl" v-if="item.body">
                            <h3 aria-live="polite">Texto de apoio</h3>
                            <div class="col-xs-12 p-0 m-t-lg" v-html="item.body" aria-live="polite"></div>
                            <div v-if="configs.variables.use_text_to_speech && defaultVoice">
                                <div class="col-xs-12 p-0 m-t-lg m-b-lg" aria-live="polite">
                                    <a type="button" class="p-x-md color-primary" alt="Start text to speech" @click="startTextToSpeech(item)"><i
                                            class="fa fa-play"></i></a>
                                    <a type="button" class="p-x-md color-primary" alt="Pause text to speech" @click="pauseTextToSpeech()"><i
                                            class="fa fa-pause"></i></a>
                                    <a type="button" class="p-x-md color-primary" alt="Stop text to speech" @click="stopTextToSpeech()"><i
                                            class="fa fa-stop"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="card p-10 m-t-xl" v-for="question in item.questions" :key="question"
                             :id="'question-' + question.number">
                            <div class="col-xs-8 p-0">
                                <h3 aria-live="polite">Questão {{ question.number }} <span
                                        v-if="question.content">| {{ question.content }}</span></h3>
                            </div>
                            <div class="col-xs-4 p-0 text-right" v-if="this.assessment.configs.show_question_code">
                                <small aria-live="polite">Código da questão: {{ question.code }}</small>
                            </div>
                            <div class="question_container">
                                <div aria-live="polite" class="col-xs-12 p-0 m-t-lg" v-html="question.body"></div>
                            </div>

                            <div v-if="configs.variables.use_text_to_speech">
                                <div class="col-xs-12 p-0 m-t-lg m-b-lg" aria-live="polite">
                                    <a type="button" class="p-x-md color-primary" alt="Start text to speech"
                                       @click="startTextToSpeech(question)"><i class="fa fa-play"></i></a>
                                    <a type="button" class="p-x-md color-primary" alt="Pause text to speech" @click="pauseTextToSpeech()"><i
                                            class="fa fa-pause"></i></a>
                                    <a type="button" class="p-x-md color-primary" alt="Stop text to speech" @click="stopTextToSpeech()"><i
                                            class="fa fa-stop"></i></a>
                                </div>
                            </div>

                            <div v-if="question.type === 'o'">
                                <div v-for="alternative in question.alternatives" :key="alternative"
                                     class="col-xs-12 p-0 alternative-row">
                                    <div class="d-flex-row flex-align-center flex-justify-between p-10"
                                         :class="alternative === question.markedAlternative ? 'bg-primary-light' : ''">
                                        <div class="col-xs-3 col-sm-2 col-md-1 p-0 text-center">
                                            <div class="border-primary alternative-container"
                                                 :class="alternative === question.markedAlternative ? 'bg-primary' : ''" tabindex="0"
                                                 @click="saveQuestion(question, alternative)">
                                                {{ alternative.letter }}
                                            </div>
                                        </div>
                                        <div class="col-xs-9 col-sm-10 col-md-11 p-0 p-l-lg" v-html="alternative.body"></div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-xs-12 p-0 m-y-lg">
                                <div class="col-xs-12 p-0" v-if="getAnswerType(question) === 'normal'">
                                    <textarea :id="'textarea-' + question.pk" v-model="question.answer_text" tabindex="0"></textarea>
                                    <div class="col-xs-12 p-0 text-right" aria-live="polite">
                                        <button class="btn btn-primary m-t-lg" @click="saveQuestion(question, null)" tabindex="0">
                                            Salvar resposta
                                        </button>
                                    </div>
                                </div>
                                <div class="col-xs-12 p-0 text-center" v-else-if="getAnswerType(question) === 'file'">
                                    <h4 class="m-b-lg">Resposta em formato de arquivo</h4>
                                    <p v-if="question.answer_text">Arquivo enviado: {{ getFileNameFromPath(question.answer_text) }}</p>
                                    <a @click="openFileModal(question)" class="btn btn-round btn-primary">
                                        <i class="fa fa-upload"></i> {{ question.answer_text ? 'Substituir' : 'Enviar' }} arquivo
                                    </a>
                                </div>
                                <div class="col-xs-12 p-0 text-center" v-else-if="getAnswerType(question) === 'audio'">
                                    <audio-recorder
                                        :initial="question.answer_text" :test-id="this.$route.params.key"
                                        :callback="(audio, audioUrl) => saveMediaAnswer(question, audioUrl)">
                                    </audio-recorder>
                                </div>
                                <div class="col-xs-12 p-0 text-center" v-else-if="getAnswerType(question) === 'video'">
                                    <video-recorder
                                        :initial="question.answer_text" :test-id="this.$route.params.key"
                                        :callback="(video, videoUrl) => saveMediaAnswer(question, videoUrl)">
                                    </video-recorder>
                                </div>
                                <div class="col-xs-12 p-0" v-else-if="getAnswerType(question) === 'code'">
                                    <v-ace-editor v-model:value="question.answer_text" lang="html"
                                                  theme="chrome" style="height: 300px" @change="codeDebounce(question)"/>

                                    <div class="col-xs-12 p-0 text-right" aria-live="polite">
                                        <button class="btn btn-primary m-t-lg" @click="saveQuestion(question, null)" tabindex="0">
                                            Salvar resposta
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 p-0 m-y-lg text-right"
                                 v-if="configs.variables.make_appeal_during_online_test && !hideAppeal[question.pk]">
                                <a class="btn btn-primary" @click="showAppeal[question.pk] = !showAppeal[question.pk]" aria-live="polite">
                                    Reportar erro
                                </a>
                            </div>
                            <div class="col-xs-12 p-0 m-y-lg text-left collapsible" v-if="!hideAppeal[question.pk]"
                                 :class="showAppeal[question.pk] ? '' : 'collapsed'">
                                <span aria-live="polite">Motivo:</span>
                                <textarea style="width: 100%; resize: vertical;" v-model="appealReason[question.pk]">
                                </textarea>
                                <div class="col-xs-12 p-0 text-right">
                                    <button aria-live="polite" class="btn btn-success" @click="createQuestionAppeal(question.pk)">
                                        Enviar
                                        <span v-if="loadingAppeal[question.pk]"> <i
                                                class="fa fa-spin fa-circle-o-notch"></i></span>
                                    </button>
                                    <div class="color-danger" v-if="appealError[question.pk]" aria-live="polite">
                                        {{ appealError[question.pk] }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 p-0 m-y-lg text-center" v-if="question.status">
                                <div class="bg-warning p-10" v-if="question.status==='saving'">
                                    <span aria-live="assertive">Salvando questão...</span>
                                </div>
                                <div class="bg-success p-10" v-else>
                                    <span aria-live="assertive">Questão salva!</span>
                                </div>
                            </div>
                            <div class="col-xs-12 p-0 m-y-lg text-center" v-if="savedAppeal[question.pk]">
                                <div class="bg-success p-10">
                                    <span aria-live="assertive">Requerimento salvo!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-4 text-left m-t-xl mobile-hidden-inline-block">
                    <div class="fixed-container">
                        <div class="card p-10 m-t-xl">
                            <h3 aria-live="polite">
                                Dados da avaliação
                                <span class="fa fa-lg expand-arrow m-r-lg" @click="expandedCards[0] = !expandedCards[0]"
                                      :class="expandedCards[0] ? 'fa-caret-up' : 'fa-caret-down'"></span>
                            </h3>
                            <div class="col-xs-12 p-0 m-t-xl collapsible" :class="!expandedCards[0] ? 'collapsed' : ''">
                                <div class="col-xs-8 p-0" aria-live="polite"><i class="fa fa-user"></i> {{
                                    assessment.configs.exam.candidate.name }}
                                </div>
                                <div class="col-xs-4 p-0" aria-live="polite"><i class="fa fa-id-card"></i> {{
                                    assessment.configs.exam.candidate.enrollment }}
                                </div>
                            </div>
                            <div class="col-xs-12 p-0 text-center m-t-lg collapsible" :class="!expandedCards[0] ? 'collapsed' : ''">
                                <button class="btn btn-primary m-r-md m-l-md" @click="modalInstructions = true"
                                        v-if="assessment.configs.exam.instructions.length > 0" aria-live="polite">
                                    <i class="fa fa-info-circle m-r-4"></i> Instruções
                                </button>
                                <button class="btn btn-primary m-r-md m-l-md" @click="modalAttachment = true"
                                        v-if="assessment.configs.exam.has_attachment" aria-live="polite">
                                    <i class="fa fa-paperclip m-r-4"></i> Anexo
                                </button>
                            </div>
                            <div class="col-xs-12 p-0 m-t-xl text-center collapsible" :class="!expandedCards[0] ? 'collapsed' : ''">
                                <h1>
                                    <span v-if="showTimer" class="timer" aria-hidden="true">{{ secondsToDate(timer) }}</span>
                                    <span v-else class="timer timer-hide">&nbsp;</span>
                                    <i class="fa fa-eye cursor-pointer" @click="showTimer = !showTimer"
                                       title="Alternar cronômetro"></i>
                                </h1>
                            </div>
                            <div class="col-xs-12 p-0 m-t-xl collapsible" :class="!expandedCards[0] ? 'collapsed' : ''">
                                <button v-bind="isOffline ? {disabled: 'disabled'} : {}" aria-live="polite" tabindex="0"
                                        class="col-xs-12 btn btn-primary" @click="finishTestBtn()">
                                    Entregar prova
                                </button>
                            </div>
                        </div>
                        <div class="card p-10 m-t-xl">
                            <h3 aria-live="polite" id="labelProgress">
                                Progresso
                                <span class="fa fa-lg expand-arrow m-r-lg" @click="expandedCards[1] = !expandedCards[1]"
                                      :class="expandedCards[1] ? 'fa-caret-up' : 'fa-caret-down'"></span>
                            </h3>
                            <div class="col-xs-12 p-0 m-t-xl collapsible" :class="!expandedCards[1] ? 'collapsed' : ''">
                                <div class="progress-bar">
                                    <div class="bg-primary" aria-label="{{100*Object.keys(answers).filter((questionId) => answers[questionId]).length/totalQuestions}} '%'" aria-labelledby="labelProgress"
                                         :style="'width: ' + 100*Object.keys(answers).filter((questionId) => answers[questionId]).length/totalQuestions + '%'">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 p-0 text-left m-t-xs collapsible" :class="!expandedCards[1] ? 'collapsed' : ''">
                                <small aria-label="{{ Object.keys(answers).filter((questionId) => answers[questionId]).length.toString().padStart(2, '0') }}/{{
                                    totalQuestions.toString().padStart(2, '0') }} 'questões respondidas'">
                                    {{ Object.keys(answers).filter((questionId) => answers[questionId]).length.toString().padStart(2, '0') }}/{{
                                    totalQuestions.toString().padStart(2, '0') }}
                                    questões respondidas
                                </small>
                            </div>
                            <div class="col-xs-12 p-0 m-t-xl d-flex-row flex-align-center flex-justify-start flex-line-break collapsible"
                                 :class="!expandedCards[1] ? 'collapsed' : ''">
                                <div v-for="question in getQuestionsArray()" :key="question" class="text-center"
                                     style="flex-basis: 35px">
                                    <div class="question-circle d-flex-row flex-align-center flex-justify-evenly cursor-pointer m-auto m-t-sm"
                                         :class="answers[question.pk] ? 'bg-primary' : 'border-primary'" aria-label="questão {{question.number}} ${answers[question.pk] ? 'não' : ''} respondida"
                                         @click="scrollToQuestion(question)">
                                        {{ question.number }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 p-0 m-t-xl collapsible" :class="!expandedCards[1] ? 'collapsed' : ''" aria-hidden="true"><p class="m-0">Legenda</p></div>
                            <div class="col-xs-12 p-0 m-t-sm d-flex-row flex-justify-between flex-align-center collapsible" aria-hidden="true"
                                 :class="!expandedCards[1] ? 'collapsed' : ''">
                                <span class="d-flex-row flex-justify-between flex-align-center">
                                    <span class="question-circle bg-primary d-inline-block m-r-md" style="width: 25px; height: 25px">
                                        &nbsp;
                                    </span>
                                    <small>Respondida</small>
                                </span>
                                <span class="d-flex-row flex-justify-between flex-align-center">
                                    <span class="question-circle border-primary d-inline-block m-r-md" style="width: 25px; height: 25px"></span>
                                    <small>Não respondida</small>
                                </span>
                            </div>
                        </div>
                        <div class="card p-10 m-t-xl" v-if="useFacialRecognition() && !isMobile()" aria-hidden="true">
                            <h3>
                                Monitoramento
                                <span class="fa fa-lg expand-arrow m-r-lg" @click="expandedCards[2] = !expandedCards[2]"
                                      :class="expandedCards[2] ? 'fa-caret-up' : 'fa-caret-down'"></span>
                            </h3>
                            <face-recognition-directive
                                    :useFacialRecognition="useFacialRecognition()"
                                    class="collapsible" :class="!expandedCards[2] ? 'collapsed' : ''">
                            </face-recognition-directive>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-dialog v-show="modalFullScreen" :hide-close="true" mask-class="bg-blank">
            <h3 class="m-b-md">Tela cheia</h3>
            <p>Para prosseguir com a avaliação, você precisa estar em modo de tela cheia.</p>
            <button class="btn btn-primary" @click="$fullscreen.request()">Ir para o modo de tela cheia</button>
        </modal-dialog>

        <modal-send-file
                :question="currentFileQuestion" @close="currentFileQuestion = null" mask-class="bg-blank" :test-id="this.$route.params.key"
                :initial="currentFileQuestion?.answer_text" :callback="(file, fileUrl) => saveMediaAnswer(currentFileQuestion, fileUrl)">
        </modal-send-file>

        <modal-dialog v-show="sharingScreenError" :hide-close="true" mask-class="bg-blank">
            <h3 class="m-b-md">Compartilhamento de tela</h3>
            <p>Para prosseguir com a avaliação, você precisa compartilhar sua tela.</p>
            <button class="btn btn-primary" @click="restartScreenSharing()">Compartilhar tela</button>
        </modal-dialog>

        <modal-dialog v-show="modalMultipleMonitors" :hide-close="true" mask-class="bg-blank">
            <h3 class="m-b-md">Múltiplos monitores</h3>
            <p>Você está utilizando mais de um monitor.</p>
            <p>Para prosseguir com a avaliação, você precisa utilizar somente um monitor.</p>
            <button class="btn btn-primary" @click="checkMultipleMonitors()">Desconectei os monitores</button>
        </modal-dialog>

        <modal-dialog v-show="modalInstructions && !modalFullScreen" @close="modalInstructions = false"
                      class="instructions-modal">
            <h3 class="m-b-md">Instruções</h3>
            <div v-if="assessment" v-html="assessment.configs.exam.instructions[0]"></div>
        </modal-dialog>

        <modal-dialog v-show="modalAttachment && !modalFullScreen" @close="modalAttachment = false"
                      class="instructions-modal">
            <h3 class="m-b-md">Anexo</h3>
            <div v-if="assessment" v-html="assessment.configs.exam.attachment"></div>
        </modal-dialog>

        <modal-dialog v-show="modalFinishingTest && !modalFullScreen" @close="modalFinishingTest = false"
                      mask-class="bg-blank">
            <h3 class="m-b-md">Entrega de prova</h3>
            <div class="col-xs-12 p-0 text-left">
                <h1>
                    <span :class="Object.keys(answers).filter((questionId) => !!answers[questionId]).length < totalQuestions ? 'color-danger' : 'color-success'">
                        {{ Object.keys(answers).filter((questionId) => !!answers[questionId]).length }}
                    </span>
                    de {{ totalQuestions }}
                </h1>
                <p>questões foram respondidas!</p>
                <div v-if="Object.keys(answers).filter((questionId) => !!answers[questionId]).length < totalQuestions">
                    <small>As questões abaixo não foram respondidas ou apresentaram erro.</small>
                    <br/>
                    <small>Por favor confira sua prova.</small>
                </div>
                <div v-else class="color-success">
                    <small>Parabéns! Todas as questões foram respondidas.</small>
                    <br/>
                </div>
                <div class="d-flex-row flex-align-center flex-justify-evenly flex-line-break m-auto m-t-sm">
                    <div v-for="number in getUnansweredQuestions()" :key="number" style="flex-basis: 50px">
                        <div class="bg-primary-light d-flex-row flex-align-center flex-justify-evenly m-t-md color-danger"
                             style="width: 40px; height: 40px">
                            <strong>{{ number }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 p-0 m-t-xl" v-if="Object.keys(answers).filter((questionId) => !!answers[questionId]).length < totalQuestions">
                    <button class="btn btn-danger" @click="modalFinishingTest = false">Conferir minhas respostas
                    </button>
                </div>
                <div class="col-xs-12 p-0 m-t-xl" v-else>
                    <button class="btn btn-success" @click="finishTest">Entregar minha prova</button>
                </div>
                <div class="col-xs-12 p-0 m-t-xl border-primary border-top">
                    <div class="col-xs-6 m-t-lg p-0" id="timeleftElement" aria-hidden="true"><small>Tempo restante: {{ secondsToDate(timer) }}</small></div>
                    <div class="col-xs-6 m-t-lg p-0 text-right">
                        <a class="link" @click="finishTest()" v-if="Object.keys(answers).filter((questionId) => !!answers[questionId]).length < totalQuestions"><small>
                            Entregar a prova com questões incompletas
                        </small></a>
                        <a class="link" @click="modalFinishingTest = false" v-else><small>
                            Conferir minhas respostas
                        </small></a>
                    </div>
                </div>
            </div>
        </modal-dialog>

        <modal-dialog v-show="modalFinishedTest" @close="modalFinishedTest = false"
                      mask-class="bg-blank" :hide-close="true">
            <div class="col-xs-12 text-center p-b-xl" v-if="finishingTest">
                <h3 class="p-y-xl">Finalizando prova...</h3>
                <i class="fa fa-circle-o-notch fa-spin fa-lg"></i>
            </div>
            <div v-else>
                <h3 class="p-y-xl" v-if="finishedByInstitution">
                    Sua prova foi encerrada pela instituição. Clique abaixo para retornar à página inicial
                </h3>
                <h3 class="p-y-xl" v-else-if="finishExamError">
                    Houve um erro ao finalizar sua prova.
                </h3>
                <h3 class="p-y-xl" v-else-if="timer <= 0">
                    O tempo para fazer a prova acabou.
                </h3>
                <h3 class="p-y-xl" v-else>Prova finalizada com sucesso!</h3>
                <div v-if="assessment && assessment.result !== null" class="p-b-xl">
                    <h3>Resultado:</h3>
                    <h4>{{ assessment.result.toFixed(2) }} / {{ totalValue().toFixed(2) }}</h4>
                </div>
                <button class="btn btn-primary m-b-xl" @click="redirectToStart()">
                    Voltar para a pagina inicial
                </button>
            </div>
        </modal-dialog>
        <modal-dialog v-show="showModalTimeError" mask-class="bg-blank" :hide-close="true">
            <div>
                <h3 class="p-y-xl">
                    O cronômetro da sua prova perdeu sincronização com o horário do servidor. Por favor recarregue a página.
                </h3>
                <button class="btn btn-primary m-b-xl" @click="reloadPage()">
                    Recarregar a página
                </button>
            </div>
        </modal-dialog>

        <div id="connection-container">
            <div class="col-xs-12 text-center bg-warning p-y-xl" :class="isOffline ? '' : 'top-hidden'">
                <span>Você está offline! As respostas não serão gravadas. Tentando reconexão...</span>
                <i class="fa fa-circle-o-notch fa-spin m-l-lg"></i>
            </div>
            <div class="col-xs-12 text-center bg-success p-y-xl" :class="returnConnMsg ? '' : 'top-hidden'">
                Conexão restaurada!
            </div>
        </div>

        <chat-window-view v-if="$store.state.onlineTest.configs && $store.state.onlineTest.configs.variables.native_proctoring"></chat-window-view>

    </div>
</template>

<script>
    import {stripQuestionTags, isMobile, secondsToDate, getCookie, getFileNameFromPath} from "../../../scripts/utils";
    import {debounce} from "debounce";
    import { VAceEditor } from 'vue3-ace-editor';
    import ModalDialog from "../../base/Modal";
    import VueSocketIO from "vue-socket.io";
    import FaceRecognitionDirective from "../../includes/FaceRecognition/FaceRecognitionDirective";
    import {getBaseSgpUrlNoSlug} from "../../../scripts/apiService/ApiService";
    import { getTestUrl } from "../../../scripts/apiService/tests";
    import axios from "axios";
    import ChatWindowView from "../ChatWindowView";
    import { onlineTestQuestions$, screenSharingStatus$ } from "../../../scripts/observableStates";
    import * as rxjs from "rxjs";
    import { initScreenStream, stopScreenStream } from "../../../scripts/screenSharingStream";
    import AudioRecorder from "../../includes/AudioRecorder";
    import VideoRecorder from "../../includes/VideoRecorder";
    import ModalSendFile from "../../includes/ModalSendFile";
    import { firestoreDB } from "../../../scripts/firebase";

    export default {
        name: 'OnlineTestView',
        components: {
            ModalSendFile,
            VideoRecorder, AudioRecorder, FaceRecognitionDirective, ModalDialog, ChatWindowView, VAceEditor},
        data: () => {
            return {
                items: [],
                answers: [],
                firestoreObj: null,
                assessment: null,
                configs: null,

                synthTimeout: null,
                defaultVoice: null,
                currentlyPlaying: null,
                synth: null,

                modalFullScreen: false,
                modalInstructions: false,
                modalAttachment: false,
                modalFinishingTest: false,
                modalFinishedTest: false,
                modalMultipleMonitors: false,
                currentFileQuestion: null,

                finishingTest: false,
                finishedByInstitution: false,
                finishedResult: null,
                finishExamError: false,
                showTimer: true,
                timer: 0,
                totalQuestions: 0,
                isOffline: false,
                returnConnMsg: false,
                expandedCards: [true, true, true],
                showAppeal: {},
                appealError: {},
                appealReason: {},
                hideAppeal: {},
                loadingAppeal: {},
                savedAppeal: {},
                alertTimes: [],
                subscriptionFroalaImports: null,
                sharingScreenError: false,
                statusScreenSubscription: null,
                debouncer : {},
                showModalTimeError: false
            }
        },
        methods: {
            debounce: debounce,
            secondsToDate: secondsToDate,
            isMobile: isMobile,
            getFileNameFromPath: getFileNameFromPath,
            reloadPage(){
                location.reload();
            },
            restartScreenSharing(){
              initScreenStream();
            },
            codeDebounce(question) {
                if (this.debouncer[question.pk]) {
                    clearTimeout(this.debouncer[question.pk]);
                }
                this.debouncer[question.pk] = setTimeout(() => {
                    this.saveQuestion(question);
                }, 1500)
            },
            saveMediaAnswer(question, fileUrl) {
                question.answer_text = fileUrl;
                this.saveQuestion(question);
            },
            async saveQuestion(question, alternative) {
                if (this.debouncer[question.pk]) {
                    clearTimeout(this.debouncer[question.pk]);
                }
                if (this.isOffline) {
                    return;
                }
                let answer;
                let previousAnswer;
                let answerDetail;
                if (question.type === 'o') {
                    previousAnswer = question.markedAlternative;
                    question.markedAlternative = alternative;
                    answer = alternative.pk;
                    answerDetail = alternative.letter
                } else if (question.type === 'e' || question.type === 't') {
                    question.savedQuestion = true;
                    answer = question.answer_text ?? "";
                    answerDetail = ""
                }

                let answerLog = {
                    datetime: new Date(),
                    display_number: question.number,
                    command: question.pk,
                    answer: answer,
                    answer_detail: answerDetail
                };

                if (!this.answers) {
                    this.answers = {};
                }

                try {
                    let canSave = !(await this.checkCanceledOrFinishedTest());

                    if (canSave) {
                        this.answers[question.pk] = answer;

                        question.status = "saving";
                        await this.firestoreObj.update({answers: this.answers});
                        question.status = "saved";
                        setTimeout(() => {
                            question.status = null
                        }, 2000);
                        await this.addFirestoreLog(answerLog);
                    }
                } catch {
                    question.markedAlternative = previousAnswer;
                }

                this.$announcer.set('Resposta da questão'+ question.number + 'salva', 'assertive');
            },
            async checkCanceledOrFinishedTest() {
                const firestoreObj = this.firestoreObj;

                const assessmentRef = await firestoreObj.get();
                const assessmentData = assessmentRef.data();
                const started = assessmentData.started;
                if (!started) {
                    this.modalFinishedTest = true;
                    this.finishedByInstitution = true;
                    return "cancelled";
                } else {
                    this.finished = assessmentData.finished;
                    if (assessmentData.finished) {
                        this.modalFinishedTest = true;
                        this.finishedByInstitution = true;
                        return "finished";
                    }
                }
            },
            async setStartedExam() {
                const firestoreObj = this.firestoreObj;

                const assessmentRef = await firestoreObj.get();
                const assessmentData = assessmentRef.data();
                if (!assessmentData.started) {
                    await firestoreObj.update({started: true});
                }
                if (!assessmentData.finished) {
                    this.connectToStatusServer();
                }
            },
            TextToSpeech(phrase) {
                let utterThis = new SpeechSynthesisUtterance(phrase);
                utterThis.voice = this.defaultVoice;
                this.synth.speak(utterThis);
                this.startSpeechTimeouts();
            },
            TextToSpeechQueueEnd() {
                let utterThis = new SpeechSynthesisUtterance("");
                utterThis.voice = this.defaultVoice;
                utterThis.onend = this.stopTextToSpeech;
                this.synth.speak(utterThis);
            },
            startSpeechTimeouts() {
                if (this.synthTimeout) {
                    clearTimeout(this.synthTimeout);
                    this.synthTimeout = null;
                }
                let myTimer = () => {
                    if (!window.speechSynthesis.paused) {
                        window.speechSynthesis.pause();
                        window.speechSynthesis.resume();
                    }
                    this.synthTimeout = setTimeout(myTimer, 10000);
                };
                myTimer();
            },
            resetAnswerEditors() {
                for (let item of this.items) {
                    item.questions.forEach((question) => {
                        if (question.type !== "o") {
                            if (this.getAnswerType(question) === "normal") {
                                let elemId = "#textarea-" + question.pk;
                                question.editor = new window.FroalaEditor(elemId, this.getFroalaOptions(question));
                            }
                        }
                    });
                }
            },
            getAnswerType(question) {
                let answerTypes = {
                    code: 4,
                    file: 5,
                    audio: 6,
                    video: 7
                };
                if (!question.answer_type) {
                    return "normal"
                }
                if (question.answer_type >= answerTypes.code) {
                    return ["code", "file", "audio", "video"]
                        .find((type) => question.answer_type === answerTypes[type])
                }
                return "normal"
            },
            openFileModal(question) {
                this.currentFileQuestion = question;
                this.$nextTick(() => {
                    document.getElementById("file-input").click();
                });
            },
            getFroalaOptions(question) {
                let froalaOptions = {
                    events: {
                        contentChanged: () => {
                            question.answer_text = question.editor.html.get();
                            this.codeDebounce(question);
                        },
                        'paste.before': () => {
                            return (!this.configs.variables.block_copy_paste);
                        }
                    },
                    language: 'pt_br',
                    toolbarButtons: ['bold', 'italic', 'underline', '|', 'formatOL', 'formatUL', '|', 'wirisEditor'],
                    quickInsertButtons: ['ol', 'ul'],
                    htmlAllowedTags: ['.*'],
                    htmlAllowedAttrs: ['.*'],
                    useClasses: false,
                    immediateVueModelUpdate: true,
                    imagePaste: false,
                    attribution: false,
                    key: 'cJC7bE6D2F3H3D1A1yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5D5C4D3C2D4G2H1=='
                };
                froalaOptions.toolbarButtons.push('|', 'insertTable', 'specialCharacters');
                return froalaOptions
            },
            startTextToSpeech(question) {
                if (this.currentlyPlaying === question) {
                    this.synth.resume();
                } else {
                    this.synth.cancel();
                    this.currentlyPlaying = question;
                    this.TextToSpeech(stripQuestionTags(question.body, true));
                    if (question.alternatives) {
                        question.alternatives.forEach((alternative) => {
                            this.TextToSpeech("Alternativa " + alternative.letter);
                            this.TextToSpeech(stripQuestionTags(alternative.body, true));
                        })
                    }
                    this.TextToSpeechQueueEnd();
                }
            },
            pauseTextToSpeech() {
                this.synth.pause();
            },
            stopTextToSpeech() {
                this.synth.cancel();
                this.currentlyPlaying = null;
                if (this.synthTimeout) {
                    clearTimeout(this.synthTimeout);
                    this.synthTimeout = null;
                }
            },
            initTextToSpeech() {
                this.synth = window.speechSynthesis;
                let voices = this.synth.getVoices();
                this.defaultVoice = voices.find((voice) => voice.lang === 'pt-BR');
                this.synth.onvoiceschanged = () => {
                    let voices = this.synth.getVoices();
                    this.defaultVoice = voices.find((voice) => voice.lang === 'pt-BR');
                };
            },
            initFullScreen() {
                let checkFullScreen = () => {
                    setTimeout(() => {
                        if (this.modalFullScreen && this.$fullscreen.isFullscreen) {
                            this.modalFullScreen = !this.$fullscreen.isFullscreen;
                            this.$nextTick(() => {
                                this.resetAnswerEditors();
                            });
                        }
                        this.modalFullScreen = !this.$fullscreen.isFullscreen;
                        if(!this.modalFullScreen) document.getElementById('titleTestView').focus();
                    }, 200);
                };

                if (!isMobile()) {
                    document.addEventListener("fullscreenchange", checkFullScreen, false);
                    document.addEventListener("webkitfullscreenchange", checkFullScreen, false);
                    document.addEventListener("mozfullscreenchange", checkFullScreen, false);

                    checkFullScreen();
                }
            },
            initMultipleMonitors(){
                if (this.configs.variables.block_multiple_monitors) {
                    this.checkMultipleMonitors();
                    setInterval(() => {
                        this.checkMultipleMonitors();
                    }, 200);
                }
            },
            checkMultipleMonitors(){
                this.modalMultipleMonitors = window.screen.isExtended;
            },
            connectToStatusServer() {
                let store = this.$store;
                let websocket_url = this.configs.variables.prova_online_status_websocket_url + '?';
                let client_slug = 'clientSlug=' + this.configs.variables.client_slug;
                let client_id = '&clientId=' + this.configs.variables.client_id;
                let item_generated = '&itemGenerated=' + this.$route.params.key;
                let online_test_queue_name = '&onlineTestQueueName=' + this.configs.variables.online_test_queue_name;

                let socketConnection = new VueSocketIO({
                    debug: true,
                    connection: websocket_url + client_slug + client_id + item_generated + online_test_queue_name,
                    vuex: {
                        store,
                        actionPrefix: 'SOCKET_',
                        mutationPrefix: 'SOCKET_'
                    },
                    options: {
                        transports: ['websocket']
                    }
                });

                setInterval(function () {
                    let status = this.$store.state.isOffline;
                    if (socketConnection.io.connected) {
                        this.isOffline = false;
                        this.returnConnMsg = !!status;
                    } else {
                        this.isOffline = true;
                        this.returnConnMsg = false;
                    }
                }.bind(this), 1000)
            },
            initTimer() {
                this.timer = this.assessment.configs.online_test_duration;
                this.alertTimes = [Math.floor(this.timer / 2), 600];
                setInterval(() => {
                    this.timer--;
                    if (this.timer <= 0) {
                        this.modalFinishedTest = true;
                    }
                    if(this.timer > 0 && this.alertTimes.includes(this.timer)) this.$announcer.set('Tempo restante: '+ this.secondsToDate(this.timer), 'assertive');
                }, 1000);

                setInterval(() => {
                    this.checkTime();
                }, 60000);

            },
            checkTime(){
                let exam_configs_url = this.configs.urls.exam_configs;
                exam_configs_url = exam_configs_url.replace("/" + this.configs.variables.client_slug + "/", "");
                getTestUrl(this.$route.params.key, exam_configs_url).then((assessmentResponse) => {
                    if(Math.abs(this.timer - assessmentResponse.data.configs.online_test_duration) > 60) this.showModalTimeError = true;
                });
            },
            getQuestionsArray() {
                let result = [];
                this.items.forEach((item) => {
                    result = result.concat(item.questions);
                });
                return result;
            },
            scrollToQuestion(question) {
                document.getElementById('question-' + question.number).scrollIntoView();
            },
            useFacialRecognition() {
                return this.configs.variables.enable_facial_recognition || this.configs.variables.enable_take_photo;
            },
            async finishTestBtn() {
                let canSave = !(await this.checkCanceledOrFinishedTest());
                if (canSave) {
                    this.modalFinishingTest = true;
                }
            },
            getUnansweredQuestions() {
                let result = [];
                let number = 1;
                this.items.forEach((item) => {
                    item.questions.forEach((question) => {
                        const answer = this.answers[question.pk];
                        if (!answer || !answer.trim()) {
                            result.push(number);
                        }
                        number++;
                    })
                });
                return result;
            },
            finishTest() {
                this.stopTextToSpeech();
                this.finishingTest = true;
                this.modalFinishedTest = true;
                if (Object.keys(this.answers).filter(questionId => !!this.answers[questionId]).length < this.totalQuestions) {
                    this.sendOnlineTestIncomplete();
                } else {
                    this.sendOnlineTestComplete();
                }
            },
            redirectToStart() {
                this.$fullscreen.exit();
                let redirectUrl = this.configs.urls.redirect_url;
                if (redirectUrl.startsWith("/")) {
                    let baseUrl = getBaseSgpUrlNoSlug();
                    redirectUrl = baseUrl + redirectUrl;
                }
                window.location.href = redirectUrl;
            },
            sendOnlineTestComplete() {
                const url = `${getBaseSgpUrlNoSlug()}${this.configs.urls.finish_exam_complete}`;
                this.sendOnlineTestWithURL(url);
            },
            sendOnlineTestIncomplete() {
                const url = `${getBaseSgpUrlNoSlug()}${this.configs.urls.finish_exam_incomplete}`;
                this.sendOnlineTestWithURL(url);
            },
            sendOnlineTestWithURL(url) {
                let headers = {};
                if (getCookie("candidateToken")) {
                    headers.Authorization = 'Token ' + getCookie("candidateToken");
                }
                axios(url, {
                    method: "POST",
                    withCredentials: true,
                    headers: headers
                })
                .then((response) => {
                    this.finishingTest = false;
                    this.assessment.result = response.data.result;
                    if (window.stream) {
                        window.stream.getTracks().forEach(function(track) {
                            track.stop();
                        });
                    }
                }).catch(() => {
                    this.finishingTest = false;
                    if (!this.isMockTest()) {
                        this.finishExamError = true;
                    }
                });
            },
            isMockTest(){
                return this.$route.params.key.includes("-")
            },
            createQuestionAppeal(command) {
                if (!this.appealReason[command]) {
                    this.appealError[command] = 'Motivo é obrigatório.';
                    return
                }
                this.loadingAppeal[command] = true;
                this.appealError[command] = null;
                let data = {
                    command: command,
                    itemGenerated: this.$route.params.key,
                    candidate: this.configs.variables.candidate_key,
                    comment: this.appealReason[command]
                };
                let url = getBaseSgpUrlNoSlug() + this.configs.urls.create_question_appeal;
                const headers = {"X-CSRFTOKEN": getCookie('csrftoken')};

                if (getCookie("candidateToken")) {
                    data = JSON.stringify(data);
                    headers.Authorization = 'Token ' + getCookie("candidateToken")
                }

                axios.post(url, data, {withCredentials: true, headers: headers})
                    .then(() => {
                        this.loadingAppeal[command] = false;
                        this.hideAppeal[command] = true;
                        this.savedAppeal[command] = true;
                        setTimeout(() => {this.savedAppeal[command] = false;}, 2000);
                    }).catch((error) => {
                        this.loadingAppeal[command] = false;
                        this.appealError[command] = error.response.data;
                    });
            },
            checkPrevent(event){
                if (this.configs.variables.block_copy_paste){
                    event.preventDefault();
                }
            },
            totalValue(){
                let totalValue = 0;
                this.items.forEach((item)=>{
                    item.questions.forEach((question)=>{
                        totalValue += question.value
                    })
                });
                return totalValue

            },
            getFirestoreObject(){
                let clientCollection = `${this.configs.variables.client_slug}-${this.configs.variables.client_id}`;
                this.firestoreObj = firestoreDB
                    .collection("provaonline")
                    .doc(clientCollection)
                    .collection("assessment")
                    .doc(this.$route.params.key);
                this.initTimer();
                this.setStartedExam();
            },
            async addFirestoreLog(logData){
                await this.firestoreObj.collection("logs").add(logData);
            },
            loadQuestions(){
                const questions = onlineTestQuestions$.getCurrentValue();

                this.items = questions.items;
                this.answers = questions.answers;
                this.totalQuestions = questions.totalQuestions;

                this.resetAnswerEditors();
                if(this.subscriptionFroalaImports) this.subscriptionFroalaImports.unsubscribe();

            },
            loadFroalaAndQuestions() {
                const froalaPluginsImport = rxjs.from(import('froala-editor/js/plugins.pkgd.min.js'));
                const froalaCssImport = rxjs.from(import('froala-editor/css/froala_editor.pkgd.min.css'));
                const froalaMathImport = rxjs.from(import('@wiris/mathtype-froala'));
                this.subscriptionFroalaImports  = rxjs.combineLatest([froalaPluginsImport, froalaCssImport, froalaMathImport]).subscribe(
                        this.loadQuestions
                )
            },
            initVerifySharingScreen(){
                if(this.$store.state.onlineTest.configs.variables.native_proctoring){
                    this.statusScreenSubscription = screenSharingStatus$.get().subscribe(obj => {
                        if(obj) this.sharingScreenError = obj.state !== "streaming";
                    });
                }
            },
        },
        mounted() {
            this.assessment = this.$store.state.onlineTest.assessment;
            this.configs = this.$store.state.onlineTest.configs;
            if (!this.assessment || !this.$store.state.onlineTest.started) {
                this.$router.replace(`/online-tests/${this.$route.params.key}/instructions`);
                return;
            }

            this.initTextToSpeech();

            this.getFirestoreObject();
            this.loadFroalaAndQuestions();
            this.initFullScreen();
            this.initMultipleMonitors();
            this.initVerifySharingScreen();
        },
        beforeUnmount() {
            if (this.statusScreenSubscription) this.statusScreenSubscription.unsubscribe();
            if (this.configs?.variables?.native_proctoring) stopScreenStream();
            if (this.subscriptionFroalaImports) this.subscriptionFroalaImports.unsubscribe();
        },
    }
</script>

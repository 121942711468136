<template>
    <div aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between flex-align-center p-r-60">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-calendar"></i> Requerimentos</p>
                <div class="input-select input">
                    <label>Status</label>
                    <select v-model="statusSearch" @change="search()" class="appeal-filter">
                        <option :value="null">Todos</option>
                        <option value="2">Em análise</option>
                        <option value="1">Aceito</option>
                        <option value="0">Rejeitado</option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="result !== null">
            <div class="col-xs-12 m-b-lg text-left" v-for="appeal in result" :key="appeal">
                <div class="card p-20">
                    <div class="d-flex-row flex-justify-between flex-align-center mobile-hidden-flex">
                        <h3>Avaliação: {{ formatDbDate(appeal.schedule.date_schedule) }} {{ appeal.schedule.time_schedule }}</h3>
                        <h3>Solicitação: {{ formatDbDatetime(appeal.creation_date) }}</h3>
                        <div>
                            <div v-if="appeal.status === 2 || appeal.status === null" class="chip bg-default m-r-sm d-inline-block">
                                <i class="fa fa-clock-o"></i> Em análise
                            </div>
                            <div v-else-if="appeal.status === 1" class="chip bg-success m-r-sm d-inline-block">
                                <i class="fa fa-check"></i> Aceito
                            </div>
                            <div v-else class="chip bg-danger m-r-sm d-inline-block">
                                <i class="fa fa-times"></i> Rejeitado
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 text-center mobile-show">
                        <div v-if="appeal.status === 2 || appeal.status === null" class="chip bg-default m-r-sm d-inline-block">
                            <i class="fa fa-clock-o"></i> Em análise
                        </div>
                        <div v-else-if="appeal.status === 1" class="chip bg-success m-r-sm d-inline-block">
                            <i class="fa fa-check"></i> Aceito
                        </div>
                        <div v-else class="chip bg-danger m-r-sm d-inline-block">
                            <i class="fa fa-times"></i> Rejeitado
                        </div>
                    </div>
                    <div class="col-xs-12 p-0 m-t-lg text-left mobile-show">
                        <h3>Avaliação: {{ formatDbDate(appeal.schedule.date_schedule) }} {{ appeal.schedule.time_schedule }}</h3>
                        <h3>Solicitação: {{ formatDbDatetime(appeal.creation_date) }}</h3>
                    </div>

                    <div class="col-xs-12 p-0 text-bold m-t-lg m-b-sm">Questão</div>
                    <span>{{ stripQuestionTags(appeal.command.body) }}</span>
                    <see-more-modal modal-title="Enunciado" :modal-content="appeal.command.body"></see-more-modal>

                    <div class="text-bold m-t-lg m-b-sm">Requerimento</div>
                    <span>{{ stripQuestionTags(appeal.comment) }}</span>
                    <see-more-modal modal-title="Enunciado" :modal-content="appeal.comment"></see-more-modal>

                    <div v-if="appeal.answer">
                        <div class="text-bold m-t-lg m-b-sm">Resposta</div>
                        <span>{{ stripQuestionTags(appeal.answer) }}</span>
                        <see-more-modal modal-title="Enunciado" :modal-content="appeal.answer"></see-more-modal>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 m-t-150" v-if="result.length === 0">
                <h3>Não há requerimentos cadastrados</h3>
            </div>
        </div>
        <div v-else class="col-xs-12 d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
            <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
        </div>
    </div>
</template>

<script>
    import {formatDbDate, formatDbDatetime, stripQuestionTags} from "../../scripts/utils";
    import {getAppealsList} from "../../scripts/apiService/appeals";
    import SeeMoreModal from "../includes/SeeMoreModal";

    export default {
        name: 'AppealsList',
        components: {SeeMoreModal},
        data: () => { return {
            result: null,
            statusSearch: null,
        }},
        methods: {
            formatDbDate: formatDbDate,
            formatDbDatetime: formatDbDatetime,
            stripQuestionTags: stripQuestionTags,
            search() {
                this.result = null;
                getAppealsList(this.statusSearch).then((response) => {
                    this.result = response.data;
                });
            }
        },
        mounted() {
            getAppealsList().then((response) => {
                this.result = response.data;
            });
            document.getElementById('title').focus();
        },
    }
</script>
<template>
  <div id="side-menu" aria-live="polite" ref="sideMenuElement">
    <div id="side-menu-logo-container" tabindex="0">
      <router-link to="/">
        <img id="side-menu-logo" :src="$store.state.main.candidateInfo.clientLogo" alt="Logotipo da instituição">
      </router-link>
    </div>
    <div id="side-menu-profile-container">
      <div id="side-menu-profile-img-container">
        <img id="side-menu-profile-img" :src="getCandidatePhoto()" alt="Sua foto de perfil">
      </div>
      <span>{{ $store.state.main.candidateInfo.firstName }}</span>
    </div>
    <div id="side-menu-options-container">
      <router-link class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.availableMenus.online_tests"
         to="/online-tests">
        <i class="fa fa-lg fa-globe"></i>
        <span class="option-description" alt="Provas online disponíveis">Provas Online</span>
      </router-link>
      <router-link class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.availableMenus.future_tests"
         to="/upcoming-tests">
        <i class="fa fa-lg fa-calendar"></i>
        <span class="option-description">Provas futuras</span>
      </router-link>
      <router-link class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.availableMenus.test_data"
         to="/finished-tests">
        <i class="fa fa-lg fa-history"></i>
        <span class="option-description">Provas realizadas</span>
      </router-link>
      <router-link class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.availableMenus.scheduling"
         to="/test-scheduling">
        <i class="fa fa-lg fa-clock-o"></i>
        <span class="option-description">Agendar provas</span>
      </router-link>
      <router-link class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.availableMenus.questionnaires"
         to="/questionnaires">
        <i class="fa fa-lg fa-bar-chart"></i>
        <span class="option-description">Questionários</span>
      </router-link>
      <router-link class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.availableMenus.appeals"
         to="/appeals">
        <i class="fa fa-lg fa-file"></i>
        <span class="option-description">Requerimentos</span>
      </router-link>
      <router-link class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.availableMenus.reports && !$store.state.main.smespCustomization"
         to="/reports-list">
        <i class="fa fa-lg fa-area-chart"></i>
        <span class="option-description">Relatórios</span>
      </router-link>
      <router-link class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.availableMenus.certificates"
         to="/certificates">
        <i class="fa fa-lg fa-trophy"></i>
        <span class="option-description">Certificados</span>
      </router-link>
      <a class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.mockExam"
         :href="getMockExamUrl()">
        <i class="fa fa-lg fa-cogs"></i>
        <span class="option-description">Avaliação de teste</span>
      </a>
      <router-link class="menu-option" @click="hiddenMenu"
         v-if="$store.state.main.candidateInfo.availableMenus.extra_contents"
         to="/extra-contents">
        <i class="fa fa-lg fa-search"></i>
        <span class="option-description">Materiais de estudo</span>
      </router-link>
      <a class="menu-option" :href="getBaseSgpUrlNoSlug() + $store.state.main.candidateInfo.availableMenus.smart_sso"
         target="_blank" v-if="$store.state.main.candidateInfo.availableMenus.smart_sso" @click="hiddenMenu">
        <i class="fa fa-lg fa-clock-o"></i>
        <span class="option-description">Agendar Prova</span>
      </a>
      <a class="menu-option"
         @click="logout()">
        <i class="fa fa-lg fa-power-off"></i>
        <span class="option-description">Sair</span>
      </a>
    </div>
    <VueAnnouncer/>
  </div>
</template>

<script>
  import {getBaseSgpUrl, getBaseSgpUrlNoSlug} from "../../scripts/apiService/ApiService";
  import {deleteCookie, getCookie} from "../../scripts/utils";
  import { firebaseSignOut } from "../../scripts/firebase";

export default {
  name: 'SideMenu',
  methods: {
    getBaseSgpUrl: getBaseSgpUrl,
    getBaseSgpUrlNoSlug: getBaseSgpUrlNoSlug,
    getCookie: getCookie,
    focus() {
      if(this.$refs.sideMenuElement.hasAttribute('tabindex')) this.$refs.sideMenuElement.removeAttribute('tabindex');
      document.getElementById('side-menu-logo-container').focus();
    },
    hiddenMenu(){
      this.$refs.sideMenuElement.setAttribute('tabindex', '-1');
    },
    getCandidatePhoto(){
      let images = require.context('../../assets/img', false, /\.png$/);
      return this.$store.state.main.candidateInfo.photo || images('./profile.png');
    },
    getUrl(menu){
      let baseUrl = getBaseSgpUrl();
      if (menu !== 'logout'){
        baseUrl = getBaseSgpUrlNoSlug();
      }
      return baseUrl + this.$store.state.main.candidateInfo.availableMenus[menu];
    },
    getMockExamUrl(){
      let baseUrl = getBaseSgpUrl();
      return baseUrl + "test/mockonlinetest/?new_layout=true&legacy_key="
              + this.$store.state.main.candidateInfo.legacyKey;
    },
    logout(){
      deleteCookie("currentCandidate");
      if (this.$store.state.main.candidateInfo.logoutUrl) {
        window.location.href = this.$store.state.main.candidateInfo.logoutUrl;
      } else {
        window.location.href = this.getUrl('logout');
      }

      firebaseSignOut();
    }
  },
  mounted(){
    if(this.$route.path === '/'){
      document.getElementById('side-menu-logo-container').setAttribute('tabindex', '0');
      if(this.$refs.sideMenuElement.hasAttribute('tabindex')) this.$refs.sideMenuElement.removeAttribute('tabindex');
      this.$announcer.set('Para acessar o menu lateral depois de acessar alguma opção, basta pressionar 0', 'polite');
    }
    else{
      document.getElementById('side-menu-logo-container').setAttribute('tabindex', '-1');
      this.$refs.sideMenuElement.setAttribute('tabindex', '-1');
    }
  }
}
</script>

<template>
    <transition name="modal">
        <div class="modal-mask" :class="maskClass">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-close-container" v-if="!hideClose">
                        <div class="modal-close-btn" @click="$emit('close')">
                            <i class="fa fa-times-circle close-icon"></i>
                        </div>
                    </div>
                    <div class="modal-content-inside">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "ModalDialog",
        props: ['maskClass', 'hideClose']
    }
</script>

<style scoped>
    .close-icon {
        line-height: 0;
        font-size: 35px;
    }
</style>
<template>
    <div v-if="result" aria-live="assertive">
        <slot name="header">
            <div class="col-xs-12">
                <div class="d-flex-row flex-justify-between">
                    <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-history"></i> Provas realizadas</p>
                    <div class="d-flex-row flex-align-center mobile-hidden-flex m-r-60">
                        <router-link class="btn btn-default m-r-md" v-if="result.configs.can_create_appeal"
                           :to="`/finished-tests/${$route.params.key}/appeal`">
                            Solicitar requerimento</router-link>
                        <router-link class="btn btn-default m-r-md" v-if="result.configs.show_print_btn"
                           :to="`/finished-tests/${$route.params.key}/print`" target="_blank">
                                Imprimir</router-link>
                        <router-link class="btn btn-primary m-r-md" v-if="!result.configs.hide_view_questions"
                           :to="`/finished-tests/${$route.params.key}/questions`">
                            Visualizar questões</router-link>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 m-t-md" v-if="result.invalidation_reason">
                <div class="bg-warning p-10">
                    Esta prova foi invalidada. <a @click="openInvalidationReason" class="link">Ver motivo</a>
                </div>
            </div>
            <modal-dialog v-show="showModalInvalidate" @close="showModalInvalidate = false">
                <h3 class="m-b-md">Motivo de invalidação</h3>
                {{ result.invalidation_reason }}
            </modal-dialog>
        </slot>

        <div class="col-xs-12 m-t-md">
            <div class="card p-20 text-left d-flex-column test-info-card">
                <h3>Informações da avaliação</h3>
                <div class="col-xs-12 p-0 m-t-md">
                    <div class="col-xs-12 col-lg-2 p-0 m-t-md">
                        <slot name="candidate-info" :candidate="result.candidate">
                            <div><i class="fa fa-user fa-lg"></i> {{ result.candidate.name }}</div>
                        </slot>
                        <div class="m-t-md"><i class="fa fa-group fa-lg"></i> {{ result.academic }}</div>
                    </div>
                    <div class="col-xs-12 col-lg-5 p-0 m-t-md">
                        <div>
                            <i class="fa fa-calendar fa-lg"></i>
                            {{ result.schedule.type_assessment.name }} -
                            {{ formatDbDate(result.schedule.date_schedule) }} {{ result.schedule.time_schedule }}
                            ({{ result.status }})
                        </div>
                        <div class="m-t-md"><i class="fa fa-map-marker fa-lg"></i> {{ result.place }}</div>
                    </div>
                    <div class="col-xs-12 col-lg-5 p-0 m-t-md" v-if="result.attendance">
                        <div><i class="fa fa-info-circle fa-lg"></i> Legenda</div>
                        <div class="m-t-md">
                            <slot name="color-info">
                                <div v-if="result.show_tbl_score" class="chip bg-success m-r-sm d-inline-block">Correta</div>
                                <div v-if="result.show_tbl_score" class="chip bg-danger m-r-sm d-inline-block">Incorreta</div>
                                <div v-if="result.show_tbl_score" class="chip bg-default m-r-sm d-inline-block">Anulada</div>
                            </slot>
                            <div class="no-break d-inline-block"><i class="fa fa-align-justify"></i> Discursiva</div>
                            <div class="no-break d-inline-block"><i class="fa fa-list-ul"></i> Objetiva</div>
                            <slot name="extra-subtitles" :result="result"></slot>
                        </div>
                    </div>
                    <div class="col-xs-12 m-t-md d-flex-column align-center mobile-show text-center">
                        <router-link class="btn btn-default m-t-md" v-if="result.configs.can_create_appeal"
                           :to="`/finished-tests/${$route.params.key}/appeal`">
                            Solicitar requerimento</router-link>
                        <router-link class="btn btn-default m-t-md" v-if="result.configs.show_print_btn"
                           :to="`/finished-tests/${$route.params.key}/print`" target="_blank">
                            Imprimir</router-link>
                        <router-link class="btn btn-primary m-t-md" v-if="!result.configs.hide_view_questions"
                           :to="`/finished-tests/${$route.params.key}/questions`">
                            Visualizar questões</router-link>
                    </div>
                </div>
            </div>
        </div>

        <slot name="total-score">
            <div class="col-xs-12 m-t-xl" v-if="result.attendance && result.show_tbl_score && result.configs.show_score && result.score !== null">
                Total: {{ result.score.toFixed(2) }} / {{ result.value.toFixed(2) }}
            </div>
        </slot>

        <div class="col-xs-12 m-t-xl" v-for="(question, index) in result.questions" :key="question">
            <div class="card p-20 text-left question-card" v-if="!result.is_absent">
                <h3 class="d-flex-row flex-justify-between">
                    <span>
                        <slot name="question-title" :order="index + 1" :question="question" :result="result">
                            <span class="m-r-sm">Questão {{ index + 1 }} | Código {{ question.client_code }}</span>
                            <span class="chip chip-lg" :class="getQuestionBg(question)"><i :class="getQuestionIcon(question)"></i></span>
                        </slot>
                    </span>
                    <span v-if="result.show_tbl_score && result.configs.show_score" class="question-score">
                        {{ (question.score ? question.score : 0).toFixed(2) }} / {{ question.value.toFixed(2) }}
                    </span>
                </h3>

                <div v-if="!result.configs.hide_question_body">
                    <slot name="content">
                        <div v-if="result.configs.show_config_name" class="m-t-lg">
                            <span class="text-bold">{{ question.content_config }}: </span>
                            <span>{{ question.content ? question.content : "-" }}</span>
                        </div>
                    </slot>
                    <div v-if="question.item_support">
                        <div class="text-bold m-t-lg">Texto de apoio</div>
                        <slot name="item-support" :body="question.item_support">
                            <span class="m-t-sm">{{ stripQuestionTags(question.item_support) }}</span>
                            <see-more-modal modal-title="Texto de apoio" :modal-content="question.item_support"></see-more-modal>
                        </slot>
                    </div>
                    <div class="text-bold m-t-lg">Enunciado</div>
                    <slot name="item-body" :body="question.body">
                        <span class="m-t-sm">{{ stripQuestionTags(question.body) }}</span>
                        <see-more-modal modal-title="Enunciado" :modal-content="question.body"></see-more-modal>
                    </slot>
                </div>

                <div v-if="question.type === 'o'">
                    <slot name="objective-section" :question="question" :result="result">
                        <div class="m-t-lg" v-if="result.configs.show_correct_alternative && result.show_tbl_score">
                            <span class="text-bold">Alternativa correta</span>
                        </div>
                        <div class="m-t-lg" v-if="result.configs.show_correct_alternative && result.show_tbl_score">
                            <div class="bg-primary-light d-flex-row text-left flex-align-center">
                                <div class="alternative-letter-container d-flex-column flex-justify-evenly text-center bg-primary">
                                    {{ orderToChar(getQuestionCorrectAlternative(question).number) }}
                                </div>
                                <div class="p-10">
                                    <slot name="answer-body" :body="getQuestionCorrectAlternative(question).body">
                                        <span>{{ stripQuestionTags(getQuestionCorrectAlternative(question).body) }}</span>
                                        <div v-if="getQuestionCorrectAlternative(question).explain">
                                            <small>Justificativa:</small>
                                            <small>{{ stripQuestionTags(getQuestionCorrectAlternative(question).explain) }}</small>
                                        </div>
                                        <see-more-modal modal-title="Alternativa"
                                                        :modal-content="getQuestionCorrectAlternative(question).body">
                                        </see-more-modal>
                                    </slot>
                                </div>
                            </div>
                        </div>
                        <div class="m-t-lg" v-if="getQuestionMarkedAlternative(question)">
                            <span class="text-bold">Alternativa marcada</span>
                        </div>
                        <div class="m-t-lg" v-if="getQuestionMarkedAlternative(question)">
                            <div class="d-flex-row text-left flex-align-center">
                                <div class="alternative-letter-container d-flex-column flex-justify-evenly text-center border-primary">
                                    {{ orderToChar(getQuestionMarkedAlternative(question).number) }}
                                </div>
                                <div class="p-10">
                                    <slot name="candidate-answer-body" :body="getQuestionMarkedAlternative(question).body">
                                        <span>{{ stripQuestionTags(getQuestionMarkedAlternative(question).body) }}</span>
                                        <div v-if="getQuestionMarkedAlternative(question).explain">
                                            <small>Justificativa:</small>
                                            <small>{{ stripQuestionTags(getQuestionMarkedAlternative(question).explain) }}</small>
                                        </div>
                                        <see-more-modal modal-title="Alternativa"
                                                        :modal-content="getQuestionMarkedAlternative(question).body">
                                        </see-more-modal>
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>
                <div v-else>
                    <div class="m-t-lg">
                        <div class="text-bold">Resposta</div>
                        <slot name="candidate-discursive-answer" :body="question.discursive_text">
                            <div v-if="getAnswerType(question) === 'normal' || getAnswerType(question) === 'code'">
                                <span class="m-t-sm">{{ stripQuestionTags(question.discursive_text) }}</span>
                                <see-more-modal modal-title="Resposta" :modal-content="question.discursive_text"></see-more-modal>
                            </div>
                            <div v-if="getAnswerType(question) === 'file'">
                                <a :href="question.discursive_text" target="_blank">Download do arquivo</a>
                            </div>
                            <div v-if="getAnswerType(question) === 'audio'">
                                <audio :src="question.discursive_text" controls></audio>
                            </div>
                            <div v-if="getAnswerType(question) === 'video'">
                                <video :src="question.discursive_text" controls class="answer-video"></video>
                            </div>
                        </slot>
                    </div>
                </div>

                <div class="m-t-lg" v-if="question.comments">
                    <div class="text-bold">Comentários</div>
                    <slot name="comments-body" :body="question.comments">
                        <span class="m-t-sm">{{ stripQuestionTags(question.comments) }}</span>
                        <see-more-modal modal-title="Comentários" :modal-content="question.comments"></see-more-modal>
                    </slot>
                </div>

                <div class="m-t-lg" v-if="question.justification && result.configs.show_justification">
                    <div class="text-bold">Justificativa</div>
                    <slot name="justification-body" :body="question.justification">
                        <span class="m-t-sm">{{ stripQuestionTags(question.justification) }}</span>
                        <see-more-modal modal-title="Enunciado" :modal-content="question.justification"></see-more-modal>
                    </slot>
                </div>
            </div>
        </div>

        <div class="col-xs-12 m-t-lg p-0" v-if="pages && pages.length > 0">
            <div class="col-xs-4" v-for="image in pages" :key="image">
                <img :src="image.image_url" class="print-img">
            </div>
        </div>
    </div>
    <div v-else class="d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
        <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
    </div>
</template>

<script>
    import {getFinishedTestData, getFinishedTestPages} from "../../scripts/apiService/tests";
    import ModalDialog from "../base/Modal";
    import {formatDbDate, orderToChar, stripQuestionTags} from "../../scripts/utils";
    import {getBaseSgpUrl} from "../../scripts/apiService/ApiService";
    import SeeMoreModal from "../includes/SeeMoreModal";

    export default {
        name: 'TestDataView',
        components: {SeeMoreModal, ModalDialog},
        props: ["on-load", "on-images-load"],
        data: () => { return {
            result: null,
            pages: [],
            showModalInvalidate: false,
            modalFlags: {
                support: [],
                body: [],
                explain: [],
                correctAlternative: [],
                markedAlternative: [],
                discursiveAnswer: [],
                comments: [],
                justification: [],
            }
        }},
        methods: {
            formatDbDate: formatDbDate,
            stripQuestionTags: stripQuestionTags,
            orderToChar: orderToChar,
            getBaseSgpUrl: getBaseSgpUrl,
            openInvalidationReason() {
                this.showModalInvalidate = true;
            },
            getQuestionBg(question){
                if (question.annulled || !this.result.show_tbl_score){
                    return 'bg-default'
                }
                return question.score ? 'bg-success' : 'bg-danger';
            },
            getQuestionIcon(question){
                return question.type === 'o' ? 'fa fa-list-ul' : 'fa fa-align-justify';
            },
            getQuestionCorrectAlternative(question){
                return question.alternatives.find((alternative) => alternative.correct)
            },
            getQuestionMarkedAlternative(question){
                return question.alternatives.find((alternative) => alternative.marked)
            },
            getAnswerType(question) {
                let answerTypes = {
                    code: 4,
                    file: 5,
                    audio: 6,
                    video: 7
                };
                if (!question.answer_type) {
                    return "normal"
                }
                if (question.answer_type >= answerTypes.code) {
                    return ["code", "file", "audio", "video"]
                        .find((type) => question.answer_type === answerTypes[type])
                }
                return "normal"
            },
        },
        mounted() {
            getFinishedTestData(this.$route.params.key).then((response) => {
                this.result = response.data;
                if (this.onLoad){
                    this.onLoad();
                }
                this.$nextTick(() => {
                    if (this.result)
                        document.getElementById('title').focus();
                    else
                        this.$announcer.set('Não existem provas realizadas disponíveis', 'assertive');
                })
            });
            getFinishedTestPages(this.$route.params.key).then((response) => {
                this.pages = response.data;
                if (this.onImagesLoad) {
                    this.pages = response.data;
                    let loadedImages = 0;
                    this.pages.forEach((page) => {
                        const img = new Image();
                        img.src = page.image_url;
                        img.onload = () => {
                            loadedImages++;
                            if (loadedImages === this.pages.length) {
                                this.onImagesLoad();
                            }
                        };
                    });
                }
            }).finally(() => {
                if (this.pages.length === 0 && this.onImagesLoad) {
                    this.onImagesLoad();
                }
            });
        },
    }
</script>
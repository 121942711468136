export const onlineTestState = {
    namespaced: true,
    state () {
        return {
            configs: null,
            started: false,
            assessment: null
        }
    },
    mutations: {
        setConfigs (state, configs) {state.configs = configs;},
        setAssessment (state, assessment) {state.assessment = assessment;},
        setStarted (state) {state.started = true;},
    }
};
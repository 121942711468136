<template>
    <div aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-globe"></i> Provas online</p>
            </div>
        </div>
        <div v-if="!loading">
            <div class="col-xs-12 p-x-sm" v-if="result && result.length > 0">
                <div class="col-xs-12 col-md-6 col-lg-4 p-md" v-for="(test) in result" :key="test">
                    <div class="card p-20">
                        <div class="d-flex-row flex-justify-between m-b-md">
                            <h4 class="level m-b-sm">{{ test.schedule.type_assessment.name }}</h4>
                            <small class="info" v-if="!test.configs.param_hide_online_test_date">
                                <i class="fa fa-calendar"></i> {{ formatDbDate(test.schedule.date_schedule) }} às {{ test.schedule.time_schedule }}
                            </small>
                        </div>
                        <div class="d-flex-row p-y-md text-left">
                            <span>{{test.group_assessment_name}}</span>
                        </div>
                        <div class="d-flex-row flex-justify-between">
                            <div class="d-flex-column text-left">
                                <small class="level m-b-sm" v-for="academic in test.academic.split(' / ')" :key="academic">
                                    {{ academic }}
                                </small>
                            </div>
                            <div class="d-flex-column text-right">
                                <span><i class="fa fa-clock-o"></i>
                                    {{ test.online_test_duration }} minutos
                                </span>
                            </div>
                        </div>
                        <div class="col-xs-12 p-0 m-t-lg text-left" v-if="test.end_date && !test.configs.hide_end_date">
                            <small>Encerramento: {{ formatDbDatetime(test.end_date) }}</small>
                        </div>
                        <div class="col-xs-12 m-t-lg">
                            <div v-if="test.application_mode !== 'p'">
                                <router-link role="button" class="col-xs-12 btn btn-default" v-if="!test.configs.use_examus_monitoring"
                                        :to="`/online-tests/${test.item_generated}/instructions`">
                                    Acessar avaliação
                                </router-link>
                                <a class="col-xs-12 btn btn-default" v-else role="button"
                                        :href="getBaseSgpUrl() + 'examusonlinetest/' + test.item_generated + '?use_new_layout=true'">
                                    Acessar avaliação
                                </a>
                            </div>
                            <button class="col-xs-12 btn btn-default" v-else disabled="disabled">
                                Prova impressa
                            </button>
                        </div>
                    </div>
                </div>    
            </div>
            <div v-else>
                <span><b>Não há provas disponíveis. Acesse o menu "Provas Futuras" a esquerda para consultar as próximas avaliações.</b></span>
            </div>
        </div>
        <div role="presentation" v-else class="col-xs-12 d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
            <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
        </div>
    </div>
</template>

<script>
    import {getOnlineTests} from "../../scripts/apiService/tests";
    import {formatDbDate, formatDbDatetime} from "../../scripts/utils";
    import {getBaseSgpUrl} from "../../scripts/apiService/ApiService";

    export default {
        name: 'OnlineTestsList',
        components: {},
        data: () => { return {
            result: null,
            loading: false
        }},
        methods: {
            formatDbDate: formatDbDate,
            formatDbDatetime: formatDbDatetime,
            getBaseSgpUrl: getBaseSgpUrl
        },
        mounted() {
            this.loading = true;
            getOnlineTests(this.$route.params.key).then((response) => {
                this.result = response.data;
                this.loading = false;
            });
            document.getElementById('title').focus();
        },
    }
</script>
<template>
    <div aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-question"></i> Questionários</p>
            </div>
        </div>
        <div class="col-xs-12 p-x-sm" v-if="result !== null">
            <div class="col-xs-12 col-md-6 col-lg-4 p-md" v-for="(questionnaire) in result" :key="questionnaire">
                <div class="card p-20">
                    <div class="d-flex-row flex-justify-between m-b-md text-left">
                        <h4 class="level m-b-sm p-r-lg">{{ questionnaire.template }}</h4>
                        <small class="info" style="white-space: nowrap;">
                            <i class="fa fa-calendar"></i> {{ formatDbDate(questionnaire.schedule.date_schedule) }}
                        </small>
                    </div>
                    <div class="d-flex-row flex-justify-between">
                        <div class="d-flex-column text-left">
                            <small class="level m-b-sm" v-for="academic in questionnaire.academic.split(' / ')" :key="academic">
                                {{ academic }}
                            </small>
                        </div>
                    </div>
                    <div class="col-xs-12 m-t-lg">
                        <router-link class="col-xs-12 btn btn-default" :to="`/questionnaires/${questionnaire.id}`">
                            Responder questionário
                        </router-link>
                    </div>
                </div>
            </div>    
        </div>
        <div v-else class="col-xs-12 d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
            <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
        </div>
    </div>
</template>

<script>
    import {formatDbDate} from "../../scripts/utils";
    import {getOnlineQuestionnaires} from "../../scripts/apiService/questionnaires";

    export default {
        name: 'QuestionnairesList',
        components: {},
        data: () => { return {
            result: null,
        }},
        methods: {
            formatDbDate: formatDbDate,
        },
        mounted() {
            getOnlineQuestionnaires().then((response) => {
                this.result = response.data;
            });

            document.getElementById('title').focus();
        },
    }
</script>
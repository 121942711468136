<template>
    <test-data-view :on-load="prepareScreen" :on-images-load="printScreen" class="print-wrapper">
        <template #header><div></div></template>
        <template #color-info><div></div></template>
        <template #total-score><div></div></template>
        <template #content><div></div></template>
        <template #candidate-info="props">
            <div>
                <i class="fa fa-user fa-lg"></i> {{ props.candidate.name }}
                <span v-if="props.candidate.enrollment_code">
                    <i class="fa fa-id-card"></i> {{ props.candidate.enrollment_code }}
                </span>
            </div>
        </template>
        <template #extra-subtitles="props">
            <b class="pull-right"
                 v-if="props.result.attendance && props.result.show_tbl_score &&
                 props.result.configs.show_score && props.result.score !== null">
                Total: {{ props.result.score.toFixed(2) }} / {{ props.result.value.toFixed(2) }}
            </b>
        </template>
        <template #question-title="props">
            <span class="chip chip-lg m-r-sm">
                <i :class="getQuestionIcon(props.question)"></i>
            </span>
            <span>
                Questão {{ props.order }} | Código {{ props.question.client_code }} |
                <span v-if="props.result.configs.show_config_name && !props.result.configs.hide_question_body">
                    <span class="text-bold">{{ props.question.content_config }}: </span>
                    <span>{{ props.question.content ? props.question.content : "-" }}</span>
                </span>
            </span>
        </template>
        <template #item-support="props"><span>{{ stripQuestionTags(props.body, true) }}</span></template>
        <template #item-body="props"><span>{{ stripQuestionTags(props.body, true) }}</span></template>
        <template #item-justification="props"><span>{{ stripQuestionTags(props.body, true) }}</span></template>
        <template #candidate-discursive-answer="props"><span>{{ stripQuestionTags(props.body, true) }}</span></template>
        <template #comments-body="props"><span>{{ stripQuestionTags(props.body, true) }}</span></template>
        <template #justification-body="props"><span>{{ stripQuestionTags(props.body, true) }}</span></template>
        <template #objective-section="props">
            <div class="col-xs-12 p-0 m-t-md m-b-md">
                <div v-for="(alternative, index) in props.question.alternatives" :key="alternative"
                     class="col-xs-12 p-0">
                    <div class="col-xs-1">
                        <i class="fa fa-check-circle"
                           v-if="alternative.correct && props.result.configs.show_correct_alternative && props.result.show_tbl_score"></i>
                    </div>
                    <div class="col-xs-11 p-0" style="display: flex; flex-direction: row" v-if="props.result.configs.show_correct_alternative ? alternative.correct : true">>
                        <b>{{ orderToChar(index+1).toLowerCase() }}) </b>
                        <div style="display: flex; flex-direction: column">
                            <span>{{ stripQuestionTags(alternative.body, true) }}</span>
                            <small><span>Justificativa:</span>{{ stripQuestionTags(alternative.explain, true) }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 p-0 m-t-md" v-if="getQuestionMarkedAlternative(props.question)">
                    <div class="col-xs-11 p-0">
                        <b>Alternativa marcada</b><br/>
                        <b>{{ orderToChar(getQuestionMarkedAlternative(props.question).number).toLowerCase() }}) </b>
                        <span>{{ stripQuestionTags(getQuestionMarkedAlternative(props.question).body, true) }}</span>
                    </div>
                </div>
            </div>
        </template>
    </test-data-view>
</template>

<script>
    import TestDataView from "./TestDataView";
    import {orderToChar, stripQuestionTags} from "../../scripts/utils";

    export default {
        name: 'TestDataPrintView',
        components: {TestDataView},
        data: () => {
            return {
                contentLoaded: false,
                imagesLoaded: false,
            }
        },
        methods: {
            stripQuestionTags: stripQuestionTags,
            orderToChar: orderToChar,
            prepareScreen() {
                let page = document.querySelector('#page-wrapper');
                page.classList.remove('theme-dark');
                page.classList.add('theme-light');
                this.contentLoaded = true;
                if (this.imagesLoaded) {
                    this.callPrint();
                }
            },
            printScreen() {
                this.imagesLoaded = true;
                if (this.contentLoaded) {
                    this.callPrint();
                }
            },
            callPrint() {
                this.$nextTick(() => {window.print()});
            },
            getQuestionIcon(question) {
                return question.type === 'o' ? 'fa fa-list-ul' : 'fa fa-align-justify';
            },
            getQuestionMarkedAlternative(question) {
                return question.alternatives.find((alternative) => alternative.marked)
            },
        },
        mounted() {},
    }
</script>
<template>
    <div v-if="result.length > 0">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-calendar"></i> Provas futuras</p>
            </div>
        </div>
        <div class="col-xs-12 col-lg-4 m-b-lg" v-for="test in result" :key="test">
            <div class="card p-20">
                <div class="d-flex-row flex-justify-between m-b-lg">
                    <h4>{{ test.schedule.type_assessment.name }}</h4>
                    <span v-if="!test.configs.param_hide_online_test_date">
                        <i class="fa fa-calendar"></i>
                        {{ formatDbDate(test.schedule.date_schedule) }} {{ test.schedule.time_schedule }}
                    </span>
                </div>
                <div class="d-flex-column text-left m-b-lg">
                    <small v-for="academic in test.academic.split(' / ')" :key="academic">
                        {{ academic }}
                    </small>
                </div>
                <div class="text-left">
                    <small><i class="fa fa-map-marker fa-lg"></i> {{ test.place }}</small>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
        <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
    </div>
    <VueAnnouncer/>
</template>

<script>
    import {getUpcomingTests} from "../../scripts/apiService/tests";
    import {formatDbDate} from "../../scripts/utils";

    export default {
        name: 'UpcomingTestsList',
        components: {},
        data: () => { return {
            result: [],
        }},
        methods: {
            formatDbDate: formatDbDate
        },
        mounted() {
            getUpcomingTests().then((response) => {
                this.result = response.data;
                this.$nextTick(() => {
                    if(this.result.length > 0)
                        document.getElementById('title').focus();
                    else
                        this.$announcer.set('Não existem provas futuras disponíveis', 'assertive');
                });
            });
        },
    }
</script>
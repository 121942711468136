<template>
  <div id="top-menu">
    <a id="top-menu-btn-container" @click="$store.commit('main/toggleMenu')">
      <i class="fa fa-align-justify"></i>
    </a>
    <div id="top-menu-logo-container">
      <img class="light-opacity" src="../../assets/img/logo.png" alt="Logotipo da instituição">
      <img class="dark-opacity" src="../../assets/img/logo-white.png" alt="Logotipo da instituição">
    </div>
    <div id="top-menu-profile-container">
      <img id="top-menu-profile-img" :src="getCandidatePhoto()" alt="Sua foto de perfil">
    </div>
  </div>
</template>

<script>
  import {isMobile} from "../../scripts/utils";

  export default {
    name: 'TopMenu',
    methods: {
      getCandidatePhoto(){
        let images = require.context('../../assets/img', false, /\.png$/);
        return this.$store.state.main.candidateInfo.photo || images('./profile.png');
      }
    },
    mounted(){
      if(this.$route.path === '/' && document.getElementById('top-menu').hasAttribute('tabindex')) document.getElementById('top-menu').removeAttribute('tabindex');
      else document.getElementById('top-menu').setAttribute('tabindex', '-1');

      if(!isMobile()) document.getElementById('top-menu').setAttribute('aria-hidden', 'true');
    }
  }
</script>

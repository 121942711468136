import { createApp } from 'vue'
import {createStore} from "vuex";
import App from './App.vue'
import * as VueRouter from "vue-router";
import './assets/imports/font-awesome/css/font-awesome.min.css';
import {mainState} from "./scripts/vuex/mainState";
import {mainRoutes} from "./scripts/router/mainRoutes";
import {onlineTestState} from "./scripts/vuex/onlineTestState";
import {onlineTestRoutes} from "./scripts/router/onlineTestRoutes";
import VueFullscreen from "vue-fullscreen";
import VueAnnouncer from '@vue-a11y/announcer';
import {initOptimizelyClient} from "./scripts/FeatureFlags";

const routes = []
    .concat(mainRoutes)
    .concat(onlineTestRoutes);

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
});

const store = createStore({
  modules: {
    main: mainState,
    onlineTest: onlineTestState,
  }
});

createApp(App)
    .use(VueAnnouncer)
    .use(store)
    .use(router)
    .use(VueFullscreen)
    .mount('#app');

initOptimizelyClient();

<template>
    <div aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-question"></i> Questionário</p>
            </div>
        </div>
        <div class="col-xs-12 p-x-sm p-b-xl" v-if="result !== null && !modalFinish">
            <div class="col-xs-1"></div>
            <div class="col-xs-10">
                <div class="card p-10">
                    <div class="col-xs-12 p-md text-center">
                        <h3>{{ result.template }}</h3>
                    </div>
                    <div class="col-xs-12 text-left" v-if="result.instructions">
                        <div v-html="result.instructions"></div>
                    </div>
                </div>
                <div class="card p-10 m-t-xl" v-for="session in result.sessions" :key="session">
                    <div class="col-xs-12 text-center m-b-xl" v-if="session.name">
                        <strong>{{ session.name }}</strong>
                    </div>
                    <div class="col-xs-12 p-0" v-if="session.number_marks">
                        <div class="col-xs-12 text-right">
                            <div class="questionnaire-table-alternative questionnaire-table-header" :key="index"
                                 v-for="index in Array(session.number_marks).keys()">
                                {{ index }}
                            </div>
                        </div>
                        <div class="d-flex-row flex-justify-between col-xs-12 striped text-left" v-for="question in session.questions" :key="question">
                            <div class="questionnaire-body" :id="'question-' + question.pk">
                                {{ stripTags(question.body) }}
                                <div v-if="checkingErrors && errors[question.pk]" class="color-danger">
                                    Por favor, responda esta questão
                                </div>
                            </div>
                            <div>
                                <div class="questionnaire-table-alternative" :key="alternative"
                                     v-for="alternative in question.alternatives">
                                    <div class="d-flex-row flex-align-center flex-justify-center"
                                         style="width: 100%; height: 100%;">
                                        <a class="alternative-input cursor-pointer border-default"
                                           :class="alternative === question.markedAlternative ? 'bg-primary' : ''"
                                           @click="question.markedAlternative = alternative">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-left col-xs-12">
                        <div v-for="question in session.questions" :key="question">
                            <div class="m-b-md" :id="'question-' + question.pk">
                                <strong>{{ question.number }}. {{ stripTags(question.body) }}</strong>
                            </div>
                            <div v-if="checkingErrors && errors[question.pk]" class="color-danger m-b-lg">
                                Por favor, responda esta questão
                            </div>
                            <div v-if="question.type === 'o'" class="m-b-xl">
                                <div v-for="alternative in question.alternatives" :key="alternative">
                                    <div class="text-left m-b-sm d-flex-row flex-justify-start flex-align-center questionnaire-alternative-container"
                                         :class="alternative === question.markedAlternative ? 'bg-primary-light' : ''">
                                        <div class="d-flex-column flex-align-center flex-justify-center">
                                            <div class="alternative-input cursor-pointer border-default"
                                               :class="alternative === question.markedAlternative ? 'bg-primary' : ''"
                                               @click="question.markedAlternative = alternative">
                                            </div>
                                        </div>
                                        <span class="m-l-md">{{ alternative[1] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="question.type === 'm'" class="m-b-xl">
                                <div v-for="alternative in question.alternatives" :key="alternative">
                                    <div class="text-left m-b-sm d-flex-row flex-justify-start flex-align-center questionnaire-alternative-container"
                                         :class="isAlternativeMarked(question, alternative) >= 0 ? 'bg-primary-light' : ''">
                                        <div class="d-flex-column flex-align-center flex-justify-center">
                                            <div class="alternative-input alternative-input-sq cursor-pointer border-default"
                                               :class="isAlternativeMarked(question, alternative) >= 0 ? 'bg-primary' : ''"
                                               @click="toggleAlternative(question, alternative)">
                                            </div>
                                        </div>
                                        <span class="m-l-md">{{ alternative[1] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="m-b-xl">
                                <textarea :id="'textarea-' + question.pk" v-model="question.answerText"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-11 text-right m-y-xl">
                <router-link class="btn btn-default m-r-lg" to="/questionnaires">Cancelar</router-link>
                <button class="btn btn-primary" @click="sendQuestionnaire()" v-bind="loading ? {disabled: 'disabled'} : {}">
                    <i class="fa fa-circle-o-notch fa-spin" v-if="loading"></i> Enviar
                </button>
            </div>
        </div>
        <div v-else class="col-xs-12 d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
            <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
        </div>

        <modal-dialog v-show="modalFinish" :hide-close="true" mask-class="bg-blank">
            <h3 class="m-b-md">Questionário finalizado</h3>
            <p>Suas respostas foram enviadas.</p>
            <div class="p-b-md"><router-link class="btn btn-primary" to="/questionnaires">Retornar à listagem</router-link></div>
        </modal-dialog>
    </div>
</template>

<script>
    import {
        getOnlineQuestionnaireQuestions,
        postOnlineQuestionnaireQuestions
    } from "../../scripts/apiService/questionnaires";
    import {stripTags} from "../../scripts/utils";
    import "froala-editor/css/froala_editor.pkgd.min.css";
    import FroalaEditor from "froala-editor/js/froala_editor.pkgd.min.js";
    import ModalDialog from "../base/Modal";

    export default {
        name: 'QuestionnaireForm',
        components: {ModalDialog},
        data: () => { return {
            result: null,
            checkingErrors: false,
            errors: {},
            loading: false,
            modalFinish: false,
        }},
        methods: {
            stripTags: stripTags,
            toggleAlternative(question, alternative) {
                question.markedAlternatives = question.markedAlternatives ?? [];
                let marked = this.isAlternativeMarked(question, alternative);
                if (marked >= 0) {
                    question.markedAlternatives.splice(marked, 1);
                } else {
                    question.markedAlternatives.push(alternative);
                }

            },
            isAlternativeMarked(question, alternative){
                if (!question.markedAlternatives){ return -1 }
                return question.markedAlternatives.findIndex((questionAlternative) => questionAlternative === alternative);
            },
            resetFroalaEditors() {
                for (let session of this.result.sessions) {
                    session.questions.forEach((question) => {
                        if (question.type === "t") {
                            let elemId = "#textarea-" + question.pk;
                            question.editor = new FroalaEditor(elemId, this.getFroalaOptions(question));
                        }
                    });
                }
            },
            getFroalaOptions(question) {
                let froalaOptions = {
                    events: {
                        contentChanged: () => {
                            question.answerText = question.editor.html.get();
                        }
                    },
                    language: 'pt_br',
                    toolbarButtons: ['bold', 'italic', 'underline', '|', 'formatOL', 'formatUL', '|', 'wirisEditor'],
                    quickInsertButtons: ['ol', 'ul'],
                    htmlAllowedTags: ['.*'],
                    htmlAllowedAttrs: ['.*'],
                    useClasses: false,
                    immediateVueModelUpdate: true,
                    imagePaste: false,
                    attribution: false,
                    key: 'cJC7bE6D2F3H3D1A1yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5D5C4D3C2D4G2H1=='
                };
                froalaOptions.toolbarButtons.push('|', 'insertTable', 'specialCharacters');
                return froalaOptions
            },
            sendQuestionnaire(){
                this.checkingErrors = true;
                this.loading = true;
                this.errors = {};

                this.result.sessions.forEach((session) => {
                    session.questions.forEach((question) => {
                        if (question.type === 'o' && !question.markedAlternative) {this.errors[question.pk] = true;}
                        if (question.type === 'm' && !question.markedAlternatives?.length) {this.errors[question.pk] = true;}
                        if (question.type === 't' && !question.answerText) {this.errors[question.pk] = true;}
                    })
                });

                let errors = Object.keys(this.errors);
                if (errors.length === 0){
                    let answers = {};
                    this.result.sessions.forEach((session) => {
                        session.questions.forEach((question) => {
                            if (question.type === 'o') {answers[question.pk] = question.markedAlternative[0];}
                            if (question.type === 'm') {
                                answers[question.pk] = question.markedAlternatives.map((alternative) => alternative[0]);
                            }
                            if (question.type === 't') {answers[question.pk] = question.answerText;}
                        })
                    });
                    postOnlineQuestionnaireQuestions(this.$route.params.key, answers).then(() => {
                        this.modalFinish = true;
                        this.loading = false;
                    })
                } else {
                    this.loading = false;
                    document.getElementById('question-' + errors[0]).scrollIntoView();
                }
            },
        },
        mounted() {
            getOnlineQuestionnaireQuestions(this.$route.params.key).then((response) => {
                this.result = response.data;

                if (this.result.status === 9){
                    this.modalFinish = true;
                }

                let questionNumber = 1;
                this.result.sessions.forEach((session) =>{
                    session.questions.forEach((question) => {
                        question.number = questionNumber;
                        questionNumber ++;
                    })
                });

                this.$nextTick(() => {
                    this.resetFroalaEditors();
                });
            });

            document.getElementById('title').focus();
        },
    }
</script>
import {getCookie} from "../utils";
import {getRequest, postRequest} from "./ApiService";

export function getFinishedTestData(testId){
    let candidateKey = getCookie("currentCandidate");
    return getRequest('finishedTestData', {"candidateKey": candidateKey, "testId": testId})
}
export function getFinishedTestPages(testId){
    let candidateKey = getCookie("currentCandidate");
    return getRequest('finishedTestPages', {"candidateKey": candidateKey, "testId": testId})
}
export function getAppealCreateQuestions(testId){
    let candidateKey = getCookie("currentCandidate");
    return getRequest('appealCreateQuestions', {"candidateKey": candidateKey, "testId": testId})
}
export function getUpcomingTests(){
    let candidateKey = getCookie("currentCandidate");
    return getRequest('upcomingTestsList', {"candidateKey": candidateKey})
}
export function getFinishedTests(){
    let candidateKey = getCookie("currentCandidate");
    return getRequest('finishedTestsList', {"candidateKey": candidateKey})
}
export function getOnlineTests(){
    let candidateKey = getCookie("currentCandidate");
    return getRequest('onlineTestsList', {"candidateKey": candidateKey})
}
export function getOnlineTestInstructions(testId){
    let candidateKey = getCookie("currentCandidate");
    return getRequest('onlineTestInstructions', {"candidateKey": candidateKey, "testId": testId})
}
export function getOnlineTestConfigs(testId){
    return getRequest('onlineTestConfigs', {"testId": testId})
}
export function postRetryOnlineTest(testId, data){
    let candidateKey = getCookie("currentCandidate");
    return postRequest('retryOnlineTest', data,{"candidateKey": candidateKey, "testId": testId})
}
export function postTestMedia(testId, file, extension, extraData){
    let formData = new FormData();
    formData.append('file', file);
    formData.append('extension', extension);

    if (extraData) {
        for (let [key, value] of Object.entries(extraData)) {
            formData.append(key, value);
        }
    }

    return postRequest('saveMedia', formData, {"testId": testId}, {'Content-Type': 'multipart/form-data'})
}
export function getTestUrl(testId, fullUrl){
    return getRequest('', {"testId": testId}, null, null, fullUrl)
}
export function postTestUrl(testId, fullUrl, data){
    return postRequest('', data, {"testId": testId}, null, fullUrl)
}

import {getCookie} from "../utils";
import {getRequest, postRequest} from "./ApiService";

export function getReportsList(){
    return getRequest('reportsList');
}

export function getReport(reportSlug){
    let candidateKey = getCookie("currentCandidate");
    let pathParams = {
        reportSlug,
        candidateKey
    };
    return getRequest('reportInfo', pathParams);
}

export function postExecuteReport(reportSlug, payload){
    let candidateKey = getCookie("currentCandidate");
    let pathParams = {
        reportSlug,
        candidateKey
    };
    return postRequest('executeReport', payload, pathParams);
}
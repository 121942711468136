<template>
    <div aria-live="assertive">
        <div class="col-xs-12">
            <div class="d-flex-row flex-justify-between">
                <p id="title" role="heading" aria-level="1" tabindex="0"><i class="fa fa-trophy"></i> Certificados</p>
            </div>
        </div>
        <div v-if="!loading">
            <div class="col-xs-12 p-x-sm" v-if="result && result.length > 0">
                <div class="col-xs-12 col-md-6 col-lg-4 p-md" v-for="(certificate) in result" :key="certificate">
                    <div class="card p-20">
                        <div class="d-flex-row flex-justify-between m-b-md">
                            <h4 class="level m-b-sm">{{ certificate.certificate_name }}</h4>
                            <small class="info" v-if="certificate.academic">
                                <i class="fa fa-users"></i> {{ certificate.academic_name }}
                            </small>
                        </div>
                        <div class="col-xs-12 m-t-sm text-left">
                            <small>
                                <i class="fa fa-calendar"></i> Emitido em {{ formatDbDatetime(certificate.date) }}
                            </small>
                        </div>
                        <div class="col-xs-12 m-t-lg">
                            <a class="col-xs-12 btn btn-success" :href="certificate.url" target="_blank">
                                Visualizar certificado
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <span><b>Não há certificados disponíveis</b></span>
            </div>
        </div>
        <div v-else class="col-xs-12 d-flex-column align-center flex-justify-center text-center h-100vh overflow-hidden">
            <i class="fa fa-5x fa-circle-o-notch fa-spin"></i>
        </div>
    </div>
</template>

<script>

    import {getCertificateList} from "../../scripts/apiService/certificate";
    import {formatDbDatetime} from "../../scripts/utils";

    export default {
        name: 'CertificateList',
        components: {},
        data: () => { return {
            result: null,
            loading: true
        }},
        methods: {
            formatDbDatetime: formatDbDatetime
        },
        mounted() {
            getCertificateList().then((response) => {
                this.loading = false;
                this.result = response.data;
                document.getElementById('title').focus();
            });
        },
    }
</script>